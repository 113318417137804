// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useState, useEffect} from 'react';

import ProjectNew from './ProjectNew'
import TranscriptNav from './TranscriptNav'
import ProjectSearchContainer from './ProjectSearchContainer'
import AnalysisDisplayContainer from './AnalysisDisplayContainer'
import DragDropTranscriptContainer from './DragDropTranscriptContainer'
import DragDropSurveyContainer from 'surveys/DragDropSurveyContainer';
import ProjectBody from './ProjectBody'
import RootBody from './RootBody'
import UserDisplay from './UserDisplay'
import CodeBookContainer from './CodeBookContainer'
import TranscriptNotFound from './TranscriptNotFound'
import ProjectNotFound from './ProjectNotFound'
import ProjectArchivePage from 'project_archives/ProjectArchivePage';
import ProjectsStore from 'stores/ProjectsStore'
import OnboardingStore from 'stores/OnboardingStore'
import SurveyPage from 'surveys/SurveyPage'
import SurveyResponsePage from 'surveys/SurveyResponsePage'
import SurveyUploadingContainer from 'surveys/SurveyUploadingContainer';

import {
  Switch,
  Route,
} from 'react-router-dom'
import ProjectChatContainer from 'chat/ProjectChatContainer';
import SurveyQuestionPage from 'surveys/SurveyQuestionPage';

const QualContent = (props) => {
  const [onboardingState, setOnboardingState] = useState({})

  const queryOnboardingState = () => {
    const projectID = props.projectID;
    if ( !projectID ) return;

    const onboardingState = ProjectsStore.getOnboardingState(projectID);
    setOnboardingState(onboardingState);
  }


  const onChange = () => {
    queryOnboardingState();
  }

  useEffect(() => {
    queryOnboardingState();
    ProjectsStore.addChangeListener(onChange);
    OnboardingStore.addChangeListener(onChange);

    return () => {
      ProjectsStore.removeChangeListener(onChange);
      OnboardingStore.removeChangeListener(onChange);
    }

  }, [props.projectID])


  const componentWithHeight = (Component) => {
    return (otherProps) => {
      return (
        <Component
          height={props.height}
          projectID={props.projectID}
          transcriptID={props.transcriptID}
          codeID={props.codeID}
          page={props.page}
          hasEditPermission={props.hasEditPermission}
          onShowCollabModal={props.onShowCollabModal}
          onboardingState={onboardingState}
          {...otherProps}
        />
      );
    }
  }

  if (props.transcriptNotFoundError) {
    return <TranscriptNotFound/>
  }

  if (props.projectNotFoundError) {
    return <ProjectNotFound/>
  }

  if (props.isArchived) {
    return <ProjectArchivePage isProjectOwner={props.isProjectOwner} />
  }

  return (
    <div style={{height: "100%", width: "100%"}}>
      <Switch>
        <Route
          exact
          path='/'
          component={RootBody}
        />
        <Route
          exact
          path='/projects'
          component={RootBody}
        />
        <Route
          path='/projects/new'
          render={(match)=>(<ProjectNew
            subscriptionState={props.subscriptionState}
          />)}
        />
        <Route
          path='/projects/:projectID/search'
          render={componentWithHeight(ProjectSearchContainer)}
        />
        <Route
          path='/projects/:projectID/analysis'
          render={componentWithHeight(AnalysisDisplayContainer)}
        />
        <Route
          path='/projects/:projectID/codes/:codeID'
          render={componentWithHeight(CodeBookContainer)}
        />
        <Route
          path='/projects/:projectID/codes'
          render={componentWithHeight(CodeBookContainer)}
        />
        <Route
          path='/projects/:projectID/chat/:conversationID'
          render={componentWithHeight(ProjectChatContainer)}
        />
        <Route
          path='/projects/:projectID/chat'
          render={componentWithHeight(ProjectChatContainer)}
        />
        <Route
          path='/projects/:projectID/transcripts/new'
          render={componentWithHeight(DragDropTranscriptContainer)}
        />
        <Route
          path='/projects/:projectID/surveys/new/:csvFileID'
          render={componentWithHeight(SurveyUploadingContainer)}
        />
        <Route
          path='/projects/:projectID/surveys/new'
          render={componentWithHeight(DragDropSurveyContainer)}
        />
        <Route
          path='/projects/:projectID'
          component={ProjectBody}
        />
        <Route
          path='/transcripts/:transcriptID'
          render={componentWithHeight(TranscriptNav)}
        />
        <Route
          path='/surveys/:surveyID/survey_questions/:surveyQuestionID'
          render={componentWithHeight(SurveyPage)}
        />
        <Route
          path='/surveys/:surveyID'
          render={componentWithHeight(SurveyPage)}
        />
        <Route
          path='/survey_responses/:surveyResponseID'
          render={componentWithHeight(SurveyResponsePage)}
        />
        <Route
          path='/survey_questions/:surveyQuestionID'
          render={componentWithHeight(SurveyQuestionPage)}
        />
        <Route
          path='/users'
          component={UserDisplay}
        />
        <Route
          path='/'
          render={() => <h3>Choose a Project</h3>}
        />
      </Switch>
    </div>
  );
}

export default QualContent;
