import React, { useRef, useEffect } from 'react';
import ChecklistDropdown from './ChecklistDropdown';

const dropDownStyle = {
  position: "relative"
};

const DropdownChecklistWrapper = ({
  codes = [],
  onChecked,
  applyFilter,
  dropdownAbove,
  subHeader,
  onDeleteFilter,
  onClearChecked,
  onClearFilter,
  filterButtonText,
  shown,
  setShown,
  children,
  dropdownAlignRight,
}) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShown(false);
    }
  };

  const handleDoneButton = applyFilter ? () => {
    setShown(false);
    if (applyFilter) {
      applyFilter();
    }
  } : null;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div style={dropDownStyle}>
        {children /* This is the button component */ }
        <ChecklistDropdown
          wrapperRef={wrapperRef}
          onDoneButton={handleDoneButton}
          shown={shown}
          onChecked={onChecked}
          codes={codes}
          dropdownAbove={dropdownAbove}
          subHeader={subHeader}
          onDeleteFilter={onDeleteFilter}
          onClearChecked={onClearChecked}
          onClearFilter={onClearFilter}
          filterButtonText={filterButtonText}
          dropdownAlignRight={dropdownAlignRight}
        />
      </div>
    </div>
  );
};

export default DropdownChecklistWrapper;
