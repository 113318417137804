// Desc: Modal for deleting a transcript

import React from 'react';
import DeleteModal from 'projects/components/DeleteModal';

function DeleteSurveyModal({
  cancelDelete,
  confirmDelete,
  showModal
}) {
  return <DeleteModal
    title="Are you sure you want to delete this survey?"
    onCancel={cancelDelete}
    onDelete={confirmDelete}
    show={showModal}
  >
    All responses and coded snippets from this transcript will be deleted.
  </DeleteModal>
}

export default DeleteSurveyModal;