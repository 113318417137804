// ExcerptMapper.js
import transcriptIDToProjectIDMapper from './TranscriptIDToProjectIDMapper';
import OptimisticMapperHelper from './OptimisticMapperHelper';
import excerptMemoMapper from './ExcerptMemoMapper';
import excerptCodesMapper from './ExcerptCodesMapper';
import excerptTranscriptMapper from './ExcerptTranscriptMapper';
const _ = require('underscore');

const excerptMapper = (state, excerptID) => {
  var excerpt = OptimisticMapperHelper.getExcerpt(state, excerptID);
  if ( !excerpt ) return null;

  const projectID = transcriptIDToProjectIDMapper(state, excerpt.transcript_id);
  
  excerpt = excerptMemoMapper(state, excerpt, projectID);  
  excerpt = excerptCodesMapper(state, excerpt);
  return excerptTranscriptMapper(state, excerpt);
}

export default excerptMapper
