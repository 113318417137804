// app/javascript/projects/components/CodeDisplay.jsx

import {Button} from 'react-bootstrap'
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import React from 'react';

import CodeStore from 'stores/CodeStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import ColorConstants from 'constants/ColorConstants'
import DimensionsConstants from 'constants/DimensionsConstants'

import CodeDragLayer from './CodeDragLayer'
import CodeNestedDisplay from './CodeNestedDisplay'
import CodingActions from '../../actions/CodingActions';
import MergeCodeActions from 'actions/MergeCodeActions'
import DeleteCodeModal from './DeleteCodeModal';

const _ = require('underscore')

const SEARCH_HEIGHT = 95;

// components
import FilterCodeForm from './FilterCodeForm'
bootstrapUtils.addStyle(Button, 'invis');

// Method to retrieve state from Stores

function _getCodeSideBar(props, state, searchText) {
  const results = CodeStore.getCodesSidebar(
    props.projectID,
    state.dragCode ? state.dragCode.codeID : null,
    searchText
  );
  return {
    codes: results.codes,
    topMargin: results.topMargin,
    bottomMargin: results.bottomMargin
  };
}

function _getClearState() {
  return {
    searchedCodeIndex: null,
    scrollIndex: undefined
  }
}

class CodeDisplay extends React.Component {
  constructor () {
    super();

    this.state = {
      newCodeText: "", // GOOD
      searchText: "", // GOOD
      deleteCode: {},
      dragCode: null,
      codes: [],
      ..._getClearState()
    };

    // dragging
    this.startDrag = this.startDrag.bind(this);


    /* delete code */
    this.cancelDelete = this.cancelDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.deleteCode = this.deleteCode.bind(this);


    this.onClick = this.onClick.bind(this); // PROPS
    this.addNewCode = this.addNewCode.bind(this);
    this.filterText = this.filterText.bind(this);
    this.onEnter = this.onEnter.bind(this);

    this._onChange = this._onChange.bind(this);
    this.onCollapseClicked = this.onCollapseClicked.bind(this);
    this.searchedCodeUnmounted = this.searchedCodeUnmounted.bind(this);
    this.initiateMergeCode = this.initiateMergeCode.bind(this);
  }

  clearSearchCode() {
    this.setState(_getClearState());
  }

  initiateMergeCode(mergeCodeId, mergeIntoCodeId) {
    this.clearSearchCode();
    MergeCodeActions.initiateMergeCode(mergeCodeId, mergeIntoCodeId);
  }

  onCollapseClicked(codeId, collapsed)
  {
    this.clearSearchCode();

    if ( collapsed ) {
      CodingActions.expandCodeLabel(this.props.projectID, codeId);
    } else {
      CodingActions.collapseCodeLabel(this.props.projectID, codeId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;

    const propsSelect = props.select || {};
    const stateSelect = state.prevSelect || {};

    if ( props.disabled !== state.prevDisabled ||
      propsSelect.start !== stateSelect.start ||
      propsSelect.end !== stateSelect.end ||
      propsSelect.codeableId !== stateSelect.codeableId
    ) {
      newState = {
        ..._getClearState(),
        prevDisabled: props.disabled,
        prevSelect: props.select
      };
    }

    if ( props.projectID != state.previousProjectID )
    {
      newState = {
        ...newState,
        ..._getCodeSideBar(props, state, state.searchText),
        previousProjectID: props.projectID
      }
    }

    return newState;
  }

  _onChange () {
    this._compileCodes(this.props, this.state, this.state.searchText);
  }

  componentDidMount () {
    CodedTranscriptStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    this._compileCodes(this.props, this.state, this.state.searchText);
  }

  componentWillUnmount () {
    CodedTranscriptStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
  }

  itemsEqual(code1, code2)
  {
    if ( !code1 && !code2 )
      return true;
    if ( !code1 && code2 )
      return false;
    if ( code1 && !code2 )
      return false;

    return code1.codeID == code2.codeID;
  }

  startDrag(dragCodeId) {
    const results = CodeStore.getCodesSidebar(this.props.projectID,
      dragCodeId,
      this.state.searchText);

    this.setState({
      codes: results.codes,
      topMargin: results.topMargin,
      bottomMargin: results.bottomMargin,
      dragCode: dragCodeId,
      ..._getClearState(),
      hoverCodeID: null,
      canMerge: false
    });
  }

  _compileCodes(props, state, searchText)
  {
    this.setState(_getCodeSideBar(props, state, searchText));
  }

  cancelDelete()
  {
    this.setState({
      deleteCode: {}
    })
  }

  confirmDelete()
  {
    if ( this.state.deleteCode.id )
      CodingActions.deleteCode(this.state.deleteCode.id)

    this.setState({
      deleteCode: {}
    })
  }

  deleteCode(code)
  {
    this.setState({
      deleteCode: code
    })
  }

  recursiveFirstCode(codes)
  {
    if ( !codes ) return null;
    for ( var codeIndex = 0; codeIndex < codes.length; codeIndex++ )
    {
      const code = codes[codeIndex];
      if ( !code.filtered ) return code;
      const returnedCode = this.recursiveFirstCode(code.children);
      if ( returnedCode ) return returnedCode;
    }

    return null;
  }

  // this occurs when a searched code is scrolled off the page
  // This is called to not fade a code continuously as it is scrolled
  // in and out of the page
  searchedCodeUnmounted() {
    this.clearSearchCode();
  }

  scrollToSearchedCode(codeId) {
    const results = CodeStore.getCodesSidebar(this.props.projectID, null, '');
    const index = _.findIndex(results.codes, {
      id: codeId
    });

    this.setState({
      codes: results.codes,
      topMargin: results.topMargin,
      bottomMargin: results.bottomMargin,
      newCodeText: "",
      searchText: "",
      scrollIndex: index ? index + 1 : 0,
      searchedCodeIndex: index
    });
  }

  onEnter (event) {
    const firstCode = this.recursiveFirstCode(this.state.codes);

    if ( firstCode )
    {
      if ( firstCode.newCode )
      {
        this.setState({
          scrollIndex: 0,
          searchedCodeIndex: 0
        })
        this.addNewCode(firstCode);
        this.clearSearch();
      }
      else
      {
        var selected = this.props.selectedCodes.includes(firstCode.id);
        this.props.onClick(firstCode?.id, selected);
        this.scrollToSearchedCode(firstCode.id);
        return;
      }
    }
  }

  filterText(text) {
    this.setState({
      searchText: text,
      ..._getClearState()
    });

    this._compileCodes(this.props, this.state, text);
  }

  clearSearch()
  {
    this.setState({
      newCodeText: "",
      searchText: ""
    });
    this._compileCodes(this.props, this.state, "");
  }

  addNewCode(code)
  {
    this.props.addNewCode(code?.name);
    this.clearSearch();
  }

  onClick(code, selected)
  {
    this.props.onClick(code?.id, selected);

    if ( this.state.searchText.length > 0 ) {
      this.scrollToSearchedCode(code.id);
    } else {
      this.clearSearchCode()
      this.clearSearch();
    }
  }

  render () {
    const height = `${this.props.height - SEARCH_HEIGHT}px`;

    return (
        <div style={{paddingLeft:"0px", height:"100%"}}>
          <CodeDragLayer/>
          <DeleteCodeModal
            show={!!this.state.deleteCode.id}
            name={this.state.deleteCode.name}
            onCancel={this.cancelDelete}
            onDelete={this.confirmDelete}
          />

          <style type="text/css">{`
          .btn-invis {
            background-color: Transparent;
            border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
            height:${DimensionsConstants.CODE_LABEL_HEIGHT.toString() + "px"};
            min-height:${DimensionsConstants.CODE_LABEL_HEIGHT.toString() + "px"};
            width:5px;
            max-width:5px;
            min-width:5px;
            margin:0px;
            padding:0px;
            border: none;
          }
          `}</style>

        <div style={{paddingLeft:DimensionsConstants.CODE_PADDING_LEFT, height:SEARCH_HEIGHT + "px", paddingTop:"10px"}}>
            <h2 style={{cursor:"default", marginTop:0}}>Codes</h2>
            <FilterCodeForm
              filterText={this.filterText}
              searchText={this.state.searchText}
              onEnter={this.onEnter}
              select={this.props.select}
            />
          </div>

          <div style={{height:height}}>
            <CodeNestedDisplay
              initiateMergeCode={this.initiateMergeCode}
              height={height}
              selectedCodes={this.props.selectedCodes}
              onClick={this.onClick}
              disabled={this.props.disabled}
              deleteCode={this.deleteCode}
              newCodeText={this.state.newCodeText}
              addNewCode={this.addNewCode}
              projectID={this.props.projectID}
              codes={this.state.codes}
              topMargin={this.state.topMargin}
              bottomMargin={this.state.bottomMargin}
              dragCode={this.state.dragCode}
              draggingEnabled={!this.state.searchText || this.state.searchText.length == 0}
              onCollapseClicked={this.onCollapseClicked}
              searchText={this.state.searchText}
              startDrag={this.startDrag}
              scrollIndex={this.state.scrollIndex}
              searchedCodeIndex={this.state.searchedCodeIndex}
              searchedCodeUnmounted={this.searchedCodeUnmounted}
              hideCodeCounts={this.props.hideCodeCounts}
            />
          </div>
        </div>
    );
  }
}

export default CodeDisplay;
