import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import FileConstants from 'constants/FileConstants'
import descriptorReducer  from 'reducers/DescriptorReducer'
import State from 'stores/State'
import projectDetailsMapper from 'mappers/ProjectDetailsMapper'
import projectStatusMapper from 'mappers/ProjectStatusMapper'
import transcriptIDToProjectIDMapper from 'mappers/TranscriptIDToProjectIDMapper'
import onboardingMapper from 'mappers/OnboardingMapper'
import editTranscriptMapper from 'mappers/EditTranscriptMapper'
import dragDropMapper from 'mappers/DragDropMapper'
import dragDropStatusMapper from 'mappers/DragDropStatusMapper'
import CashierConstants from 'constants/CashierConstants'

// import archive constants
import {
  ARCHIVE_PROJECT,
  UNARCHIVE_PROJECT,
} from 'project_archives/ArchiveConstants'

// import project archive reducer
import projectArchiveReducer from 'project_archives/ArchiveReducer'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

// TODO: refactor this away once you redo the new project experience
var _newProject = null;
function newProject(data) {
  _newProject = data;
}

var ProjectsStore = _.extend({}, EventEmitter.prototype, {
  getSessionID: function() {
    return State.get().session_id
  },

  getProjectLoadState: function(projectID) {
    const projectLoadState = State.get().loadingState.projectDetails[projectID];
    if ( projectLoadState )
      return projectLoadState;
    else
      return {loading: false, loaded: false};
  },

  getStateWithMapper: function(mapper)
  {
    return mapper(State.get());
  },

  getNewestProject: function() {
    return _newProject;
  },

  getProject : function(projectID) {
    return projectDetailsMapper(State.get(), projectID);
  },

  getOnboardingState: function(projectID) {
    return onboardingMapper(State.get(), projectID);
  },

  getProjectStatus: function() {
    return projectStatusMapper(State.get())
  },

  // TODO: temp function until a better one is written
  getProjectIDFromTranscriptID(transcriptID)
  {
    return transcriptIDToProjectIDMapper(State.get(), transcriptID);
  },

  // TODO: this should use a mapper
  getTranscript(transcriptID)
  {
    return State.get().entities.transcripts[transcriptID];
  },

  getEditTranscript(transcript_id, editTranscriptState)
  {
    return editTranscriptMapper(State.get(), transcript_id, editTranscriptState);
  },

  getFiles()
  {
    return dragDropMapper(State.get());
  },

  getFileStatus()
  {
    return dragDropStatusMapper(State.get());
  },

  getNextTranscript(skipTranscriptID)
  {
    const state = State.get();
    const projectID = this.getProjectIDFromTranscriptID(skipTranscriptID);
    if ( !projectID ) return null;
    if ( state.entities.projects[projectID] )
    {
      const transcripts = state.entities.projects[projectID].transcripts;
      if ( !transcripts ) return null;
      const filteredTranscripts = transcripts.filter((id)=>{return id != skipTranscriptID});
      if (filteredTranscripts.length == 0) return null;
      return filteredTranscripts[0];
    }
    return null;
  },

  // TODO: used in excerpts, refactor this away once excerpts are part of the redux state
  getTranscriptFromExcerpt(projectID, excerpt)
  {
    if ( !excerpt || !projectID) return null;

    var project = this.getProject(projectID);

    if ( !project ) return null;

    if ( !project.transcripts ) return null;

    var transcript = project.transcripts.filter(transcript => transcript.id == excerpt.transcript_id);

    if (transcript.length == 0 ) return null;
    transcript = transcript[0];

    if ( !transcript )
      return null;

    return transcript;
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// TODO: Worth trying to remove this
ProjectsStore.setMaxListeners(20);

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType)
  {
    // this is just to trigger changes
    case QualConstants.LOAD_TRANSCRIPT_RESULTS:
      break
    case QualConstants.NEW_PROJECT_RESULT:
      // TODO: this exception should not be needed
      newProject(action.data);
    case QualConstants.UPDATE_PROJECT_NAME:
    case QualConstants.PROJECT_CABLE:
    case QualConstants.LOADED_PROJECTS:
    case QualConstants.LOAD_PROJECT_DETAILS:
    case QualConstants.LOAD_PROJECT_DETAILS_RESULT:
    case QualConstants.TRANSCRIPT_CREATE_RESULT:
    case QualConstants.TRANSCRIPT_CREATE_CABLE:
    case QualConstants.DELETE_TRANSCRIPT:
    case QualConstants.DELETE_TRANSCRIPT_CABLE:
    case QualConstants.UPDATE_TRANSCRIPT_RESULT:
    case QualConstants.UPDATE_TRANSCRIPT_CABLE:
    case QualConstants.SHOW_NEW_PROJECT_MODAL:
    case QualConstants.HIDE_NEW_PROJECT_MODAL:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_RESULT:
    case FileConstants.PARSED_FILE:
    case FileConstants.PARSE_FILES:
    case FileConstants.PARSE_FILES_ERROR:
    case FileConstants.PARSED_FILE_ERROR:
    case FileConstants.PARSE_FILES_HIDE:
    case FileConstants.PARSE_FILES_SHOW:
    case FileConstants.PARSE_FILE_CLEAR:
      State.set(descriptorReducer(action, State.get()));
    break;
    case ARCHIVE_PROJECT:
    case UNARCHIVE_PROJECT:
      State.set(projectArchiveReducer(action, State.get()));
    break;
    case CashierConstants.FETCH_SUBSCRIPTIONS_RESULT:
    break;
    default:
      return true;
  }

  ProjectsStore.emitChange();
  return true;
});

export default ProjectsStore
