import React, { useState } from 'react';
import { Button, Glyphicon } from 'react-bootstrap'; // Adjust the import path as necessary
import DimensionsConstants from 'constants/DimensionsConstants'
import QualCodeActions from 'actions/QualCodeActions'


const TITLE_WIDTH = DimensionsConstants.LEFT_WIDTH - 60;

const LeftSideProject = ({
  hasEditPermission,
  isOnUserPage,
  project,
  projectName,
  showProjectModal,
  leftPadding,
}) => {
    // create state for editableProjectTitle
    const [editableProjectTitle, setEditableProjectTitle] = useState(null);

    const nonInputStyle = {padding:"1px 0px 0px 2px", cursor: "default"}
    const titleStyle = {
        paddingLeft: leftPadding,
        paddingTop:"20px",
        paddingBottom:"20px",
        fontSize:"1.25em",
        color:"white",
        float:"left",
        width: "100%"
      }
    const onTitleChange = (e) => {
        setEditableProjectTitle(e.target.value);
    }
      
  const handleTitleChange = (e) => {
    setEditableProjectTitle(e.target.value);
    onTitleChange(e);
  };

    const onTitleBlur = (e) => {
        if (project.name !== editableProjectTitle) {
            QualCodeActions.updateProjectName(project.id, editableProjectTitle);
        }

        setEditableProjectTitle(null);
    }

    const onTitleFocus = (e) => {
        setEditableProjectTitle(project.name);
    }

    const saveTitle = (e) => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            saveTitle();
        }
    }    

  return (
    <div style={titleStyle}>
      <div style={{ float: "left", width: `${TITLE_WIDTH}px` }}>
        {hasEditPermission && !isOnUserPage ? (
          <input
            style={{
              borderStyle: "none",
              outline: "none",
              width: "100%",
              background: "transparent",
            }}
            value={
              editableProjectTitle != null
                ? editableProjectTitle
                : projectName
                ? projectName
                : ""
            }
            onBlur={onTitleBlur}
            onChange={handleTitleChange}
            onFocus={onTitleFocus}
            onKeyDown={onKeyDown}
            placeholder="Untitled Project"
          />
        ) : (
          <div
            style={!projectName ? { color: "#727272", ...nonInputStyle } : nonInputStyle}
          >
            {projectName || "Untitled Project"}
          </div>
        )}
      </div>
      <div style={{ float: "left" }}>
        <Button bsStyle="link" onClick={showProjectModal}>
          <Glyphicon style={{ color: "white" }} glyph="chevron-down" />
        </Button>
      </div>
      <div style={{ clear: "both" }} />
    </div>
  );
};

export default LeftSideProject;