// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import ColorConstants from 'constants/ColorConstants';

function PageLoadingComponent() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        zIndex: 1000, // Optional: Ensures it's above other elements if needed
      }}
    >
      <ClipLoader
        color={ColorConstants.ACTIVE_BUTTON_BLUE}
        sizeUnit={"px"}
        size={19}
        loading={true}
      />
    </div>
  );
}

export default PageLoadingComponent;
