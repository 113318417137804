// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import TranscriptExcerptTitle from './TranscriptExcerptTitle'
import SurveyAnswerExcerptTitle from './SurveyAnswerExcerptTitle'

const ExcerptTitle = ({excerpt, onLinkClick}) => {
  if ( excerpt.codeable_type == 'Transcript' )
    return <TranscriptExcerptTitle excerpt={excerpt} onLinkClick={onLinkClick} />
  else if ( excerpt.codeable_type == 'SurveyAnswer' )
    return <SurveyAnswerExcerptTitle excerpt={excerpt} onLinkClick={onLinkClick}/>
  else
    return <div></div>
}

export default ExcerptTitle;

