import React from "react";
import ColorConstants from "constants/ColorConstants";

const ParagraphSpeakerComponent = ({speaker, time, onTitleClick}) => {
    if ( !speaker && !time ) return null;

    return (
        <>
            <span onClick={onTitleClick} style={{cursor: onTitleClick ? "pointer" : "default"}}>
                <b>{speaker}</b>
            </span>
            <span style={{color:ColorConstants.LIGHT_GREY, paddingLeft:speaker ? "10px" : "0px"}}>
                {time}
            </span>
        </>
    )
}

export default ParagraphSpeakerComponent;