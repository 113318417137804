// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ShareHeader from './ShareHeader'
import SubscribeHeader from './SubscribeHeader'
import HeaderFrame from './HeaderFrame'

function SubscriptionHeaderFrame({
  children,
  dropdownOptions,
  hasEditPermission,
  isActive,
  name,
  onRenew,
  onShowCollabModal,
  projectId,
  roleIsEdit,
  showCollabModal,
  subscriptionState,
}){
  
  const subscription = subscriptionState?.subscription || {};

  // view only person
  if ( !roleIsEdit && !!isActive ) {
    return <ShareHeader
      hasEditPermission={hasEditPermission}
      subscriptionState={subscriptionState || {}}
      onShowCollabModal={onShowCollabModal}
      showCollabModal={showCollabModal}
    >
      {name}
    </ShareHeader>
  }

  // cancelled person
  if ( subscriptionState?.isCancelled ) {
    return <SubscribeHeader
      isSubscriptionOwner={subscriptionState?.isOwner}
      onRenew={onRenew}
      isSeparatelyInvoiced={subscription.isSeparatelyInvoiced}
      onShowCollabModal={onShowCollabModal}
      showCollabModal={showCollabModal}
    >
      {name}
    </SubscribeHeader>
  }

  // TODO: does this happen?
  if ( !hasEditPermission ) {
    return (
      <ShareHeader
        hasEditPermission={hasEditPermission}
        subscriptionState={subscriptionState || {}}
        onShowCollabModal={onShowCollabModal}
        showCollabModal={showCollabModal}  
      >
        {name}
      </ShareHeader>
    )
  }

  return (
    <HeaderFrame
      dropdownOptions={dropdownOptions}
      projectID={projectId}
      hasEditPermission={hasEditPermission}
      subscriptionState={subscriptionState || {}}
      onShowCollabModal={onShowCollabModal}
      showCollabModal={showCollabModal}  
      projectId={projectId}
    >
      {children}
    </HeaderFrame>
  );
}

export default SubscriptionHeaderFrame;
