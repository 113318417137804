import React, { useState, useCallback, useEffect } from 'react';
import EditTranscriptModal from './EditTranscriptModal';
import QualCodeActions from 'actions/QualCodeActions';
import OutOfDateTranscriptModal from './OutOfDateTranscriptModal'
import ErrorStore from 'stores/ErrorStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'

const EditTranscriptContainer = ({ transcriptID }) => {
  const [editParagraphIndex, setEditParagraphIndex] = useState(null);
  const [versionError, setVersionError] = useState(ErrorStore.getVersioningError());

  const _onChange = useCallback(() => {
    // Ideally this could be stored locally, does not need to be in a Store.
    setEditParagraphIndex(CodedTranscriptStore.getEditState(transcriptID).paragraphIndex);
    setVersionError(ErrorStore.getVersioningError());
  }, [transcriptID]);

  useEffect(() => {
    CodedTranscriptStore.addChangeListener(_onChange);
    ErrorStore.addChangeListener(_onChange);
    return () => {
      CodedTranscriptStore.removeChangeListener(_onChange);
      ErrorStore.removeChangeListener(_onChange);
    };
  }, [_onChange]);

  const onCancel = useCallback(() => {
    if (!editParagraphIndex?.saving) {
      QualCodeActions.cancelEditParagraph();
    }
  }, [editParagraphIndex?.saving]);

  const onSave = useCallback((newText) => {
    if (editParagraphIndex?.paragraph === newText) {
      onCancel();
    } else if (!isNaN(transcriptID) && !isNaN(editParagraphIndex?.paragraph_id)) {
      QualCodeActions.doneEditParagraph(transcriptID, editParagraphIndex?.paragraph_id, newText);
    }
  }, [editParagraphIndex?.paragraph, transcriptID, editParagraphIndex?.paragraph_id, onCancel]);

  const error = editParagraphIndex?.error;
  const errorCode = error?.error ? (error?.errorCode || "other") : null;

  return (
    <>
      <EditTranscriptModal
        show={!!editParagraphIndex?.paragraph}
        text={editParagraphIndex?.paragraph}
        hideModal={onCancel}
        onSave={onSave}
        saving={editParagraphIndex?.saving}
        errorCode={errorCode}
      />
      <OutOfDateTranscriptModal show={!!versionError?.error}/>
    </>
  );
};

export default EditTranscriptContainer;
