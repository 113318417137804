// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useState, useCallback} from 'react';
const BreadcrumbContainer = ({children}) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',       // Hides any overflowing text
    whiteSpace: 'nowrap',     // Prevents wrapping
    textOverflow: 'ellipsis', // Adds ellipsis when text overflows
    maxWidth: '100%',
    paddingRight: '10px'         // Stays within the allocated width from the parent
  }}>
    {children}
  </div>
);

const HoverableSpan = ({children, onClick, isAlone}) => {
  const [isHovered, setIsHovered] = useState(false);
  const flexGrow = isAlone ? '1' : '0';     // Allows full expansion if it's alone
  const flexShrink = isAlone ? '1' : '1';   // Shrinks as needed if content is smaller than maxWidth
  const maxWidth = isAlone ? '100%' : '50%';

  return (
    <span
      style={{
        padding: '0px',
        borderRadius: '8px',
        backgroundColor: isHovered ? 'lightgrey' : 'transparent',
        // underline on hover
        textDecoration: isHovered ? 'underline' : 'none',
        // stylus for hover
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        flexGrow,
        flexShrink,
        maxWidth,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

const Breadcrumb = ({first, second, onClick}) => {
  const isAlone = !second;

  if ( isAlone )
    return  <div style={{
      width:"100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }}>{first}</div>;

  return (
    <BreadcrumbContainer>
      <HoverableSpan onClick={onClick} isAlone={isAlone}>
        {first}
      </HoverableSpan>
      {!isAlone && <span>&nbsp;/&nbsp;</span>}
      {!isAlone && (
        <span style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-block',
        }}>
          {second}
        </span>
      )}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
