import React, {useEffect, useState, useCallback} from 'react';
import CodedTranscriptUtil from 'utils/CodedTranscriptUtil';
import SurveyParagraphContainer from 'paragraph/SurveyParagraphContainer';

const SurveyAnswer = ({
  questionText,
  questionType,
  surveyQuestionId,
  surveyAnswer,
  excerpts,
  selectedExcerptId, 
  select,
  onParagraphClick,
  onTitleClick,
  logo,
  color,
  hasEditPermission
}) => {
  const [codedTranscript, setCodedTranscript] = useState({});
  const [primeCode, setPrimeCode] = useState(null);

  const onParagraphClickWithId = useCallback((start, end) => {
    onParagraphClick(start, end, surveyAnswer?.id, surveyQuestionId);
  }, [onParagraphClick, surveyAnswer?.id, surveyQuestionId]);

  const settingPrimeCode = (codeID, paragraphIndex) => {
    setPrimeCode({ codeID, paragraphIndex });
  };

  /*
  {
    "id":1,
    "created_at":"2024-09-09T13:03:12.197Z",
    "updated_at":"2024-09-09T13:03:12.197Z",
    "body":"I really enjoyed it"
  }

  or 

  {
    "id":2,
    "created_at":"2024-09-09T13:15:34.889Z",
    "updated_at":"2024-09-09T13:15:34.889Z",
    "survey_option":{
      "id":1,
      "option_text":"Ericeira"
    }
  }
  */

  useEffect(() => {
    if (!surveyAnswer?.body) {
      return;
    }

    // if the survey question has a text answer, create a new instance of CodedTranscriptUtil
    if ( questionType === 'single_choice' ) {
      return;
    }

    const codedTranscriptUtil = new CodedTranscriptUtil(surveyQuestionId, surveyAnswer.body);
    // this should not be calcualted down here. It shhould be calculated at a higher level
    // ideally codes can be a context as well as collaborators
    // and then that can be passed in to create excertps that can be passed down to codedTranscriptUtil
    // I don't think we should depend on CodeStore but have a CodesContext that can cause re-renders appropriately

    codedTranscriptUtil.addExcerpts(excerpts || []);

    let passedInSelect = select;

    if ( selectedExcerptId ) 
      passedInSelect = excerpts.find((excerpt) => excerpt.id.toString() === selectedExcerptId);

    codedTranscriptUtil.setPrimeCode(primeCode?.paragraphIndex, primeCode?.codeID);

    setCodedTranscript(codedTranscriptUtil.getCodedTranscript(
      null,
      passedInSelect,
      selectedExcerptId)
    );
  }, [excerpts, selectedExcerptId, select, primeCode]);

  if (questionType === 'text') {
    const paragraphItems = (codedTranscript?.paragraphs || []).map((paragraph, index) =>
      {
        if ( !paragraph || (paragraph.start === paragraph.end)) return null;
  
        return (
          <SurveyParagraphContainer codedParagraph={paragraph}
                              absoluteIndex={paragraph.index}
                              paragraphIndex={index}
                              transcriptID={surveyQuestionId}
                              canEdit={false}
                              isPrint={false}
                              hasEditPermission={hasEditPermission}
                              onParagraphClick={onParagraphClickWithId}
                              key={paragraph.index}
                              hideTitle={index == 0 ? false : true}
                              title={index == 0 ? questionText : null}
                              onTitleClick={onTitleClick}
                              logo={logo}
                              color={color}
                              settingPrimeCode={settingPrimeCode} // Pass the custom function
          />
        );
      }
    );

    return <div>
      {paragraphItems}
    </div>

/*return (<div>
          {surveyAnswer?.body || 'No answer provided'}
        </div>)*/
  } else if (questionType === 'single_choice') {
    return (<div>
        {surveyAnswer?.survey_option?.option_text || 'No answer provided'}
      </div>
    )
  } else {
    return <div>No answer provided</div>;
  }
};

export default SurveyAnswer;