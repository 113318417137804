import React from 'react';
import {Glyphicon} from 'react-bootstrap'
import ClipLoader from 'react-spinners/ClipLoader';
import ColorConstants from 'constants/ColorConstants'
import ModalRow from './ModalRow'
import { useHistory } from 'react-router-dom';

function FileRow({
  loaded,
  error,
  errorMessage,
  name,
  transcript_id,
  onHide
}) {
  const history = useHistory();
  /*
    Open can be used to open the native file picker
  */
  let rightHand = (
    <ClipLoader
                color={ColorConstants.ACTIVE_BUTTON_BLUE}
                sizeUnit={"px"}
                size={19}
                loading={true}/>);

  if ( loaded )
    rightHand = <Glyphicon style={{
        fontSize:'1.4em',
        color:ColorConstants.ACTIVE_BUTTON_BLUE,
        width:"21px",
        height:"21px"
      }} glyph="ok"/>

  if ( error )
    rightHand = <div style={{color:ColorConstants.ERROR_TEXT}}>{errorMessage || 'Something went wrong'}</div>
    //rightHand = <Alert bsStyle="danger" style={{padding:10, margin:0}}>{props.errorMessage || 'Something went wrong'}</Alert>

  return <ModalRow
      row={[
        <div style={{whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width:"100%",
      }}>
          {loaded && transcript_id ? (
            <span 
              style={{
                color: ColorConstants.ACTIVE_BUTTON_BLUE,
                cursor: "pointer",
                whiteSpace: "nowrap", 
                overflow: "hidden", 
                textOverflow: "ellipsis"
              }} 
              onClick={() => {
                history.push(`/transcripts/${transcript_id}`);
                onHide();
              }}
              onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
            >
              {name}
            </span>
          ) : (
            name
          )}
        </div>,
        rightHand
      ]}
    />
}


export default FileRow
