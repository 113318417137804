const getHost = () => {
  return window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
};

const getRelativeExcerptUrl = (excerpt) => {
  if (excerpt.survey_row_id) {
    return `/survey_responses/${excerpt.survey_row_id}?snippet=${excerpt.server_id || excerpt.id}`;
  } else if (excerpt.transcript_id) {
    return `/transcripts/${excerpt.transcript_id}?snippet=${excerpt.server_id || excerpt.id}`;
  } else {
    return '';
  }
};

const getAbsoluteExcerptUrl = (excerpt) => {
  const host = getHost();
  const baseUrl = `https://${host}`;
  const relativeUrl = getRelativeExcerptUrl(excerpt);

  return `${baseUrl}${relativeUrl}`;
};

export { getRelativeExcerptUrl, getAbsoluteExcerptUrl };
