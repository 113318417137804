// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import QualCodeActions from 'actions/QualCodeActions'

import SentencesComponent from 'codeable/SentencesComponent';
import ParagraphCodeList from 'codeable/ParagraphCodeList';
import ParagraphLayout from 'paragraph/ParagraphLayout';

class SpeakerParagraphComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      paragraphHover: false,
      showTools: false,
      hoverExcerptId: null,
    }
    this.onClick = this.onClick.bind(this);
    this.tagEnter = this.tagEnter.bind(this);
    this.tagLeave = this.tagLeave.bind(this);
    this.onParagraphHover = this.onParagraphHover.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.memoClicked = this.memoClicked.bind(this);
    this.onHoverExcerpt = this.onHoverExcerpt.bind(this);
  }

  onHoverExcerpt(excerptId) {
    this.setState({hoverExcerptId: excerptId});
  }

  memoClicked(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.memoClicked(this.props.codedParagraph.memoedExcerptIds || []);
  }
  
  shouldComponentUpdate(nextProps, nextState)
  {
    if ( nextState.showTools != this.state.showTools ) return true;
    if ( nextState.paragraphHover != this.state.paragraphHover ) return true;
    if ( !nextProps.codedParagraph ) return false;
    if ( !this.props.codedParagraph ) return true;
    if ( JSON.stringify(nextProps.codedParagraph) != JSON.stringify(this.props.codedParagraph)) return true;
    if ( nextProps.hasEditPermission !== this.props.hasEditPermission ) return true;
    if ( this.state.hoverExcerptId !== nextState.hoverExcerptId ) return true;
    return false;
  }

  onMouseEnter(e)
  {
    this.setState({
      showTools: true,
    })
  }

  onMouseLeave(e)
  {
    this.setState({
      showTools: false,
    })
  }

  onParagraphHover(hover)
  {
    if ( this.props.isPrint || !this.props.hasEditPermission ) return;
    this.setState({paragraphHover: hover});
  }

  tagEnter(codeID)
  {
    const { settingPrimeCode } = this.props;
    if (settingPrimeCode) {
      settingPrimeCode(codeID, this.props.absoluteIndex);
    } else {
      QualCodeActions.addPrimeCode({
        codeID: codeID,
        paragraphIndex: this.props.absoluteIndex,
      });
    }
  }

  tagLeave(codeID)
  {
    const { settingPrimeCode } = this.props;
    if (settingPrimeCode) {
      settingPrimeCode(null, null);
    } else {
      QualCodeActions.removePrimeCode({
        codeID: codeID,
        paragraphIndex: this.props.absoluteIndex,
      });
    }
  }

  onClick(target, event)
  {
    if ( !this.props.hasEditPermission ) return;
    this.props.onParagraphClick(this.props.codedParagraph.start, this.props.codedParagraph.end);
  }

  render () {
    if ( !this.props.codedParagraph || this.props.codedParagraph.end - this.props.codedParagraph.start <= 0)
      return (<div></div>)

    const speaker = this.props.title || this.props.codedParagraph.speaker;
    const time = this.props.codedParagraph.time;
    const color = this.props.color || this.props.codedParagraph.color || null;

    return (
      <ParagraphLayout
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        hasEditPermission={this.props.hasEditPermission}
        onParagraphHover={this.onParagraphHover}
        marginLeft = {this.props.marginLeft}
        marginRight = {this.props.marginRight}
        speaker={speaker}
        time={time}
        color={color}
        paragraphIndex={this.props.paragraphIndex}
        start={this.props.codedParagraph.start}
        end={this.props.codedParagraph.end}
        logo={this.props.logo}
        onParagraphClick={this.onClick}
        onEditClick={this.props.onEditClick}
        canEdit={this.props.canEdit}
        isPrint={this.props.isPrint}
        showTools={this.state.showTools}
        hideTitle={this.props.hideTitle}
        onTitleClick={this.props.onTitleClick}
      >
        <div>
          <SentencesComponent
            sentences={this.props.codedParagraph.sentences}
            paragraphHover={this.state.paragraphHover}
            isPrint={this.props.isPrint}
            hasEditPermission={this.props.hasEditPermission}
            onHoverExcerpt={this.onHoverExcerpt}
            hoverExcerptId={this.state.hoverExcerptId}
          />

          <ParagraphCodeList
            codes={this.props.codedParagraph.codes}
            hasEditPermission={this.props.hasEditPermission}
            onCodeRemove={this.props.onRemoveCode}
            tagEnter={this.tagEnter}
            tagLeave={this.tagLeave}
          />
        </div>
      </ParagraphLayout>
    )
  }
}

export default SpeakerParagraphComponent;
