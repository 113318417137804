import React, { useState, useEffect, useCallback } from 'react';
import SurveyLink from './SurveyLink';
import SurveyAPI from 'apis/SurveyAPI';
import { useSurvey } from 'surveys/SurveyContextProvider';


const LeftSideSurveyList = ({ projectId, isOnUserPage, onLinkClick }) => {
  const { survey_id, changed_survey_id} = useSurvey();

  const [surveys, setSurveys] = useState([]);
  
  // on load or projectID change, fetch surveys
  // TODO: handle error
  useEffect(() => {
    if (projectId ) {
      SurveyAPI.getSurveys(projectId).then((data)=>{
        setSurveys(((data || {}).data || {}).surveys || []);
      }).catch((error) => console.error(error)
    )}
  }, [projectId, changed_survey_id]);

  if ( !!isOnUserPage )
    return null;

  return surveys.map((survey) => (
    <SurveyLink
      key={survey.id}
      surveyId={survey.id}
      selected={parseInt(survey_id) === survey.id}
      onLinkClick={onLinkClick}
    >
      {survey.title}
    </SurveyLink>
  ));
};

export default LeftSideSurveyList;