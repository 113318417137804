import React, { useEffect, useRef } from 'react';
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap';

const FilterCodeForm = ({ filterText, searchText, placeholder, onEnter, select }) => {
  const myInp = useRef(null);

  const handleNewCodeTextChange = (event) => {
    filterText(event.target.value);
  };

  const focus = () => {
    if (myInp.current) {
      myInp.current.focus();
    }
  };

  useEffect(() => {
    if (select) {
      requestAnimationFrame(() => {
        focus();
      });
    }
  }, [select?.start, select?.end, select?.codeableId]);

  const onKeyUp = (event) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <div>
      <FormGroup bsSize="small">
        <InputGroup bsSize="small">
          <FormControl
            type="text"
            bsSize="small"
            value={searchText}
            onChange={handleNewCodeTextChange}
            placeholder={placeholder || "Search or Add Codes"}
            onKeyUp={onKeyUp}
            inputRef={(ref) => { myInp.current = ref; }}
          />
        </InputGroup>
      </FormGroup>
    </div>
  );
};

export default FilterCodeForm;
