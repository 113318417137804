import React, { useContext } from 'react';
import SpeakerParagraphComponent from 'projects/components/SpeakerParagraphComponent';
import CodeableContext from 'contexts/CodeableContext';

const _ = require('underscore');

const SurveyParagraphContainer = ({
  absoluteIndex,
  codedParagraph,
  paragraphIndex,
  canEdit,
  hasEditPermission,
  isPrint,
  marginLeft,
  marginRight,
  onParagraphClick,
  title,
  hideTitle,
  onTitleClick,
  logo,
  color,
  settingPrimeCode, // Add the prop
}) => {  
  const {onRemoveCode} = useContext(CodeableContext) || {};

  return (
    <SpeakerParagraphComponent
      codedParagraph={codedParagraph}
      paragraphIndex={paragraphIndex}
      absoluteIndex={absoluteIndex}
      canEdit={canEdit}
      hasEditPermission={hasEditPermission}
      isPrint={isPrint}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onParagraphClick={onParagraphClick}
      onRemoveCode={onRemoveCode}
      title={title}
      hideTitle={hideTitle}
      logo={logo}
      color={color}
      onTitleClick={onTitleClick}
      settingPrimeCode={settingPrimeCode} // Pass the prop
    />
  );
};

export default SurveyParagraphContainer;
