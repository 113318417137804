// ExcerptMapper.js
const _ = require('underscore');

const excerptTranscriptMapper = (state, excerpt) => {
  if ( !excerpt ) return null;

  const transcript = state.entities.transcripts[excerpt.transcript_id] ? state.entities.transcripts[excerpt.transcript_id] : {};

  return {
    ...excerpt,
    transcript:{
      id: transcript.id,
      name: transcript.name ? transcript.name : "Untitled",
      length: transcript.body ? transcript.body.length : 0,
    },
  }
}

export default excerptTranscriptMapper
