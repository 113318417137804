import React, {useCallback, useContext} from 'react';
import { MenuItem } from 'react-bootstrap';
import { useSurvey } from 'surveys/SurveyContextProvider';
import SubscriptionHeaderFrame from './SubscriptionHeaderFrame';
import Header from './Header';
import Breadcrumb from './Breadcrumb';
import DeleteSurveyModal from 'surveys/DeleteSurveyModal';
import useModal from 'modals/useModal';
import ProjectContext from 'contexts/ProjectContext'; // Import ProjectContext

function SurveyHeader({
  hasEditPermission,
  subscriptionState,
  onShowCollabModal,
  showCollabModal,
  onRenew,
  roleIsEdit,
  isActive,
  history
}) {
  const { project } = useContext(ProjectContext); // Use projectId from ProjectContext
  const projectId = project?.id;
  const {
    survey_title,
    survey_response_title,
    survey_id,
    survey_question_title,
    deleteSurvey,
    survey_loaded,
  } = useSurvey();

  const onConfirmDelete = useCallback(() => {
    deleteSurvey(survey_id);
    // Delete survey
  }, [survey_id, deleteSurvey]);

  const {
    showModal,
    onShowModal,
    onCancelModal,
    onConfirmModal,
  } = useModal(onConfirmDelete);

  const onSurveyClicked = useCallback(() => {
    if (survey_id) {
      history.push(`/surveys/${survey_id}`);
    }
  }, [survey_id, history]);

  if ( !survey_loaded ) {
    return null;
  }

  const breadCrumb = <Breadcrumb
    onClick={onSurveyClicked}
    first={survey_title}
    second={survey_response_title || survey_question_title}
  />;

  return (
    <div>
      <DeleteSurveyModal
        showModal={showModal}
        cancelDelete={onCancelModal}
        confirmDelete={onConfirmModal}
      />

      <SubscriptionHeaderFrame
        name={breadCrumb}
        hasEditPermission={hasEditPermission}
        subscriptionState={subscriptionState}
        onShowCollabModal={onShowCollabModal}
        showCollabModal={showCollabModal}
        isActive={isActive}
        onRenew={onRenew}
        roleIsEdit={roleIsEdit}
        projectId={projectId} // Pass projectId here
        dropdownOptions={[
          <MenuItem key='delete' eventKey="1" onClick={onShowModal}>
            Delete Survey
          </MenuItem>
        ]}
      >
        <Header>{breadCrumb}</Header>
      </SubscriptionHeaderFrame>
    </div>
  );
}

export default SurveyHeader;
