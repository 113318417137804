import getCheckedTranscripts from './filters/CheckedTranscriptsMapper'

const analysisTranscriptMapper = (state, filterState, projectID) => {
  try {
    const nullState = {transcripts:[]};
    if ( !projectID ) nullState;
    if ( !state.entities.projects[projectID]) return nullState;

    const projects = filterState.projects || {};
    const project = projects[projectID] || {};
    const transcriptToCount = (project.counts || {}).transcript_ids || {};

    const transcriptIDs = state.entities.projects[projectID].transcripts;
    if ( !transcriptIDs ) return nullState;

    const checkedTranscripts = getCheckedTranscripts(filterState, projectID);

    var transcripts = transcriptIDs.map((id)=>{
      const transcript = state.entities.transcripts[id];
      if ( !transcript ) return null;
      return {
        id: transcript.id,
        name: transcript.name ? transcript.name : "Untitled",
        count: transcriptToCount[id] ? transcriptToCount[id] : 0,
        checked: checkedTranscripts.includes(transcript.id)
      }
    }).filter((transcript)=>{ return !!transcript })
    .sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return {transcripts: transcripts};
  } catch (e) {
    console.error(e);
    return {transcripts:[]};
  }
}

export default analysisTranscriptMapper
