import React, {useEffect, useState} from 'react';
import DescriptorDropdown from 'projects/components/DescriptorDropdown';
import SurveyAPI from 'apis/SurveyAPI';

const SurveyFilterButton = ({
    option_ids,
    question,
    optionAdded,
    onOptionRemoved,
    onDeleteFilter,
    onClearChecked
}) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if ( !question ) return;
        SurveyAPI.getSurveyQuestionOptions(question.id)
            .then((response) => {
                setOptions(response.data['survey_options']);
            })
            .catch((error) => {
                console.error('Error getting survey question options:', error);
            })
    }, [question?.id]);

    const onChecked = (id, checked) => {
        if ( !checked ) {
            optionAdded(question.id, id);
        } else {
            onOptionRemoved(question.id, id);
        }
    }
    
    if ( !question ) return null;
    option_ids = option_ids || [];

    return (<DescriptorDropdown
                key={question.id}
                name={question?.question_text}
                subHeader="Filter responses by any of the selected"
                onChecked={onChecked}
                onDeleteFilter={() => onDeleteFilter(question.id)}
                onClearChecked={() => onClearChecked(question.id)}
                codes={options.map((option) => {
                    const checked = option_ids.includes(option.id);

                    return {
                        name: option?.option_text,
                        id: option?.id,
                        count: option?.count,
                        checked: checked
                    }
                }).filter((option) => option !== null)}
                />)
}

export default SurveyFilterButton;