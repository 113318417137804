// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import NetworkLayer from './NetworkLayer';

const SuccessDisplay = ({location, history}) => {
  
  useEffect(() => {
    ReactGA.pageview('/welcome');
    setTimeout(() => history.push('/projects'), 50);
  }, [history]);

  return (
    <NetworkLayer/>
  );
}

export default SuccessDisplay;
