import React, { useState } from 'react';
import DropdownChecklistWrapper from './DropdownChecklistWrapper';
import { Button } from 'react-bootstrap';
import { IoIosSettings } from "react-icons/io";
import { IconContext } from "react-icons";

const GearDropdown = ({
  codes = [],
  onChecked,
  applyFilter,
  dropdownAbove,
  subHeader,
  onDeleteFilter,
  onClearChecked,
  onClearFilter,
  filterButtonText,
}) => {
  const [shown, setShown] = useState(false);

  const handleClick = () => {
    setShown((prevShown) => !prevShown);
  };

  return (
    <DropdownChecklistWrapper
      codes={codes}
      onChecked={onChecked}
      applyFilter={applyFilter}
      dropdownAbove={dropdownAbove}
      subHeader={subHeader}
      onDeleteFilter={onDeleteFilter}
      onClearChecked={onClearChecked}
      onClearFilter={onClearFilter}
      filterButtonText={filterButtonText}
      shown={shown}
      setShown={setShown}
      dropdownAlignRight={true}
    >
      <Button
        onClick={handleClick}
        bsStyle="link"
      >
        <IconContext.Provider value={{ size:'1.25em', margin:0, padding:0 }}>
          <IoIosSettings/>
        </IconContext.Provider>
      </Button>
    </DropdownChecklistWrapper>
  );
};

export default GearDropdown
