// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { DragItemTypes } from 'constants/DragConstants';
import { DropTarget } from 'react-dnd';
import ColorConstants from 'constants/ColorConstants'
import DimensionsConstants from 'constants/DimensionsConstants'
import { IoIosGitMerge } from "react-icons/io"
import { IconContext } from "react-icons";
import CombineCodesOverlay from './CombineCodesOverlay'

const LARGE_BORDER_RADIUS = "5px"

const codeTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const didDrop = monitor.didDrop();
    props.initiateMergeCode(item.codeID, props.codeID);
  },

  canDrop(props, monitor) {
    return true;
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

class MergeDropZone extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  componentDidUpdate (prevProps) {
    if ( prevProps.isOver !== this.props.isOver) {
      this.props.onDragHover(this.props.isOver)
    }
  }


  render () {
    const { connectDropTarget, isOver} = this.props;
    const dialogueShow = this.props.showConfirmMerge;
    const show = isOver || this.props.showMerge || dialogueShow;

        return connectDropTarget(
           <div style={{
                          width: "100%",
                          height: DimensionsConstants.CODE_LABEL_HEIGHT * 2
                          }}>
            {this.target && <CombineCodesOverlay
              show={!!dialogueShow}
              container={this.props.container}
              target={this.target}
              onClick={this.props.confirmMerge}
              onHide={this.props.onHide}
              />}
              <div style={{
                            width: DimensionsConstants.CODE_LABEL_HEIGHT,
                            height: DimensionsConstants.CODE_LABEL_HEIGHT,
                            backgroundColor: !!show ? ColorConstants.ULTRA_LIGHT_GREY : null,
                            borderRadius: LARGE_BORDER_RADIUS,
                            position:"relative",
                            }}
                            ref={target => {
                              this.target = target;
                            }}
                >
                {!!show &&
                  <div>
                    <div style={{position:"absolute",
                                  top:"8%",
                                  width:"100%",
                                  textAlign:"center"
                                }}>
                      <div style={{margin:0, padding:0}}>
                        <IconContext.Provider value={{ color: ColorConstants.LIGHT_GREY, size:'1.25em', margin:0, padding:0 }}>
                          <IoIosGitMerge/>
                        </IconContext.Provider>
                      </div>
                    </div>
                    <div style={{position:"absolute",
                                  bottom:"8%",
                                  width:"100%",
                                  textAlign:"center",
                                  color: ColorConstants.LIGHT_GREY
                                }}>
                      <div style={{margin:0, padding:0}}>
                        <div style={{margin:0,padding:0, fontSize:'0.6em'}}>
                          Merge
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
        );
      }
    }


export default DropTarget(DragItemTypes.CODE_LABEL, codeTarget, collectTarget)(MergeDropZone);
