// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback} from 'react';

import NewTranscript from './NewTranscript'
import OnboardingTranscript from './OnboardingTranscript'
import OnboardingActions from 'actions/OnboardingActions'
import TranscriptActions from 'actions/TranscriptActions'

function NewTranscriptContainer({projectID, height, isHover, onboardingState, history}) {
  const handleSubmit = useCallback((name, body) => {
    if ( ! projectID ) return;

    TranscriptActions.createTranscript({
      project_id:projectID,
      body: body,
      name: name
    }).then((data)=>{
      if ( !data ) return;
      const transcript = data.transcript || {};
      if ( !transcript.id ) return;
      if ( !history ) return;
      history.push("/transcripts/" + transcript.id);
    });
  }, [projectID, history]);

  const getStartedClick = () => {
    OnboardingActions.getStarted();
  }

  if ( !onboardingState.newTranscriptOnboarding ) {
      if ( !onboardingState.loaded ) {
        return (<div></div>)
      }
      else {
        return (
          <div>
            <NewTranscript
                height={height}
                isHover={isHover}
                handleSubmit={handleSubmit}/>
          </div>
        );
      }
    }
  else {
    return (<OnboardingTranscript onClick={getStartedClick}/>);
  }
}

export default NewTranscriptContainer;
