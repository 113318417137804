import React from 'react';
import FullPageMessage from 'projects/components/FullPageMessage';
import QualCodeActions from 'actions/QualCodeActions';

const SurveyNotFound = () => {
  return (
    <FullPageMessage
      header='Survey not Found'
      body='The survey you are looking for does not exist or you do not have access to it.'
      onClick={() => QualCodeActions.showProjectModal()}
      buttonText='See Your Projects'
    />
  );
};

export default SurveyNotFound;
