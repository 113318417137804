// app/javascript/projects/components/DeleteModal.jsx

import React from 'react';
import TwoOptionModal from 'projects/components/TwoOptionModal';

function DeleteModal({title, children, onCancel, onDelete, show}) {
  return <TwoOptionModal
    title={title}
    onNo={onCancel}
    onYes={onDelete}
    noText="No Cancel"
    yesText="Yes, Delete It"
    show={show}
  >
    {children}
  </TwoOptionModal>
}

export default DeleteModal;
