import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import FilterActions from 'actions/FilterActions';
import Citation from './Citation';

import MyMarkdownComponent from './MyMarkdownComponent';
import citationCreation, {extractSnippetIds} from './CitationCreation';

const Message = ({
    message,
    content,
    excerpts,
    selectedExcerptID,
    projectID,
    postfix, 
    hasEditPermission,
    name,
    onExcerptSelected
}) => {
    const [showSnippets, setShowSnippets] = useState(false);
    const [activeSnippetId, setActiveSnippetId] = useState(null);
    const snippetRefs = useRef({});
    const markdownRef = useRef(null);

    const onExcerptTextClicked = (snippetId) => {
        onExcerptSelected(snippetId);
    }

    const onSnippetClicked = (snippetId) => {
        // extract the number from snippetId which is of format #Snippet_<number>
        const snippetNumber = snippetId.match(/\d+/)[0];

        setShowSnippets(true);
        setActiveSnippetId(snippetNumber);
        //onExcerptTextClicked(snippetNumber);
        const excerpt = excerpts[snippetNumber];
        onExcerptSelected(snippetNumber);
    }

    const scrollToTop = () => {
        markdownRef.current.scrollIntoView({ behavior: 'smooth' });
        setActiveSnippetId(null);
    }

    const paragraphContent = (content) => {
        // Find snippets IDs by looking for the pattern #Snippet_<id>. There will be multiple snippets in the content
        const {linkContent, snippetCitations, snippetIds} = citationCreation(content);

        snippetIds.forEach(id => {
            snippetRefs.current[id] = React.createRef();
        });

        return (
            <React.Fragment>
                <div ref={markdownRef}>
                    <MyMarkdownComponent onLinkClicked={onSnippetClicked}>
                        {linkContent}
                    </MyMarkdownComponent>
                </div>
                <div style={{marginTop:'10px'}}>
                    <MyMarkdownComponent>
                        {postfix || ''}
                    </MyMarkdownComponent>
                </div>
                {snippetIds.length > 0 && 
                    <React.Fragment>
                        {!showSnippets ? (
                            <Button style={{marginLeft:'0px', paddingLeft: '0px'}} bsStyle="link" onClick={() => setShowSnippets(!showSnippets)}>
                                See {snippetIds.length} supporting snippets
                            </Button>
                        ) : (
                            <Button style={{marginLeft:'0px', paddingLeft: '0px'}} bsStyle="link" onClick={() => setShowSnippets(!showSnippets)}>
                                Hide snippets
                            </Button>
                        )}
                        {showSnippets && snippetIds.map((snippetId, index) => 
                            excerpts[snippetId] && 
                                <Citation
                                    key={index}
                                    citationRef={snippetRefs.current[snippetId]}
                                    onClick={scrollToTop}
                                    citationNumber={(snippetCitations[snippetId] || {}).order}
                                    excerpt={excerpts[snippetId]}
                                    onTextClicked={() => onExcerptTextClicked(snippetId)}
                                    selected={selectedExcerptID == snippetId}
                                    hasEditPermission={hasEditPermission}
                                />
                        )}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    const prevExcerptRef = useRef(null);

    useEffect(() => {
        // If the current excerpt is loaded and the activeSnippetId has changed...
        if (showSnippets && excerpts[activeSnippetId] && activeSnippetId !== prevExcerptRef.current) {
            // Perform the scroll.
            if (snippetRefs.current[activeSnippetId] && snippetRefs.current[activeSnippetId].current) {
                snippetRefs.current[activeSnippetId].current.scrollIntoView({ behavior: 'smooth' });
                prevExcerptRef.current = activeSnippetId;
            }
                
        }
        // Update the ref with the current excerpt for the next render.
    }, [showSnippets, excerpts, activeSnippetId]);

    // when showSnippets is true, grab the currently available snippets for this message
    useEffect(() => {
        if (showSnippets) {
            const snippetIds = extractSnippetIds(content);
            if (snippetIds.length > 0)
                FilterActions.bulkGetExcerpts(projectID, snippetIds)
        }
    }, [showSnippets]);

    return (
        <div style={{ padding: '10px', borderBottom: '1px solid #ddd' }} className="message">
            {message.user_id == 'bot' ? 
                <div style={{fontWeight: 'bold'}}>AI Assistant: </div> 
                : <div style={{fontWeight: 'bold'}}>{name}</div>}
            {paragraphContent(content)}
        </div>
    )
};

export default Message;