// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useCallback, useRef } from 'react';
var _ = require('underscore');

  // this function gets the index of the codeSpan, highlightSpan, sentenceSpan, and paragraph
  // which you need to calculate the start and end of the selection
function getSpanIndex(node, type) {
  const span = node.closest(`.${type}Span`);
  if ( !span ) return {};

  const index = parseInt(span.getAttribute(`${type}-index`));
  return {span, index};
}

function getCodeSpanIndex(node) {
  return getSpanIndex(node, 'code');
}

function getHighlightSpanIndex(node) {
  return  getSpanIndex(node, 'highlight');
}

function getSentenceSpanIndex(node) {
  return getSpanIndex(node, 'sentence');
}

function getParagraphIndex(node) {
  return getSpanIndex(node, 'paragraph');
}

function _getParagraphStartEnd(node) {
  const {
    index: paragraphIndex,
    span: paragraph
  } = getParagraphIndex(node);

  const start = parseInt(paragraph.getAttribute('start'));
  const end = parseInt(paragraph.getAttribute('end'));
  return {
    paragraphIndex,
    start,
    end
  }
}

function getExcerptId(node) {
  const {span: sentenceSpan, index: sentenceIndex} = getSentenceSpanIndex(node);
  if ( !sentenceSpan ) return null;

  return sentenceSpan.getAttribute('excerpt-id') || null;
}

function getCodeSpanStartEnd(node) {
  const {span: codeSpan} = getCodeSpanIndex(node);
  if ( !codeSpan ) return {};
  return {
    start: parseInt(codeSpan.getAttribute('start')),
    end: parseInt(codeSpan.getAttribute('end'))
  }
}

function getClickedSpan(node) {
  return getCodeSpanStartEnd(node);
}

function _isClick(startNode, endNode, startOffset, endOffset) {
  // not sure quite what would cause this, but if we don't have a start or end hash
  // we should return true because there is no selection
  // I have noticed in cypress startHash and endHash are sometimes null
  // when a user clicks on the x button to remove a highlight
  if ( startOffset != endOffset ) return false;

  // loop through the span types, and check if they are equal
  // if any are not equal, return false
  const getSpanFunctions = [getCodeSpanIndex, getHighlightSpanIndex, getSentenceSpanIndex, getParagraphIndex];
  return getSpanFunctions.every((func) => {
    const {index: startIndex} = func(startNode);
    const {index: endIndex} = func(endNode);
    return startIndex === endIndex;
  });
}

function getNodeFromContainer(node) {
  return node.nodeType === Node.TEXT_NODE ? node.parentNode : node;
}


function getStartNodeAndEndNode() {
  var s = window.getSelection();

  if ( !s || s.rangeCount == 0 )
    return {}

  var startRange = s.getRangeAt(0);
  var endRange = s.getRangeAt(s.rangeCount - 1);

  const startNode = getNodeFromContainer(startRange.startContainer);
  const endNode =  getNodeFromContainer(endRange.endContainer);    

  return {
    startNode,
    endNode,
    startRange,
    endRange
  }
}

function  clickedOnCodeTag(isClick, event) {
  if ( !isClick ) return false;

  const _target = !!event && event.target || {};

  const codeTagContainer = _target.closest('.code-tag-container');
  return ( !! codeTagContainer );
}

function isNodeInCodeList(node) {
  return !!node.closest('.codelist');
}

function isNodeOnParagraphIntro(node) {
  return !!node.closest('.speaker') || !!node.closest('.left-hand-transcript'); 
}

function isOutsideOfCodeable(node) {
  return !node.closest('.paragraphSpan');
}

const getLoc = (container, node, offset, isStart) => {
  if (isNodeInCodeList(node)) {
    return _getParagraphStartEnd(node).end;
  } else if (isNodeOnParagraphIntro(node)) {
    return _getParagraphStartEnd(node).start;
  } else if (isOutsideOfCodeable(node)) {
    return isStart ? getFirstVisibleNode(container).start : getLastVisibleNode(container).end;
  } else {
    return getCodeSpanStartEnd(node).start + offset;
  }
};

function getVisibleNode(container, findLast = false) {
  if ( !container ) return {};
  
  const nodes = container.querySelectorAll('.paragraphSpan');

  let returnValue = {}; // Default return value if no visible node is found

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[findLast ? nodes.length - 1 - i : i]; // Choose direction based on `findLast`
    const rect = node.getBoundingClientRect();

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    const isVisible = rect.bottom > 0 && rect.top < viewportHeight; // Any part of node is within viewport


    if (isVisible) {
      
      returnValue = {
        start: parseInt(node.getAttribute('start')),
        end: parseInt(node.getAttribute('end')),
      };

      break;
    }
  }

  return returnValue;
}

function getFirstVisibleNode(container) {
  return getVisibleNode(container, false)
}

function getLastVisibleNode(container) {
  return getVisibleNode(container, true)
}

const CodeableWrapper = ({
  onDeselect,
  onSelectExcerpt,
  onSelectText,
  isPrint,
  hasEditPermission,
  surveyQuestionId,
  codeableId,
  children
}) => {
  const wrapperRef = useRef();

  const onMouseUp = useCallback((event) => {
    document.removeEventListener('mouseup', onMouseUp);

    if (isPrint) return;

    if (!hasEditPermission) {
      onDeselect?.();
      return;
    }

    const {
      startNode,
      endNode,
      startRange,
      endRange
    } = getStartNodeAndEndNode();

    if (!startNode || !endNode) return;

    const startOffset = startRange.startOffset;
    const endOffset = endRange.endOffset;

    const isClick = _isClick(startNode, endNode, startOffset, endOffset);

    if (isClick) {
      if (clickedOnCodeTag(isClick, event)) return;

      const excerptId = getExcerptId(event.target);

      if (excerptId && onSelectExcerpt) {
        onSelectExcerpt(excerptId);
        return;
      }

      const { start, end } = getClickedSpan(event.target);

      if (start !== undefined && end !== undefined && onSelectText) {
        onSelectText(start, end, codeableId, surveyQuestionId);
        return;
      }

      onDeselect?.();
      return;
    } else {
      document.getSelection().removeAllRanges();

      if (onSelectText) {
        const start = getLoc(wrapperRef.current, startNode, startOffset, true);
        const end = getLoc(wrapperRef.current, endNode, endOffset, false);
        onSelectText(start, end, codeableId, surveyQuestionId);
      }
    }
  }, [isPrint, hasEditPermission, onDeselect, onSelectExcerpt, onSelectText]);

  const onMouseDown = useCallback(() => {
    document.addEventListener('mouseup', onMouseUp);
  }, [onMouseUp]);

  return (
    <div onMouseDown={onMouseDown} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default CodeableWrapper;
