// app/javascript/projects/contexts/SurveyContextProvider.js

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import SurveyAPI from 'apis/SurveyAPI';
import {withRouter} from 'react-router';

const SurveyContext = createContext();

export const useSurvey = () => useContext(SurveyContext);

function SurveyContextProvider({ history, match, children, projectId }) {
  const [survey, setSurvey] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState(null);
  const [surveyQuestion, setSurveyQuestion] = useState(null);
  const [changedSurveyId, setChangedSurveyId] = useState(null);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  
  const surveyId = match?.params?.surveyID || surveyResponse?.survey_id || surveyQuestion?.survey_id;
  const surveyResponseId = match?.params?.surveyResponseID;
  const surveyQuestionId = match?.params?.surveyQuestionID;

  const deleteSurvey = useCallback((survey_id) => {
    SurveyAPI.destroySurvey(survey_id).then(() => {
      history.push(`/projects/${projectId}`);
      setChangedSurveyId(survey_id);
    });
  }, [history, projectId]);

  const newSurvey = useCallback((survey_id) => {
    setChangedSurveyId(survey_id);
  }, []);

  useEffect(() => {
    if (match?.params?.surveyID) {
      setSurveyResponse(null);  // Reset survey response when surveyID changes
      setSurveyQuestion(null);  // Reset survey question when surveyID changes
    }
  }, [match?.params?.surveyID]);

  useEffect(() => {
    setSurveyLoaded(false);
    if (!surveyId) return;

    SurveyAPI.getSurvey(surveyId).then((response) => {
      setSurvey(response.data);
      setSurveyLoaded(true);
    }).catch((error) => {
    });
  }, [surveyId]);

  useEffect(() => {
    if (!surveyResponseId) return;
    setSurveyLoaded(false);
    setSurveyQuestion(null);  // Reset survey question when surveyResponseId changes
    SurveyAPI.getSurveyRow(surveyResponseId).then((response) => {
      setSurveyLoaded(true);
      setSurveyResponse(response.data);
    }).catch((error) => {
      setSurveyLoaded(false);
    })
  }, [surveyResponseId]);

  // need to pull the survey question information when you are on the survey repsonse page
  useEffect(() => {
    if (!surveyQuestionId) return;

    setSurveyLoaded(false);

    setSurveyResponse(null);  // Reset survey response when surveyQuestionId changes
    SurveyAPI.getSurveyQuestion(surveyQuestionId).then((response) => {
      setSurveyQuestion(response.data);
      setSurveyLoaded(true);      
    }).catch((error)=>{
      setSurveyLoaded(false);
    })
  }, [surveyQuestionId]);

  return (
    <SurveyContext.Provider value={{
      survey_id: surveyId,
      survey_title: survey?.title,
      survey_response_id: surveyResponseId,
      survey_response_title: surveyResponse?.response_text,
      survey_question_id: surveyQuestionId,
      survey_question_title: surveyQuestion?.question_text,
      deleteSurvey: deleteSurvey,
      changed_survey_id: changedSurveyId,
      newSurvey: newSurvey,
      survey_loaded: surveyLoaded
    }}>
      {children}
    </SurveyContext.Provider>
  );
}

export default withRouter(SurveyContextProvider);