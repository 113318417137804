import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const GenericModal = ({ show, onHide, children, backButtonClick }) => {
  const pageHtml = children || <div></div>;

  return (
    <div>
      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          <div>
            <div style={{ minHeight: "33px" }}>
              {backButtonClick && <Button bsStyle="link" onClick={backButtonClick}>Back</Button>}
            </div>
            <div style={{ margin: "0px 33px" }}>
              {pageHtml}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GenericModal;