import React, { useState, useEffect } from "react";
import { Modal, DropdownButton, MenuItem, Button, Alert } from "react-bootstrap";
import SurveyAPI from "apis/SurveyAPI";

const EditQuestionModal = ({ question_type, question_id, onHide, onSaved, hasEditPermission }) => {
    const [newQuestionType, setNewQuestionType] = useState(question_type);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleSelect = (eventKey) => {
        setNewQuestionType(eventKey);
    };

    useEffect(() => {
        setNewQuestionType(question_type);
    }, [question_type])

    const saveChanges = () => {
        setIsSaving(true);
        setError(null);

        SurveyAPI.updateSurveyQuestion(question_id, newQuestionType).then((result)=>{
            console.log(result);
            setIsSaving(false);
            onSaved(result.data);
            onHide();
        }).catch((error) => {
            setIsSaving(false);
            setError(error.response?.data?.error || "An error occurred while saving changes.");
        });
    }

    const getQuestionType = (question_type) => {
        switch (question_type) {
            case "text":
                return "Long Text";
            case "single_choice":
                return "Single Choice";
            default:
                return "Unknown";
        }
    }

    // TODO: This does not work perfectly, because hasEditPermission defaults to false
    // Ideally this would be null first, but I like that this is hidden from view only people
    useEffect(()=>{
        if ( !question_id ) return;
        if ( hasEditPermission === false ) onHide();
    }, [question_id, onHide, hasEditPermission])

    return (
        <Modal show={!!question_id} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Question</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {error && <Alert bsStyle="warning">{error}</Alert>}
                <p>Dropdown questions can be used as filters, and long text can be coded.</p>

                <div className="mb-3">
                    <label>Change question type</label>
                    &nbsp;
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={getQuestionType(newQuestionType)}
                        onSelect={handleSelect}
                        bsStyle="white"
                    >
                        <MenuItem eventKey="text">{getQuestionType('text')}</MenuItem>
                        <MenuItem eventKey="single_choice">{getQuestionType('single_choice')}</MenuItem>
                    </DropdownButton>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="blue" onClick={saveChanges} disabled={isSaving}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditQuestionModal;
