// surveySelectCallbacks.js
import { useCallback } from 'react';

export const useSurveySelectCallbacks = (onSelectText, surveyResponseID, surveyAnswerId, surveyQuestionId) => {
  const onSurveyAnswerSelected = useCallback(
    (start, end) => {
      onSelectText(start, end, surveyAnswerId, "SurveyAnswer", {
        survey_row_id: surveyResponseID,
        survey_question_id: surveyQuestionId
      });
    },
    [onSelectText, surveyResponseID, surveyAnswerId, surveyQuestionId]
  );

  const onParagraphClick = useCallback(
    (start, end) => {
      console.log('on paragraph click')
      onSurveyAnswerSelected(start, end, surveyAnswerId, "SurveyAnswer", {
        survey_row_id: surveyResponseID,
        survey_question_id: surveyQuestionId
      });
      document.getSelection().removeAllRanges();
    },
    [onSurveyAnswerSelected, surveyResponseID, surveyAnswerId, surveyQuestionId]
  );

  return { onSurveyAnswerSelected, onParagraphClick };
};