import { useState, useEffect, useCallback } from "react";
import LocalAPI from "utils/LocalAPI";

const DEFAULT_VISIBLE_COLUMNS = 20;

function getVisibleColumns(localStorageKey, surveyQuestions) {
    if (surveyQuestions.length > 0) {
        const savedColumns = LocalAPI._getFromStorage(localStorageKey);
        if (savedColumns && savedColumns.length > 0) {
            const validColumns = savedColumns
            .map((id) => surveyQuestions.find((q) => q.id === id))
            .filter(Boolean)
            .sort((a, b) => a.id - b.id); // Sort by id
            return validColumns;
        } else {
            // Default to first N questions if no saved state
            return surveyQuestions.slice(0, DEFAULT_VISIBLE_COLUMNS).sort((a, b) => a.id - b.id); // Sort by id
        }
    }

    return null;
}

const useVisibleColumns = (survey_id, surveyQuestions) => {
  const localStorageKey = `visibleColumns_${survey_id}`;

  // State for visible columns (initialized as full question objects)
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = getVisibleColumns(localStorageKey, surveyQuestions);
    return savedColumns || [];
  });

  // Apply defaults or saved state when surveyQuestions change
  useEffect(() => {
    const savedColumns = getVisibleColumns(localStorageKey, surveyQuestions);
    if (!savedColumns) return;
    setVisibleColumns(savedColumns);    
  }, [surveyQuestions]);

  // Toggle visibility of a column and save to localStorage
  const toggleColumnVisibility = useCallback(
    (question_id) => {
      setVisibleColumns((prev) => {
        const isVisible = prev.some((q) => q.id === question_id);
        const updatedColumns = isVisible
          ? prev.filter((q) => q.id !== question_id) // Remove from visible columns
          : [...prev, surveyQuestions.find((q) => q.id === question_id)]; // Add to visible columns

        // Save updated IDs to localStorage
        const visibleIds = updatedColumns.map((q) => q.id);
        LocalAPI._setToStorage(localStorageKey, visibleIds);

        return updatedColumns.sort((a, b) => a.id - b.id); // Sort by id
      });
    },
    [surveyQuestions, localStorageKey]
  );

  // Reset to default (first N columns)
  const resetColumns = useCallback(() => {
    const defaultColumns = surveyQuestions.slice(0, DEFAULT_VISIBLE_COLUMNS).sort((a, b) => a.id - b.id); // Sort by id
    setVisibleColumns(defaultColumns);
    // No save to localStorage since defaults don't need to persist
  }, [surveyQuestions]);

  return {
    visibleColumns,
    toggleColumnVisibility,
    resetColumns,
  };
};

export default useVisibleColumns;
