import React from 'react';
import SurveyAnswer from './SurveyAnswer';
import CodeableWrapper from 'codeable/CodeableWrapper';
import SurveyQuestionNoAnswer from './SurveyQuestionNoAnswer';

const SurveyQuestionText = ({
  questionText,
  questionType,
  surveyQuestionId,
  surveyAnswer,
  excerpts,
  selectedExcerptId,
  select,
  onParagraphClick,
  onMouseDown,
  disabled,
  onExcerptSelected,
  onDeselect,
  onSurveyAnswerSelected,
  onTitleClick,
  logo,
  color,
  hasEditPermission
}) => {
  if (!surveyAnswer)
    return (
      <SurveyQuestionNoAnswer
        questionText={questionText}
        questionType={questionType}
        onClick={onTitleClick}
      />
    );

  return (
    <CodeableWrapper
      hasEditPermission={hasEditPermission}
      onSelectExcerpt={onExcerptSelected}
      onDeselect={onDeselect}
      onSelectText={onSurveyAnswerSelected}
      codeableType="SurveyAnswer"
      surveyQuestionId={surveyQuestionId}
      codeableId={surveyAnswer?.id}
    >
      <div
        onMouseDown={() => onMouseDown && onMouseDown(surveyAnswer?.id)}
        style={disabled ? {
          userSelect: "none"
        } : {}}
      >
          <SurveyAnswer
            questionText={questionText}
            questionType={questionType}
            surveyQuestionId={surveyQuestionId}
            surveyAnswer={surveyAnswer}
            excerpts={excerpts}
            selectedExcerptId={selectedExcerptId}
            select={select}
            onParagraphClick={onParagraphClick}
            onTitleClick={onTitleClick}
            logo={logo}
            color={color}
            hasEditPermission={hasEditPermission}
          />
      </div>
    </CodeableWrapper>
  );
};

export default SurveyQuestionText;