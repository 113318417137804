module.exports = {
                    /**
                    * FILTERS
                    ***/
                    // REPORT
                    FILTER_REPORT: 'FILTER_REPORT',
                    FILTER_REPORT_RESPONSE: 'FILTER_REPORT_RESPONSE',
                    FILTER_REPORT_ERROR: 'FILTER_REPORT_ERROR',
                    // CODES
                    FILTER_CODE_CHECKED: "FILTER_CODE_CHECKED",
                    FILTER_CODE_UNCHECKED: "FILTER_CODE_UNCHECKED",
                    FILTER_CODE_CLEAR: "FILTER_CODE_CLEAR",
                    // Descriptors
                    FILTER_CHECKED: "FILTER_CHECKED",
                    FILTER_UNCHECKED: "FILTER_UNCHECKED",
                    FILTER_CLEAR: "FILTER_CLEAR",
                    // Transcripts
                    TRANSCRIPT_CHECKED: "TRANSCRIPT_CHECKED",
                    TRANSCRIPT_UNCHECKED: "TRANSCRIPT_UNCHECKED",
                    TRANSCRIPT_CLEAR: "TRANSCRIPT_CLEAR",
                    // Surveys
                    SURVEY_CHECKED: "SURVEY_CHECKED",
                    SURVEY_UNCHECKED: "SURVEY_UNCHECKED",
                    SURVEY_CLEAR: "SURVEY_CLEAR",
                    // FILTER
                    FILTER_EXCERPTS: "FILTER_EXCERPTS",
                    FILTER_EXCERPTS_RESULT: "FILTER_EXCERPTS_RESULT",
                    FILTER_EXCERPTS_ERROR: "FILTER_EXCERPTS_ERROR",
                    FILTER_NEXT: "FILTER_NEXT",
                    // CODE OP
                    CODE_OP_CHANGED: "CODE_OP_CHANGED",
                    // SORT
                    SORT_CHANGED: "SORT_CHANGED",
                  }
