const excerptWithStartAndEndMapper = (state, start, end, codeableId, codeableType) => {
  return Object.values(state.entities.excerpts).reduce((maxExcerpt, excerpt) => {
    if (excerpt.start !== start || excerpt.end !== end || excerpt.transcript_id !== codeableId || excerpt.codeable_type !== codeableType) {
      
      return maxExcerpt;
    }
    
    if (!maxExcerpt || excerpt.codings.length > maxExcerpt.codings.length) {
      return excerpt;
    }
    
    return maxExcerpt;
  }, null);
}

export default excerptWithStartAndEndMapper;
