import {
    compileUrl,
    getParams
} from './urlEncoder';


const getFilters = (location) => {
    const params = getParams(location);
    return params.filters || [];
}

const goNext = (location, pageNext) => {
    const params = getParams(location);
    params.offset = pageNext;
    
    return compileUrl(location.pathname, params);
}

const goPrevious = (location, offset) => {
    const params = getParams(location);
    params.offset= Math.max(0, params.offset - offset);
    return compileUrl(location.pathname, params);
}

const editQuestionUrl = (location, questionId) => {
    return `${location.pathname}/survey_questions/${questionId}/edit${location.search}`;
}

const hideUrl = (location) => {
    // drop everythign after /survey_questions but keep the search
    return location.pathname.split('/survey_questions')[0] + location.search;
}

// update filter should remove the offset and update the filters
const updateFilter = (location, filters, keepOffset) => {
    const params = getParams(location);
    params.filters = filters;

    if (!keepOffset)
        delete params.offset;
    return compileUrl(location.pathname, params);
}

const optionClicked = (location, question_id, option_id) => {
    return updateFilter(location, [
        {
            question_id,
            option_ids: [option_id]
        }
    ])
}

const surveyUrlOptionFilter = (survey_id, question_id, option_id) => {
    return compileUrl(
        `/surveys/${survey_id}`,
        {
            filters: [
                {
                    question_id,
                    option_ids: [option_id]
                }
            ]
        }
    )
}

const deleteFilter = (location, question_id) => {
    const questionFilter = getFilters(location).find((filter) => filter.question_id === question_id);

    const hasOptions = questionFilter && (questionFilter.option_ids || []).length > 0;

    const filters = getFilters(location).filter((filter) => filter.question_id !== question_id);
    return updateFilter(location, filters, !hasOptions);
}

const clearCheckedUrl = (location, question_id) => {
    const filters = getFilters(location);
    const filter = filters.find((filter) => filter.question_id === question_id);
    if (filter) {
        filter.option_ids = [];
    }
    return updateFilter(location, filters);
}

const optionRemovedUrl = (location, question_id, option_id) => {
    const filters = getFilters(location);
    const filter = filters.find((filter) => filter.question_id === question_id);
    if (filter) {
        filter.option_ids = filter.option_ids.filter((id) => id !== option_id);
    }
    return updateFilter(location, filters);
}

const optionAddedUrl = (location, question_id, option_id) => {
    if (!question_id || !option_id) {
        return;
    }

    const filters = getFilters(location);
    const filter = filters.find((filter) => filter.question_id === question_id);
    if (filter) {
        if (!filter.option_ids.includes(option_id)) {
            filter.option_ids.push(option_id);
        }
    } else {
        filters.push({
            question_id,
            option_ids: [option_id]
        });
    }

    return updateFilter(location, filters);
}

const questionAddedUrl = (location, question_id) => {
    const filters = getFilters(location);
    filters.push({
        question_id,
        option_ids: []
    });
    return updateFilter(location, filters, true);
}


export {
    goNext,
    goPrevious,
    editQuestionUrl,
    hideUrl,
    updateFilter,
    optionClicked,
    deleteFilter,
    clearCheckedUrl,
    optionRemovedUrl,
    questionAddedUrl,
    optionAddedUrl,
    getFilters,
    surveyUrlOptionFilter
};
