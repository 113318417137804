import API from './API';
import getUrlParams from './getFilterUrlParams';

export default {
  filterByCodes: function(projectID, code_ids, choice_ids, transcript_ids, survey_ids, limit, offset, op, sort, source) {
    const urlParams = getUrlParams(code_ids, choice_ids, transcript_ids, survey_ids, limit, offset, op, sort, source);
    return API.get(`/api/projects/${projectID}/excerpts?${urlParams}`);
  },

  getFilterReport: function(projectID, code_ids, choice_ids, transcript_ids, survey_ids, op, sort, source) {
    const urlParams = getUrlParams(code_ids, choice_ids, transcript_ids, survey_ids, null, null, op, sort, source);
    return API.get(`/api/projects/${projectID}/filter_report?${urlParams}`);
  },
};