// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CodePillLink from './CodePillLink'
import CodeX from './CodeX'
import CoderOverlay from './CoderOverlay';

class CodeTag extends React.Component {
  constructor () {
    super();
    this.state = {"mouseIn": false};
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onMouseEnter () {
    this.setState({"mouseIn": true});
    if ( this.props.tagEnter )
      this.props.tagEnter(this.props.id);
  }

  onMouseLeave () {
    this.setState({"mouseIn": false});
    if ( this.props.tagLeave)
      this.props.tagLeave(this.props.id);
  }

  onRemove (e) {
    //IE9 & Other Browsers
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    //IE8 and Lower
    else {
      e.cancelBubble = true;
    }

    if ( this.props.id )
      this.props.onCodeRemove(this.props.id);
  }

  render () {
    return (
        <span
          style={{marginRight:12, padding:0, maxWidth:"100%"}}
          className="code-tag-container"
        >
          {' '}
          <CoderOverlay
            coders={this.props.coders}
            // If this is in an excerpt, the codes should always be dark
            // If this is in a project, and this code is selected
            // We make the coders darker
            selected={this.props.selected && !this.props.inExcerpt}
          >
              <span
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                style={{cursor:"pointer", marginRight:2, padding:0, maxWidth:"100%"}}
                className="code-tag"
              >
                <CodePillLink
                  server_id={this.props.server_id}
                  codePage={this.props.codePage}
                  projectID={this.props.projectID}
                  name={this.props.name}
                  selected={this.props.selected}
                  id={this.props.id}
                  onClick={this.props.onClick}
                />
              </span>
        </CoderOverlay>
              {this.props.selected && this.props.hasEditPermission &&
                <CodeX onClick={this.onRemove}/>
              }
        </span>
    );
  }
}

export default CodeTag;
