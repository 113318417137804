import React, {useState} from "react";
import { Popover } from "react-bootstrap";
import SurveyTableCellContainer from "./SurveyTableCellContainer";
import ConditionalOverlayTrigger from "./ConditionalOverlayTrigger";

const SurveyTableTextCell = ({ body, question_id, onQuestionClicked }) => {
    const [isHovered, setIsHovered] = useState(false);

    const tooltip = (
        <Popover
            id={`popover-${question_id}`}
        >
            {body}
        </Popover>
    );

    return (
        <SurveyTableCellContainer>
            <ConditionalOverlayTrigger body={body} minChars={30} tooltip={tooltip}>
                <div
                    className="survey-text-cell"
                    style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <span style={{
                            cursor:"pointer",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            textDecoration: isHovered ? "underline" : "none",
                            color: isHovered ? "blue" : "black",
                        }}
                        onClick={() => onQuestionClicked(question_id)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {body}
                    </span>
                </div>
            </ConditionalOverlayTrigger>
        </SurveyTableCellContainer>
    )
}

export default SurveyTableTextCell;