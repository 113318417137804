// ExcerptMapper.js
import OptimisticMapperHelper from './OptimisticMapperHelper';
import FallbackNameMapper from './FallbackNameMapper';
const _ = require('underscore');

const excerptCodesMapper = (state, excerpt) => {
  if ( !excerpt ) return null;

  const codes = _.chain(excerpt.codings)
  .groupBy((coding)=>OptimisticMapperHelper.getCodeId(state, coding.code_id)).map((codings, code_id)=>{   
    const code = OptimisticMapperHelper.getCode(state, code_id);
    if (!code) return code;

    return {
      id: code.id,
      name: code.name,
      count: code.count,
      ...(code.server_id && {server_id: code.server_id}),
      // dedupe coders by coder id
      coders: _.chain(codings).map((coding)=>{
        const user = FallbackNameMapper(state, coding.user_id) || {};

        if ( !coding.user_id ) return null;
  
        return {
          id: coding.user_id,
          name: user.name,
          time: coding.created_at
        }
      }).filter((coder)=>!!coder)
      .uniq((coder)=>coder.id)
      .sortBy((coder)=>coder.time).value()
    }
  }).filter((code)=>{return !!code}).sort(
    (a,b) => {
      const nameA = (a?.name || '').toString().toLowerCase();
      const nameB = (b?.name || '').toString().toLowerCase();
      return nameA.localeCompare(nameB);
    }
  ).value()

  const { codings, ...excerptWithoutCodings } = excerpt;

  return {
    ...excerptWithoutCodings,
    codes
  };
}

export default excerptCodesMapper
