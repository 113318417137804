import AppDispatcher from 'dispatcher/AppDispatcher'
import AuthConstants from 'constants/AuthConstants'
import CodedTranscriptUtil from 'utils/CodedTranscriptUtil'
import MergeConstants from 'constants/MergeConstants'
import QualConstants from 'constants/QualConstants'
import MemoConstants from 'memos/MemoConstants'
import State from 'stores/State'
import descriptorReducer  from 'reducers/DescriptorReducer'
import editParagraphMapper from 'mappers/EditParagraphMapper'
import excerptMapper from 'mappers/ExcerptMapper'
import transcriptMapper from 'mappers/TranscriptMapper'
import botTranscriptExcerptMapper from 'mappers/BotTranscriptExcerptMapper'
import loadedTranscriptMapper from 'mappers/LoadedTranscriptMapper'
import ShareConstants from 'constants/ShareConstants'
import intercomMapper from 'mappers/IntercomMapper'
import interCoderMapper from 'mappers/intercoder/InterCoderMapper'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'

var _ = require('underscore');

var EventEmitter = require('events').EventEmitter;

var _coders = null;
var _codedTranscriptUtil = null;
var _otherCodedTranscriptUtil = null;

function _setTranscript(transcriptID)
{
  // if transcript does not exist
  if ( !transcriptID && !!_codedTranscriptUtil )
      // and use that transcript
      transcriptID = _codedTranscriptUtil.getTranscriptID();

  // if it didn't have a transcript then return
  if ( !transcriptID ) return;

  // this mapper puts it in the format that it can be consumed, it should be the ground truth
  const transcript = transcriptMapper(State.get(), transcriptID);
  if ( !transcript ) return;

  // only create a new coded transcript util if this is truly a new transcript

  if ( !_codedTranscriptUtil || _codedTranscriptUtil.isNew(transcriptID, transcript.body) )
  {
      _codedTranscriptUtil = new CodedTranscriptUtil(transcriptID, transcript.body);
  }

  const coders = _getCoders() || [];
  if ( !!coders && coders.length > 1 ) {
    if ( !_otherCodedTranscriptUtil || _otherCodedTranscriptUtil.isNew(transcriptID, transcript.body) ) {
      _otherCodedTranscriptUtil = new CodedTranscriptUtil(transcriptID, transcript.body);
    }

    _otherCodedTranscriptUtil.addExcerpts(transcript.excerpts || [], [coders[1]]);
  } else {
    _otherCodedTranscriptUtil = null;
  }


  _codedTranscriptUtil.addExcerpts(transcript.excerpts || [], coders.length > 0 ? [coders[0]] : null);
}

function _setPrimeCode(data)
{
  if (!!_codedTranscriptUtil)
    _codedTranscriptUtil.setPrimeCode(data.paragraphIndex, data.codeID);
  if ( !!_otherCodedTranscriptUtil )
    _otherCodedTranscriptUtil.setPrimeCode(data.paragraphIndex, data.codeID);
}

function _removePrimeCode(data)
{
  if (!!_codedTranscriptUtil)
    _codedTranscriptUtil.setPrimeCode(null, null);
  if ( !!_otherCodedTranscriptUtil )
    _otherCodedTranscriptUtil.setPrimeCode(null, null);
}

function _filterCoders(data)
{
  _coders = data;
}

// this is hackier than I'd like
function replaceMe(coders) {
  if ( !coders ) return coders;

  coders = [...coders]
  if ( coders.includes('me') ) {
    const user = intercomMapper(State.get());
    if ( !!user && !!user.user_id )
    {
      const userId = user.user_id.toString();
      if ( !coders.includes(userId) )
        coders.push(userId);

      coders = coders.filter((coder) => coder !== 'me');
    }
  }

  return coders;
}

function _getCoders()
{
  if ( !_coders ) return null;
  return replaceMe(_coders);
}

var CodedTranscriptStore = _.extend({}, EventEmitter.prototype, {
  testReduce(action) {
    State.set(descriptorReducer(action, State.get()));
  },

  getBotExcerpts(transcriptID) {
    return botTranscriptExcerptMapper(State.get(), transcriptID) || {};
  },

  isTranscriptLoaded(transcriptID) {
    return loadedTranscriptMapper(State.get(), transcriptID);
  },

  calculateInterCoderReliability(transcriptID) {
    return interCoderMapper(State.get(), transcriptID);
  },

  // TODO: put this in a mapper
  getExcerptDetails(excerptID)
  {
    return excerptMapper(State.get(), excerptID);
  },

  getLocationWithSearchText(transcriptID, searchText)
  {
    const transcript = transcriptMapper(State.get(), transcriptID);
    if ( !transcript || !transcript.body ) return null;

    const body = transcript.body;
    const loc = body.indexOf(searchText);
    if ( loc >= 0 )
    {
      return {
        start: loc,
        end: loc + searchText.length
      }
    }
    else {
      null
    }
  },

  getParagraphIndexWithLocation(transcriptID, loc)
  {
    if ( !_codedTranscriptUtil ) return null;
    if ( transcriptID != _codedTranscriptUtil.getTranscriptID()) return null;
    if ( !_codedTranscriptUtil ) return null;
    return _codedTranscriptUtil.getParagraphLocation(loc);
  },

  getSpecificCodedTranscript(codedTranscriptUtil, transcriptID, page, select, selectedExcerptId) {
    const EMPTY_PROJECT = {paragraphs:[]};
    if ( codedTranscriptUtil == null) return EMPTY_PROJECT;
    if ( codedTranscriptUtil.getTranscriptID() != transcriptID ) return EMPTY_PROJECT;
    return codedTranscriptUtil.getCodedTranscript(page, select, selectedExcerptId);
  },

  getCodedTranscript(transcriptID, page, isOther, select, selectedExcerptId)
  {
    selectedExcerptId = OptimisticMapperHelper.getExcerptIdOrOriginal(State.get(), selectedExcerptId);
    if ( isOther ) {
      return this.getSpecificCodedTranscript(_otherCodedTranscriptUtil, transcriptID, page, select, selectedExcerptId);
    } else {
      return this.getSpecificCodedTranscript(_codedTranscriptUtil, transcriptID, page, select, selectedExcerptId);
    }
  },

  getNumberOfPages(transcriptID)
  {
    const EMPTY_PROJECT = 1;
    if ( _codedTranscriptUtil == null) return EMPTY_PROJECT;
    if ( _codedTranscriptUtil.getTranscriptID() != transcriptID ) return EMPTY_PROJECT;
    return _codedTranscriptUtil.getNumberOfPages();
  },

  getNumberOfParagraphs()
  {
    if ( !_codedTranscriptUtil ) return 0;
    return _codedTranscriptUtil.getNumberOfParagraphs();
  },

  getNumberOfSentences(paragraphIndex)
  {
    if ( !_codedTranscriptUtil ) return 0;
    return _codedTranscriptUtil.getNumberOfSentences(paragraphIndex);
  },

  getSelectedText(select)
  {
    if (!select ) return null;
    if (!_codedTranscriptUtil) return null;

    const transcriptID = _codedTranscriptUtil.getTranscriptID();
    const transcript = transcriptMapper(State.get(), transcriptID);
    if ( !transcript || !transcript.body ) return null;

    const text = transcript.body.substring(select.start, select.end);
    return text;
  },

  // accessed from TranscriptCodeContainer


  // this is the get edit state that you should use
  getEditState(transcriptID)
  {
    return editParagraphMapper(State.get(), transcriptID);
  },

  // Emit Change event
  emitChange: function(event_name) {
    this.emit(event_name || 'change');
  },

  // Add change listener
  addChangeListener: function(callback, event_name) {
    this.on(event_name || 'change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback, event_name) {
    this.removeListener(event_name || 'change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    // THIS IS A FULL RE-RENDER
    // TODO: should not be necessary, but for now will force a reset
    case QualConstants.CREATE_EXCERPT_ERROR:
    case QualConstants.ADD_CODE_TO_EXCERPT_ERROR:
    case QualConstants.CREATE_CODE_ERROR:
      const transcriptID = !!_codedTranscriptUtil ? _codedTranscriptUtil.getTranscriptID() : null;
      State.set(descriptorReducer(action, State.get()));
      _codedTranscriptUtil = null;
      _setTranscript(transcriptID);
      CodedTranscriptStore.emitChange('codeChange');
    break;
    // note this is just for debugging
    case AuthConstants.AUTH_SIGN_OUT:
    case AuthConstants.AUTH_SIGN_IN_RESPONSE:
    case AuthConstants.AUTH_SIGN_OUT_RESPONSE:
    case AuthConstants.AUTH_SIGN_IN:
      _codedTranscriptUtil = null;
      return true; //TODO: do not need to broadcast, just wiping the data on log out
    break;
    case QualConstants.DEBUG_SET_STATE:
      State.set(action.data);
      return true;
    break
    case QualConstants.LOAD_TRANSCRIPT_RESULTS:
      State.set(descriptorReducer(action, State.get()));
      _setTranscript(action.data.id);
    break;
    // just things relevant to codeChanges
    case QualConstants.FETCH_CODES_RESULT:
    case QualConstants.NEST_CODE_CABLE:
    case QualConstants.DELETE_CODE:
    case QualConstants.ADD_CODE_TO_EXCERPT_RESULT:
    // TODO: create code cable is probably making set transcript be called too much, could optimize this...
    case QualConstants.CREATE_EXCERPT_CABLE:
    case MergeConstants.MERGE_CODE:
    case MergeConstants.MERGE_CODE_RESULT:
      State.set(descriptorReducer(action, State.get()));
      _setTranscript();
      CodedTranscriptStore.emitChange('codeChange');
    break;
    case QualConstants.RENAME_CODE:
    case QualConstants.UPDATE_CODE_CABLE:
      _setTranscript();
    break;
    case QualConstants.CREATE_EXCERPT:
    case QualConstants.ADD_CODE_TO_EXCERPT:
    case QualConstants.DELETE_CODE_FROM_EXCERPT:  
      CodedTranscriptStore.emitChange('codeChange');
      _setTranscript();
    break;
    case MemoConstants.CREATE_MEMO_RESULT:
    case ShareConstants.FETCH_PROJECT_COLLABORATORS_RESULT:
      State.set(descriptorReducer(action, State.get()));
      _setTranscript();
    break;
    case QualConstants.CREATE_EXCERPT_RESULT:
    case QualConstants.EDIT_PARAGRAPH:
    case QualConstants.CANCEL_EDIT_PARAGRAPH:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_RESULT:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_ERROR:
    case MergeConstants.MERGE_CODE_INITIATED:
    case MergeConstants.MERGE_CODE_CANCELLED:
      State.set(descriptorReducer(action, State.get()));
    break;
    case QualConstants.ADD_PRIME_CODE:
      _setPrimeCode(action.data);
    break;

    case QualConstants.REMOVE_PRIME_CODE:
      _removePrimeCode(action.data);
    break;

    case QualConstants.FILTER_CODERS:
      _filterCoders((action.data || {}).coders);
      _setTranscript();
    break;

    default:
      return true;
  }

  CodedTranscriptStore.emitChange();
  return true;
});

export default CodedTranscriptStore
