import React, { useEffect, useCallback, useContext, useMemo, useRef, useState } from 'react';
import CodeableContext from 'contexts/CodeableContext';
import AnalysisStore from 'stores/AnalysisStore';
import CodeStore from 'stores/CodeStore';
import asyncUtil from 'utils/AsyncUtil';
import useSelectedExcerpt from './useSelectedExcerpt';
import useResolvedUserId from 'codeable/useResolvedUserId';
import {withRouter} from 'react-router';

const CodeableContextProvider = ({children, projectId, location, includeAllUsers}) => {
  const {
    excerptId: selectedExcerptId,
    excerpt: selectedExcerpt,
    select,
    onNewExcerptId,
    onDeselect,
    onSelectText,
    selectedExcerptVisible,
    setSelectedExcerptVisible,
  } = useSelectedExcerpt(null);

  const {codingUserId} = useResolvedUserId(location, includeAllUsers);

  const selectedExcerptIdRef = useRef(selectedExcerptId);
  useEffect(() => {
    selectedExcerptIdRef.current = selectedExcerptId;
  }, [selectedExcerptId]);

  const selectRef = useRef(select);
  useEffect(() => {
    selectRef.current = select;
  }, [select]);

  const onExcerptSelected = useCallback((excerpt_id) => {
    onNewExcerptId(excerpt_id);
  }, [onNewExcerptId]);

  // adding a code to an excerpt
  const addCodeToExcerpt = useCallback((code_id) => {
    // if there is no excerpt selected or no text selected nothing to do
    if ( !selectedExcerptIdRef.current && !selectRef.current ) return null;

    // TODO: I do not think the right side bar is updating immediately
    // this is likely because there is no longer anything being dispatched

    const {
      start,
      end,
      codeableId,
      codeableType,
      metadata
    } = selectRef.current || {};

    const user_ids = codingUserId ? [codingUserId] : null;

    // if there is an excerpt selected add the code to that excerpt
    if ( !!selectedExcerptIdRef.current )
      asyncUtil.addCodeToExcerpt(code_id, selectedExcerptIdRef.current, user_ids);
    // if there is no excerpt selected but there is text selected create a new excerpt
    // based on the selected text and add the code to that excerpt
    else if ( !!selectRef.current && codeableId && start != undefined && end != undefined ) {
      // create the excerpt
      const [excerptClientID, promise] = asyncUtil.createNewExcerpt(
        code_id,
        codeableId,
        codeableType,
        start,
        end,
        user_ids,
        metadata || {}
      );

      // select that particular excerpt
      onNewExcerptId(excerptClientID);
    }
  }, [projectId, codingUserId]);

  // when a new code is created
  const onNewCode = useCallback((codename) => {
    if ( !projectId ) return null;
    // create the code
    const clientID = asyncUtil.createCode(projectId, codename);
    // add the code to the excerpt
    addCodeToExcerpt(clientID);
  }, [projectId, addCodeToExcerpt]);

  const onAddCode = useCallback((code_id) => {
    addCodeToExcerpt(code_id);
  }, [addCodeToExcerpt]);

  const onRemoveCode = useCallback((code_id) => {
    if ( !selectedExcerptIdRef.current ) return null;
    const user_ids = codingUserId ? [codingUserId] : null;
    asyncUtil.removeCodeFromExcerpt(code_id, selectedExcerptIdRef.current, user_ids);
  }, [codingUserId]);

  useEffect(() => {
    function onChange() {
      if ( selectedExcerptIdRef.current ) {
        onNewExcerptId(selectedExcerptIdRef.current);
      } else if ( selectRef.current ) {
        onSelectText(selectRef.current.start, selectRef.current.end, selectRef.current.codeableId, selectRef.current.codeableType, selectRef.current.metadata);
      }
    }

    CodeStore.addChangeListener(onChange)
    AnalysisStore.addChangeListener(onChange)

    return () => {
      CodeStore.removeChangeListener(onChange);
      AnalysisStore.removeChangeListener(onChange);
    };
  }, []);

  let codeIds = [];
  // TODO: this needs to be a mapper, it is complicated
  if ( !!selectedExcerpt && !!selectedExcerpt.codes ) {
    codeIds = selectedExcerpt.codes.map((code) => {
      return {
        ...code,
        coders: code.coders.filter((coder) => 
          !codingUserId || coder.id === codingUserId )
      }
    }).filter((code) => code.coders.length > 0).map((code) => code.id);
  }
    

  const contextValue = useMemo(() => ({
    selectedExcerptId,
    select,
    onExcerptSelected,
    onDeselect,
    onSelectText,
    selectedCodeIds: codeIds,
    onNewCode,
    onAddCode,
    onRemoveCode,
    selectedExcerptVisible,
    setSelectedExcerptVisible,
  }), [selectedExcerptId, select, codeIds, onNewCode, onAddCode, onRemoveCode]);
  

  return (
    <CodeableContext.Provider value={contextValue}>
      {children}
    </CodeableContext.Provider>
  );
};

export default withRouter(CodeableContextProvider);
