// urlEncoder.js

const jsonToBase64 = (json) => {
    return btoa(JSON.stringify(json));
};

const base64ToJson = (base64) => {
    return JSON.parse(atob(base64));
};

const compileUrl = (base, params) => {
    const base64Filter = jsonToBase64(params);
    return `${base}?filter=${base64Filter}`;
};

const getBase64Filter = (location) => {
    return new URLSearchParams(location.search).get('filter');
};

const getParams = (location) => {
try {
    const filter = getBase64Filter(location);
    return filter ? base64ToJson(filter) : {};
} catch (error) {
    console.error(error);
    return {};
}
};

export {
    jsonToBase64,
    base64ToJson,
    compileUrl,
    getBase64Filter,
    getParams,
};
