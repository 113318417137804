import SpeakerColorConstants from 'constants/SpeakerColorConstants'
import ParagraphBuilder from 'utils/ParagraphBuilder'
import TranscriptPagingUtil from 'utils/TranscriptPagingUtil'
import _ from 'underscore'

const COLORS = SpeakerColorConstants;

class CodedTranscriptUtil {
  constructor(transcriptID, transcriptText){
    this.transcriptID = transcriptID;
    this.transcriptText = transcriptText;

    const transcriptLength = this.transcriptText ? this.transcriptText.length : 0;
    this._numberOfPages = TranscriptPagingUtil.getNumberOfPages(transcriptLength);

    this._paragraphs = new ParagraphBuilder().build(transcriptText);
    const speakers = _.uniq(this._paragraphs
      .map((paragraph)=>paragraph.getSpeaker()).
      filter((speaker)=>!!speaker));

    this._speakerColorHash = {};

    for ( var i = 0; i < speakers.length; i++ ) {
      const speaker = speakers[i];
      const color = COLORS[i % COLORS.length];
      this._speakerColorHash[speaker] = color;
    }


    this._startEndToExcerpt = {};
    this._primeParagraphIndex = null;
    this._primeCodeID = null;
  }

  getTranscriptID()
  {
    return this.transcriptID;
  }

  isNew(transcriptID, transcriptText)
  {
    return this.transcriptID != transcriptID || transcriptText != this.transcriptText;
  }

  setPrimeCode(primeParagraphIndex, primeCodeID)
  {
    if ( primeParagraphIndex == null || primeCodeID == null || !this._checkIndexValid(primeParagraphIndex))
    {
      this._primeParagraphIndex = null;
      this._primeCodeID = null;
      return;
    }

    this._primeParagraphIndex = primeParagraphIndex;
    this._primeCodeID = primeCodeID;
  }

  getNumberOfPages() {
    return this._numberOfPages || 1;
  }

  getNumberOfParagraphs()
  {
    return this._paragraphs.length;
  }

  getNumberOfSentences(paragraphIndex)
  {
    if ( !this._checkIndexValid(paragraphIndex)) return 0;
    return this._paragraphs[paragraphIndex].getNumberOfSentences();
  }

  _checkIndexValid(paragraphIndex)
  {
    return paragraphIndex >= 0 && paragraphIndex < this.getNumberOfParagraphs()
  }

  getCodedTranscript(page, select, selectedExcerptId)
  {
    const numberOfPages = this.getNumberOfPages();
    const paragraphCount = this.getNumberOfParagraphs();
    const start = TranscriptPagingUtil.getPageStart(page, numberOfPages, paragraphCount)
    const end = TranscriptPagingUtil.getPageEnd(page, numberOfPages, paragraphCount)
    const paragraphs = this._paragraphs.slice(start, end);

    return {
      page: page || null,
      paragraphs: paragraphs.map((paragraph, index) =>{
        return paragraph.getCodedSentences(
          this._speakerColorHash,
          select,
          index + start === this._primeParagraphIndex ? this._primeCodeID : null,
          selectedExcerptId
        );
      })
    }
  }

  getExcerpt(start, end)
  {
    if ( [start,end] in this._startEndToExcerpt )
    {
      return this._startEndToExcerpt[[start, end]];
    }
    return null;
  }

  getParagraphIndexWithLocation(loc)
  {
    const numberOfParagraphs = this.getNumberOfParagraphs();
    for ( var paragraphIndex = 0; paragraphIndex < numberOfParagraphs; paragraphIndex++ )
    {
      var paragraph = this._paragraphs[paragraphIndex];

      if ( paragraph.getStart() <= loc && loc <= paragraph.getEnd() )
        return paragraphIndex;
    }

    return numberOfParagraphs - 1;
  }

  getParagraphLocation(loc) {
    const paragraphIndex = this.getParagraphIndexWithLocation(loc);
    const page = TranscriptPagingUtil.getPageLocationOfParagraph(paragraphIndex, this.getNumberOfPages(), this.getNumberOfParagraphs());
    const paragraphStart = TranscriptPagingUtil.getPageStart(page, this.getNumberOfPages(), this.getNumberOfParagraphs());
    return {
      paragraphIndex: paragraphIndex - paragraphStart,
      page
    }
  }

  addExcerpts(excerpts, coders)
  {
    const filteredExcerpts =  (!coders || coders.length === 0) ? excerpts : (excerpts || []).map(
      (excerpt)=>{
        return {
        ...excerpt,
        codes: (excerpt.codes || []).map((code)=>{
            return {
              ...code,
              coders: (code.coders || []).filter((coder)=>coders.includes(coder.id.toString()))
            }
        }).filter((code)=>code.coders.length > 0)
      }
    })/*.filter((excerpt)=>excerpt.codes.length > 0)*/

    filteredExcerpts.forEach((excerpt) =>
      this._startEndToExcerpt[[excerpt.start, excerpt.end]] = excerpt
    );

    this._paragraphs.forEach((paragraph) =>
      paragraph.addExcerpts(filteredExcerpts)
    );
  }
}

export default CodedTranscriptUtil;
