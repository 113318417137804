import React from 'react';

const TRANSCRIPT_PADDING = "6px";

const linkStyle = {
  paddingLeft: "30px",
  paddingTop: TRANSCRIPT_PADDING,
  cursor: "pointer",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  paddingTop: "0px",
  paddingBottom: "0px",
  marginTop: "2px",
};

const selectedStyle = {
  ...linkStyle,
  color: "white",
};

function LeftSideLink({
  selected,
  className,
  children,
  onClick
}) {
  return (
    <div style={selected ? selectedStyle : linkStyle} className={className} onClick={onClick}>
      <span style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "0px",
        margin: "0px",
        maxWidth: "100%",
        display: "inline-block",
        topMargin: "0px",
        bottomMargin: "0px",
        topPadding: "0px",
        bottomPadding: "0px",
      }}>
        {children}
      </span>
    </div>
  );
}

export default LeftSideLink;
