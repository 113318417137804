// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import Header from './Header'
import SubscriptionHeaderFrame from './SubscriptionHeaderFrame';

function DropdownHeader({
  dropdownOptions,
  children,
  hasEditPermission,
  isActive,
  name,
  onRenew,
  onShowCollabModal,
  projectId,
  roleIsEdit,
  showCollabModal,
  subscriptionState,
}) 
{
  return <SubscriptionHeaderFrame
    dropdownOptions={dropdownOptions}
    hasEditPermission={hasEditPermission}
    isActive={isActive}
    name={name}
    onRenew={onRenew}
    onShowCollabModal={onShowCollabModal}
    projectId={projectId}
    roleIsEdit={roleIsEdit}
    showCollabModal={showCollabModal}
    subscriptionState={subscriptionState}
  >
    <Header>{children}</Header>
  </SubscriptionHeaderFrame>
}

export default DropdownHeader;
