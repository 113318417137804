// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState } from 'react';
import {MenuItem} from 'react-bootstrap'
import DropdownHeader from './DropdownHeader'
import matchToProjectID from 'utils/MatchToProjectID'
import ConversationPickerModal from 'chat/ConversationPickerModal';

const ChatHeader = (props) => {
    const [showExportModal, setShowExportModal] = useState(false);

    const hideExportModal = () => {
        setShowExportModal(false);
    }

    const showExportModalClicked = () => {
        setShowExportModal(true);
    }

    const projectId = matchToProjectID(props.match);

    const dropdownOptions = [
        <MenuItem eventKey="1" onClick={showExportModalClicked}>
            View All Chats
        </MenuItem>
    ]

    return (
        <div>
            <ConversationPickerModal
              show={showExportModal}
              projectId={projectId}
              onHide={hideExportModal}
            />
            <DropdownHeader
                projectId={projectId}
                hasEditPermission={props.hasEditPermission}
                subscriptionState={props.subscriptionState}
                onShowCollabModal={props.onShowCollabModal}
                showCollabModal={props.showCollabModal}
                dropdownOptions={dropdownOptions}
            >
                AI Chat | Beta
            </DropdownHeader>
        </div>
    )
}

export default ChatHeader;