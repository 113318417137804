// app/javascript/projects/components/DeleteMemoModal.jsx

import React from 'react';
import DeleteModal from 'projects/components/DeleteModal';

function DeleteMemoModal(props) {
  return (
    <DeleteModal
      show={props.show}
      onCancel={props.onCancel}
      onDelete={props.onDelete}
      title={`Are you sure you want to delete this memo?`}
    >
      You will not be able to recover this memo after it has been deleted.
    </DeleteModal>
  )
}

export default DeleteMemoModal;
