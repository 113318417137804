import React, { useState } from 'react';
import FilterButton from './analysis/FilterButton';
import { OP_OR } from 'constants/OpConstants';
import DropdownChecklistWrapper from './DropdownChecklistWrapper';

const DescriptorDropdown = ({
  codes = [],
  name,
  onChecked,
  applyFilter,
  dropdownAbove,
  subHeader,
  onDeleteFilter,
  onClearChecked,
  onClearFilter,
  filterButtonText,
}) => {
  const [shown, setShown] = useState(false);

  const handleClick = () => {
    setShown((prevShown) => !prevShown);
  };

  const checkedCodes = codes.filter((code) => code.checked).map((code) => code.name);

  return (
    <DropdownChecklistWrapper
      codes={codes}
      onChecked={onChecked}
      applyFilter={applyFilter}
      dropdownAbove={dropdownAbove}
      subHeader={subHeader}
      onDeleteFilter={onDeleteFilter}
      onClearChecked={onClearChecked}
      onClearFilter={onClearFilter}
      filterButtonText={filterButtonText}
      shown={shown}
      setShown={setShown}
    >
      <FilterButton
          op={OP_OR}
          filters={checkedCodes}
          name={name}
          onClick={handleClick}
      />
    </DropdownChecklistWrapper>
  );
};

export default DescriptorDropdown;
