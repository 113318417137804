import React, { useState, useEffect, useCallback, useContext } from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import CodeStore from 'stores/CodeStore';
import UserStore from 'stores/UserStore';
import EditTranscriptContainer from './EditTranscriptContainer';
import TranscriptPagingUtil from 'utils/TranscriptPagingUtil';
import TranscriptScrollbar from './TranscriptScrollbar';
import UrlUtil from 'utils/UrlUtil';
import CodeableContext from 'contexts/CodeableContext';
import {
  useSelectSnippetEffect,
  useTextSelectionEffect,
  useSearchRedirectEffect,
  deselectOnNewTranscript
} from './useSelectionEffects';

import CodingActions from 'actions/CodingActions';
import QualCodeActions from 'actions/QualCodeActions';

import useParagraphIndex from './useParagraphIndex';

const queryString = require('query-string');
var _ = require('underscore');

const TranscriptBodyContainer = (props) => {
  const [codedTranscript, setCodedTranscript] = useState(CodedTranscriptStore.getCodedTranscript(null));

  /*
  -- note that the url is updating faster than the props being passed down -- 
  const transcriptID = props?.match.params.transcriptID;
  */

  const parsed = queryString.parse(props.location.search);
  const { search, pos, end, snippet } = parsed;
  const page = UrlUtil.getPageNumber(props.location);

  const shouldGotoOtherPage = useCallback((newPage) => {
    if (page !== newPage) {
      gotoTranscript(TranscriptPagingUtil.calcQueryParams(props.location.search, newPage));
      return true;
    }
  }, [props.location.search, page]);

  const { paragraphIndex, scrollToParagraphIndex } = useParagraphIndex(page, search, shouldGotoOtherPage);

  const {
    onSelectText,
    onExcerptSelected,
    select,
    selectedExcerptId,
    onDeselect
  } = useContext(CodeableContext);


  const searchRedirect = useCallback((transcriptID, searchText) => {
    if ( !searchText ) return;

    const loc = CodedTranscriptStore.getLocationWithSearchText(transcriptID, searchText) || {};

    // goto /transcripts/${props.transcriptID}?pos=${loc.start}&end=${loc.end}`
    if (loc.start !== undefined && loc.end !== undefined) {
      props.history.replace(`/transcripts/${transcriptID}?pos=${loc.start}&end=${loc.end}`);
    }
  }, []);

  // if the snippet changes, select it
  useSelectSnippetEffect(onExcerptSelected, snippet);
  // if the select start changes and it matches the snippet, scroll to the paragraph
  useTextSelectionEffect(onSelectText, pos, end, props.transcriptID);
  useSearchRedirectEffect(searchRedirect, props.transcriptID, search);
  deselectOnNewTranscript(onDeselect, props.transcriptID, snippet, search, pos);

  // when the position or transcript updates, scroll to the paragraph index

  useEffect(() => {
    // if the snippet is set and it matches the selected excerpt, scroll to the paragraph
    if ( snippet ) {
      if ( !select?.start ) return;
      if ( !selectedExcerptId ) return;
      if ( snippet != selectedExcerptId ) return;

      scrollToParagraphIndex(props.transcriptID, select.start);  
    } 
    // we should hold on if there is a search present, because that needs to be resolved
    else if ( !search ) {
      scrollToParagraphIndex(props.transcriptID, pos);
    }
  }, [select?.start, snippet, selectedExcerptId, props.transcriptID, pos, page, codedTranscript]);


  const loadState = useCallback((transcriptID, select, selectedExcerptId, search, page) => {
    searchRedirect(transcriptID, search);

    setCodedTranscript(CodedTranscriptStore.getCodedTranscript(
      transcriptID,
      page,
      false,
      select,
      selectedExcerptId
    ));
  }, []);

  useEffect(() => {
      const handleStoreChange = () => {
        loadState(props.transcriptID, select, selectedExcerptId, search, page);
      };

      handleStoreChange();

      CodedTranscriptStore.addChangeListener(handleStoreChange);
      CodeStore.addChangeListener(handleStoreChange);
      UserStore.addChangeListener(handleStoreChange);

      return () => {
          CodedTranscriptStore.removeChangeListener(handleStoreChange);
          CodeStore.removeChangeListener(handleStoreChange);
          UserStore.removeChangeListener(handleStoreChange);
      };
  }, [search, page, props.transcriptID, select, selectedExcerptId, loadState]);


  const getCoders = (pathname) => {
    return pathname.includes('user/me') ? ['me'] : [];
  };

  const coders = getCoders(props.location.pathname);

  useEffect(() => {
    // when you switch between trasncripts, we need to load the codes
    // so that we get the correct code counts
    setTimeout(() => {
      QualCodeActions.filterCoders(coders);
      CodingActions.loadCodes(props.projectID);
    }, 0);
  }, [coders.length]);

  const gotoTranscript = (queryParams) => {
    props.history.push(`${props.location.pathname}${queryParams}`);
  };

  return (
    <div style={{ height: '100%' }}>
      <EditTranscriptContainer transcriptID={props.transcriptID} />
      <TranscriptScrollbar
        transcriptID={props.transcriptID}
        codedTranscript={codedTranscript}
        shouldGotoOtherPage={shouldGotoOtherPage}
        page={page}
        hasEditPermission={props.hasEditPermission}
        pageCount={props.pageCount}
        scrollToParagraphIndex={paragraphIndex}
        waitToScroll={search || pos || snippet}
        onDeselect={onDeselect}
      />
    </div>
  );
};

export default TranscriptBodyContainer;
