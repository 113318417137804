// Desc: Modal for deleting a transcript

import React from 'react';
import DeleteModal from '../DeleteModal';

function DeleteTranscriptModal({
  cancelDelete,
  confirmDelete,
  showModal
}) {
  return <DeleteModal
    title="Are you sure you want to delete this transcript?"
    onCancel={cancelDelete}
    onDelete={confirmDelete}
    show={showModal}
  >
    All text and coded snippets from this transcript will be deleted.
  </DeleteModal>
}

export default DeleteTranscriptModal;