import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

const AddFilterDropdownButton = ({
    questions,
    questionAdded,
    disabledQuestionIds
}) => {
    questions = questions || [];
    disabledQuestionIds = disabledQuestionIds || [];
    
    // filter questions, only include ones that have question_type of single_choice
    questions = questions.filter((question) => {
        return question.question_type === 'single_choice';
    })

    const addQuestion = (isDisabled, question_id) => {
        if ( !isDisabled )
            questionAdded(question_id);
    }

    const menuItems = questions.map((question) => {
        const isDisabled = disabledQuestionIds.includes(question.id);

        return (
            <MenuItem
                key={question.id}
                onClick={() => addQuestion(isDisabled, question.id)}
                eventKey={question.id}
                disabled={disabledQuestionIds.includes(question.id)}
            >
                    {question.question_text}
            </MenuItem>
        )
    })

    return (
        <DropdownButton bsStyle="link"
        title="Add Filter"
        id="add-filter-dropdown"
        >
            {menuItems}
        </DropdownButton>
    )
}

export default AddFilterDropdownButton;