import React from "react";
import SpeakerComponent from "projects/components/SpeakerComponent";
import EditButton from "projects/components/EditButton";
import ColorConstants from "constants/ColorConstants";
import {PARAGRAPH_PADDING} from 'constants/DimensionsConstants'

const leftStyle = {
    left:0,
    top:0,
    padding:"0 0 0 15px",
    margin:0,
    width:PARAGRAPH_PADDING + "px",
    position:"absolute"
}

const toolStyle = {
  paddingTop:'5px',
  paddingLeft: '2px',
}

const ParagraphLeftComponent = ({
    speaker,
    onParagraphHover,
    onParagraphClick,
    color,
    onEditClick,
    canEdit,
    isPrint,
    showTools,
    logo,
    hideTitle
 }) => {
    return (
        <div
            style={leftStyle}
            className="left-hand-transcript"
        >
          {!isPrint &&
            <SpeakerComponent
              initials={speaker && !hideTitle ? speaker[0] : null}
              onHover={onParagraphHover}
              onClick={onParagraphClick}
              color={speaker && !hideTitle ? color : ColorConstants.ULTRA_LIGHT_GREY}
              logo={hideTitle ? null : logo}
            />
          }
        <div
          style={toolStyle}>
          <EditButton
            onClick={onEditClick}
            show={canEdit && showTools}
          />
        </div>
      </div>    
    )
}

export default ParagraphLeftComponent;