import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import ColorConstants from 'constants/ColorConstants';

function GlypiconParagraph({
  borderRadius,
  fontSize,
  color,
  marginTop,
  glyph
}) {
  //const CIRCLE_SIZE = 30;
  const circleSize = `30px`;

  return (
    <div
      style={{
        width: circleSize,
        height: circleSize,
        borderRadius: borderRadius || "20%",
        fontSize: fontSize || "15px",
        color: "#fff",
        lineHeight: circleSize,
        textAlign: "center",
        background: color || ColorConstants.ACTIVE_BUTTON_BLUE,
        marginTop: marginTop,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Glyphicon glyph={glyph} />
    </div>
  );
}

export default GlypiconParagraph;
