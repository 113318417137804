import { normalize, schema } from 'normalizr';
import * as Sentry from "@sentry/browser";
const _ = require('underscore');
import {
        // FILTER
        FILTER_CODE_EXCERPTS_RESULT,
        FILTER_CODE_EXCERPTS,
        CODE_REPORT_RESULT
} from 'constants/CodePageConstants'

function checkServerIdBeingUsed(codeID, actionType) {
  if ( isNaN(codeID) ) {
    Sentry.captureMessage(new Error(`${codeID} is not a server id in ${actionType}`));
  }
}

function codeBookReducer(action, state = {})
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case FILTER_CODE_EXCERPTS:
    {
      const codeID = action.data.codeID;
      const filterUUID = action.data.filterUUID;

      if ( !codeID ) return state;
      checkServerIdBeingUsed(codeID, action.actionType);

      const code = state.codes[codeID];

      return {
        ...state,
        codes: {
          ...state.codes,
          [codeID]: {
            excerpts: [],
            counts: {},
            ...code,
            loading: true,
            uuid: filterUUID,
          }
        }
      }
    }
    break;
    case FILTER_CODE_EXCERPTS_RESULT:
    {
      const codeID = action.data.id;
      if ( !codeID ) return state;
      checkServerIdBeingUsed(codeID, action.actionType);
      const uuid = action.data.filterUUID;
      const next = action.data.next;
      const count = action.data.count;


      const excerpts_schema = new schema.Entity('excerpts')
      const code_schema = new schema.Entity('code', {
        excerpts: [excerpts_schema]
      })

      var normalizedData = normalize(action.data, code_schema);

      const normalizedCode = normalizedData.entities.code[codeID] || {};
      const newExcerpts = normalizedCode.excerpts || [];

      const codeHash = state.codes || {};
      const code = codeHash[codeID] || {};

      const existingExcerpts = !action.data.isFirstCall && !!code.excerpts ? code.excerpts : [];

      return {
        ...state,
        codes: {
          [codeID]: {
            ...code,
            uuid,
            next: next || null,
            count,
            excerpts: _.uniq([...existingExcerpts, ...newExcerpts]),
            loading: false
          }
        }
      }
    }
    break;
    case CODE_REPORT_RESULT:
    {
      const report = action.data.report || {}
      const codeID = action.data.report.code_id;
      if ( !codeID ) return state;
      checkServerIdBeingUsed(codeID, action.actionType);
      const codeHash = state.codes || {};
      const code = codeHash[codeID] || {};
      return {
        ...state,
        codes: {
          [codeID]: {
            ...code,
            counts: action.data.report
          }
        }
      }
    }
    break;
  }

  return state;
}

export default codeBookReducer
