import React from 'react';
import TranscriptLink from './TranscriptLink'; // Adjust the import path as necessary

const TranscriptLinks = ({ transcripts, isOnUserPage, page, transcriptID, location, onLinkClick }) => {
  if (!transcripts || isOnUserPage) {
    return null;
  }

  return transcripts.map((transcript) => (
    <TranscriptLink
      key={transcript.id}
      page={page}
      selected={transcriptID === transcript.id}
      transcriptID={transcript.id}
      location={location}
      onLinkClick={onLinkClick}
    >
      {transcript.name}
    </TranscriptLink>
  ));
};

export default TranscriptLinks;