import React, { useContext, useEffect, useRef, useCallback } from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore';

import ParagraphContainer from './ParagraphContainer';
import { PARAGRAPH_PADDING } from 'constants/DimensionsConstants';
import CodeableWrapper from 'codeable/CodeableWrapper';
import CodeableContext from 'contexts/CodeableContext';

var _ = require('underscore');

const copy = (e, selectRef) => {
    const selectedText = CodedTranscriptStore.getSelectedText(selectRef.current);

  if (selectedText) {
    e.clipboardData.setData('text/plain', selectedText);
    e.preventDefault();
  }
};

const TranscriptBody = ({
  transcriptID,
  setParagraphRef,
  codedTranscript,
  isPrint,
  hasEditPermission,
  bodyRef,
  title
}) => {
  const {select, onExcerptSelected, onDeselect, onSelectText} = useContext(CodeableContext) || {};
  const selectRef = useRef(select);

  useEffect(() => {
    selectRef.current = select;
  }, [select]);

  useEffect(() => {
    const copyHandler = (e) => copy(e, selectRef);
    document.addEventListener('copy', copyHandler, false);
    return () => {
      document.removeEventListener('copy', copyHandler, false);
    };
  }, []);

  const onSelectTranscriptText = useCallback((start, end) => {
    onSelectText(start, end, transcriptID, 'Transcript');
  }, [onSelectText, transcriptID]);

  const onParagraphClick = useCallback((start, end) => {
    onSelectTranscriptText(start, end);
    document.getSelection().removeAllRanges();
  }, [onSelectTranscriptText]);

  const paragraphItems = codedTranscript.paragraphs.map((paragraph, index) => {
    if (!paragraph || paragraph.start === paragraph.end) return null;

    return (
      <div key={paragraph.index} ref={(pc) => { if ( setParagraphRef ) {setParagraphRef(index, pc)} }}>
        <ParagraphContainer
          codedParagraph={paragraph}
          absoluteIndex={paragraph.index}
          paragraphIndex={index}
          transcriptID={transcriptID}
          canEdit={!isPrint && !!hasEditPermission}
          isPrint={!!isPrint}
          hasEditPermission={!!hasEditPermission}
          onParagraphClick={onParagraphClick}
        />
      </div>
    );
  });

  const paragraphPadding = `${PARAGRAPH_PADDING}px`;

  return (
    <CodeableWrapper
      isPrint={isPrint}
      hasEditPermission={hasEditPermission}
      onSelectExcerpt={onExcerptSelected}
      onDeselect={onDeselect}
      onSelectText={onSelectTranscriptText}
      codeableType="Transcript"
      codeableId={transcriptID}
    >
      <div ref={bodyRef} style={{ margin: 0, padding: '20px 10px' }}>
        {title && (
          <div style={{ paddingLeft: paragraphPadding }}>
            <h3>{title}</h3>
          </div>
        )}
        {paragraphItems}
        {isPrint && paragraphItems.length > 0 && (
          <div style={{ paddingLeft: paragraphPadding }}>
            <hr style={{ borderWidth: '0px' }} />
            Created with the <a href="http://www.delvetool.com">Delve Qualitative Analysis Tool </a>
          </div>
        )}
      </div>
    </CodeableWrapper>
  );
};

export default TranscriptBody;
