// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Pagination} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UrlUtil from 'utils/UrlUtil';

function onPage(props, page) {
  if ( page ) {
    // this needs to be used to use the new context
    // (or use the on hidden logic)
    const url = UrlUtil.setPage(page, props.location);
    props.history.push(url);
    props.onPage(page)
  }
}

function TranscriptPager(props) {
  let items = [];
  const currentPageNumber = UrlUtil.getPageNumber(props.location);
  const pageCount = props.pageCount;
  const previousDisabled = currentPageNumber <= 1;

  items.push(<Pagination.Prev
    key={0}
    onClick={()=>onPage(props, currentPageNumber - 1)}
    disabled={previousDisabled}
    />
  );

  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPageNumber}
        onClick={()=>onPage(props, number)}>
        {number}
      </Pagination.Item>,
    );
  }

  const nextDisabled = currentPageNumber == pageCount;

  items.push(<Pagination.Next
    key={pageCount + 1}
    onClick={()=>onPage(props, nextDisabled ? null : currentPageNumber + 1)}
    disabled={nextDisabled}
  />)

  return (<Pagination style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                marginTop: "0px",
                paddingTop: "0px",
              }}>
                {items}
          </Pagination>)
}

export default withRouter(TranscriptPager);
