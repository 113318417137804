import QualCodeActions from './QualCodeActions'
import FilterActions from './FilterActions'
import ProjectsStore from 'stores/ProjectsStore'
import AnalysisStore from 'stores/AnalysisStore'
import CodeStore from 'stores/CodeStore'
import Exporter from 'utils/Exporter'
import ReportAPI from 'apis/ReportAPI'
import trackingUtil from 'utils/TrackingUtil'
import textFileExporter from 'utils/TextFileExporter'
import csvFileExporter from 'utils/CSVFileExporter'
import compileReports from 'mappers/exports/CompileReports'
const _ = require('underscore');



var ExportActions = {
  /*****************************\
  *
  * Exporting to Word
  *
  \*****************************/
  exportToWord: function(projectId)
  {
    FilterActions._pageThroughExcerpts(projectId).then((result)=>{
      const exportObject = CodeStore.getCodesForExport(projectId);
      const exporter = new Exporter(exportObject.projectName);
      exporter.export(exportObject.projectName, exportObject.fileName, exportObject.codes);
      trackingUtil.logEvent('export-codebook');
    })
  },

  exportCodesToCSV: function(projectId)
  {
    const exportObject = CodeStore.getCodesForCSVExport(projectId);
    csvFileExporter(exportObject.projectName, exportObject.codeText);
    trackingUtil.logEvent('export-codebook-csv');
  },

  exportTranscript: function(transcriptId) {
    return QualCodeActions.loadTranscript(transcriptId).then((result)=>{
      const transcript = ProjectsStore.getTranscript(transcriptId) || {};
      const name = (transcript.name || 'Untitled');
      textFileExporter(name, transcript.body);
      trackingUtil.logEvent('export-transcript');
    });
  },

  exportSnippets: function(projectId) {
    const project = ProjectsStore.getProject(projectId);
    if ( !project ) return;
    return FilterActions.pageThroughExcerptsWithFilters(projectId).then((result)=>{
      const exportResults = AnalysisStore.getExportExcerptMapper(projectId);
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.exportExcerpts);
      trackingUtil.logEvent('export-snippets')
    });
  },
  
  exportCodesVCodesMatrix: async function(projectId) {
    const code_ids = CodeStore.getCodeIds(projectId);
    let reports = [];
    
    // Helper function to introduce a delay
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const chunkSize = 200;
  
    for (let i = 0; i < code_ids.length; i += chunkSize) {
      if ( i > 0 )
        await delay(2000);

      const code_ids_slice = code_ids.length < chunkSize ? null : code_ids.slice(i, i + chunkSize);
      
      try {
        // Fetch report for the current slice
        const result = await ReportAPI.getCodesVCodesReport(projectId, code_ids_slice);
        reports.push({
          code_ids: code_ids_slice,
          data: result.data
        });
        
      } catch (error) {
        console.error(`Error fetching report for code_ids: ${code_ids_slice}`, error);
      }
    }
  
    // Once all reports are collected, generate the matrix
    const report = compileReports(reports);
    const exportResults = AnalysisStore.getCodesVCodesMatrix(projectId, report);
  
    if (!exportResults) return;
    
    // Export the results as a CSV file
    csvFileExporter(exportResults.projectName, exportResults.matrix);
    trackingUtil.logEvent('export-codes-v-codes');
  },
  

  exportCodesVTranscriptsMatrix: function(projectId) {
    ReportAPI.getTranscriptCodesReport(projectId).then((results)=>{
      const exportResults = AnalysisStore.getCodesVTranscriptsMatrix(
        projectId,
        results.data
      );
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.matrix);
      trackingUtil.logEvent('export-codes-v-transcripts')
    });
  },

  exportCodesVDescriptorMatrix: function(projectId) {
    ReportAPI.getDescriptorVCodesReport(projectId).then((results)=>{
      const exportResults = AnalysisStore.getCodesVDescriptorMatrix(
        projectId,
        results.data
      );
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.matrix);
      trackingUtil.logEvent('export-codes-v-descriptor')
    });
  },

  printTranscript: function(handlePrint) {
    handlePrint();
    trackingUtil.logEvent('export-transcript-pdf')
  }
}

export default ExportActions
