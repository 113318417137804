// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import FieldGroup from './FieldGroup'
import DelveAccountBody from './DelveAccountBody'

class ForgotDisplay extends React.Component {
  constructor () {
    super();
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email: "",
      sent: false,
      ...AuthStore.getForgotPasswordState(),
    }
    this._onChange = this._onChange.bind(this);
  }

  _onChange(e){
    this.setState({
      ...AuthStore.getForgotPasswordState(),
    })
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }

  onSubmit(e)
  {
    QualCodeActions.forgotPassword(this.state.email);
    this.setState({
      sent: true,
    })
    e.preventDefault();
  }

  onEmailChange (e) {
    this.setState({
      email: e.target.value
    });
  }

  render () {
    return (
      <DelveAccountBody error={this.state.error} subHeader="Reset Your Password" remember>
      <div style={{width:"300px", margin: "0 auto"}}>
        {(!this.state.sent || this.state.error.error) &&
          <form onSubmit={this.onSubmit}>
            <FieldGroup
              id="formControlsEmail"
              type="email"
              label="Email address"
              value={this.state.email}
              onChange={this.onEmailChange}
            />

            <div style={{width: "100%", marginTop:"30px"}}>
              <div style={{width: "300px", margin: "0 auto"}}>
                <Button bsStyle="blue" type="submit" style={{width:"100%"}}>Submit</Button>
              </div>
            </div>
          </form>
        }
        {this.state.sent && !this.state.error.error &&
          <h4 style={{textAlign:"center"}}>An email with a reset link has been sent to you.</h4>
        }
      </div>
    </DelveAccountBody>

    );
  }
}

export default ForgotDisplay;
