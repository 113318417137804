// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'

const LEFT_PADDING = 45;
const MARGIN = 30;

class OutOfDateTranscriptModal extends React.Component {
  constructor () {
    super();
    this.state = {};
    this.refreshPage = this.refreshPage.bind(this);
  }

  refreshPage(e)
  {
     window.location.reload();
  }

  render () {
    return (
      <div>
        <Modal show={this.props.show} animation={false}>
            <Modal.Body style={{padding: 0, margin: 0}}>
              <div style={{padding:`30px ${LEFT_PADDING}px`, textAlign:"center"}}>
                <div>
                  <h4>
                    You're coding an out of date transcript.
                  </h4>
                </div>
              <div style={{marginTop: MARGIN, width: "100%"}}>
                <p>
                  To make sure you are coding the most up to date transcript.
                  Please refresh and try again.
                </p>
              </div>

              <div style={{marginTop: MARGIN}}>
                <Button bsStyle="blue" onClick={this.refreshPage}>
                  Refresh Page
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default OutOfDateTranscriptModal;
