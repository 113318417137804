import React from 'react';
import QualHeader from './header/QualHeader'
import QualLeftSideBar from './QualLeftSideBar'
import QualRightSideBar from './QualRightSideBar'
import QualContent from './QualContent'
import ColorConstants from 'constants/ColorConstants'
import TermsOfServiceModal from './TermsOfServiceModal'
import SubscriptionModal from './SubscriptionModal'
import PageConstants from 'constants/PageConstants'
import HeaderSubscribeModal from './HeaderSubscribeModal'
import { withRouter } from 'react-router';
import CodeableContextProvider from 'codeable/CodeableContextProvider';

import { Redirect } from 'react-router-dom'
import {
  UNCANCEL_SUBSCRIPTION,
  BLOCKER,
  PROJECT_BLOCKER
} from 'constants/AccountPageConstants'

import {
  HEADER_HEIGHT,
  LEFT_WIDTH,
  RIGHT_WIDTH,
  CONTENT_MIN_WIDTH
} from 'constants/DimensionsConstants'

const allStyle = {
  margin:0,
  border:0,
  position:"relative",
  // height:"500px", // this is set set by react
}

const leftStyle = {
    left:0,
    top:0,
    padding:0,
    margin:0,
    width:LEFT_WIDTH + "px",
    height:"100%",
    background:ColorConstants.DARK_GREY,
    position:"absolute",
    margin:"0px",
    boxSizing: "border-box",
}

const mainStyle = {
  marginLeft:LEFT_WIDTH + "px",
  marginRight:"0px",
  padding:"0px",
  height:"100%",
  //background:"blue",
  minWidth:LEFT_WIDTH + RIGHT_WIDTH + CONTENT_MIN_WIDTH + "px",
  boxSizing: "border-box",
}

const withoutRightSideBar = {
  ...mainStyle,
  minWidth: LEFT_WIDTH + CONTENT_MIN_WIDTH + "px",
}

const headerStyle = {
  //background:"orange",
  height: 50 + "px",
  width:"100%",
  borderBottom: "1px solid lightGray",
    boxSizing: "border-box",
}

const bodyStyle = {
  width:"100%",
  // height: "450px", set dynamically
  position:"relative",
  boxSizing: "border-box",
}

const contentStyle = {
    //background:"yellow",
    marginRight:RIGHT_WIDTH + "px",
    height:"100%",
    minWidth: CONTENT_MIN_WIDTH + "px",
    boxSizing: "border-box",
}

const rightStyle = {
  //background:"green",
  width:RIGHT_WIDTH + "px",
  position:"absolute",
  right:0,
  top:0,
  height:"100%",
  borderLeft: "1px solid lightGray",
  boxSizing: "border-box",
  paddingTop:"0px",
  marginTop:"0px",
}

const wrapperStyle = {
  padding:"10px 10px 10px 30px"
}

class SNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, showCollabModal: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onRenew = this.onRenew.bind(this);
    this.onHide = this.onHide.bind(this)
    this.onCanceledNewProject = this.onCanceledNewProject.bind(this);
    this.onShowCollabModal = this.onShowCollabModal.bind(this);
  }

  onRenew () {
    this.setState({
      showBillingModal: UNCANCEL_SUBSCRIPTION
    })
  }

  onCanceledNewProject () {
    this.setState({
      showBillingModal: PROJECT_BLOCKER
    })
  }

  isActive() {
    return !this.props.project || this.props.project.is_active === undefined || this.props.project.is_active;
  }

  isPastDue() {
    return this.props.project && this.props.subscriptionState && this.props.subscriptionState.isPastDue;
  }

  onHide () {
    this.setState({
      showBillingModal: null
    })

    if ( this.isActive() === false || this.isPastDue() ) {
      this.props.history.push('/users');
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  isProjectNotFoundError() {
    return   !!this.props.projectNotFoundError
      && !!this.props.projectNotFoundError.error
  }

  isTranscriptNotFoundError() {
    return   !!this.props.transcriptNotFoundError
      && !!this.props.transcriptNotFoundError.error
  }

  shouldRedirect() {
    if ( !this.props.project ) return false;
    if ( !this.props.project.role ) return false;
    if ( !this.props.page ) return false;

    /* for redirecting away from featureFlagAICoding
    if ( this.props.page === PageConstants.CHAT_PAGE && 
        !!this.props.user &&
        // feature flag ai coding is null while loading
        this.props.user.featureFlagAICoding === false
      ) return true
    */
      
    if (
      this.props.project.role === 'view_only' ||
      !!this.props.subscriptionState.isCancelled
    ) {
      switch (this.props.page){
        case PageConstants.TRANSCRIPT_PAGE:
        case PageConstants.DEMOGRAPHICS_PAGE:
        case PageConstants.PROJECT_PAGE:
        case PageConstants.USER_PAGE:
        case PageConstants.ROOT_PAGE:
        case PageConstants.CODE_BOOK_CODE_PAGE:
        case PageConstants.CODE_BOOK_PAGE:
        case PageConstants.SUBSCRIBE_PAGE:
        case PageConstants.CODED_BY_ME_PAGE:
        case PageConstants.COMPARISON_PAGE:
          return false;
        default:
          return true;
      }
    }
  }

  onShowCollabModal (show) {
    this.setState({showCollabModal: show})
  }

 render () {
   var allStyleUpdated = {
     ...allStyle,
     height:this.state.height
   }

   const bodyHeight = this.state.height - HEADER_HEIGHT;

   var bodyStyleUpdated = {
     ...bodyStyle,
     height:bodyHeight
   }

   const transcriptNotFoundError = this.isTranscriptNotFoundError();
   const projectNotFoundError = this.isProjectNotFoundError();

   const isArchived = (this.props.project || {}).status === 'archived';
   const notFoundError = transcriptNotFoundError || projectNotFoundError || isArchived;


   const rightSideBar = this.props.rightSideBar && !notFoundError;
   const role = (this.props.project || {}).role;
   const roleIsEdit = role === 'edit';
   const hasEditPermission = roleIsEdit && this.props.isSubscriptionActive;
   const isActive = this.isActive()
   const isPastDue = this.isPastDue()
   const isSubscriptionOwner = (this.props.subscriptionState || {}).isOwner
   const isProjectOwner = (this.props.project || {}).isOwner;

   return (

     <div style={allStyleUpdated} height={this.state.height}>
      {this.shouldRedirect() &&
        <Redirect to={`/projects/${this.props.projectID}/codes`}/>
      }
      <SubscriptionModal page={this.props.page}/>
      <HeaderSubscribeModal
        show={!!this.state.showBillingModal || isActive === false || !!isPastDue}
        subscriptionState={this.props.subscriptionState}
        page={(isActive) ?
          this.state.showBillingModal : BLOCKER
        }
        hideBackButton={true}
        onHide={this.onHide}
        isSubscriptionOwner={isSubscriptionOwner}
      />
      <TermsOfServiceModal/>

         <div style={{...leftStyle, height:this.state.height}}>
             <QualLeftSideBar projectID={this.props.projectID}
                              transcriptID={this.props.transcriptID}
                              page={this.props.page}
                              hasEditPermission={hasEditPermission}
                              subscriptionState={this.props.subscriptionState}
                              onCanceledNewProject={this.onCanceledNewProject}
                            />
         </div>
         <div style={rightSideBar ? mainStyle : withoutRightSideBar}>
           <div style={headerStyle}>
             <div style={wrapperStyle}>
               <QualHeader
                  notFoundError={notFoundError}
                  hasEditPermission={hasEditPermission}
                  subscriptionState={this.props.subscriptionState}
                  isActive={isActive}
                  roleIsEdit={roleIsEdit}
                  onRenew={this.onRenew}
                  showCollabModal={this.state.showCollabModal}
                  onShowCollabModal={this.onShowCollabModal}
               />
             </div>
           </div>
           <CodeableContextProvider
              projectId={this.props.projectID}
              codeableId={this.props.transcriptID}
           >
            <div style={bodyStyleUpdated}>
              <div style={rightSideBar ? contentStyle : {
                ...contentStyle,
                marginRight: "0px"
              }}>
                  <QualContent
                      projectID={this.props.projectID}
                      transcriptID={this.props.transcriptID}
                      codeID={this.props.codeID}
                      page={this.props.page}
                      height={bodyHeight}
                      style={{width:"100%"}}
                      projectNotFoundError={projectNotFoundError}
                      transcriptNotFoundError={transcriptNotFoundError}
                      hasEditPermission={hasEditPermission}
                      subscriptionState={this.props.subscriptionState}
                      isArchived={isArchived}
                      isProjectOwner={isProjectOwner}
                      onShowCollabModal={this.onShowCollabModal}
                    />
              </div>
              {rightSideBar &&
                <div style={rightStyle}>
                    <QualRightSideBar projectID={this.props.projectID}
                                      transcriptID={this.props.transcriptID}
                                      height={bodyHeight}
                                      hasEditPermission={hasEditPermission}
                                />
                </div>
              }
            </div>
          </CodeableContextProvider>
         </div>
       </div>
   );
 }
}

export default withRouter(SNavigation);
