import { useState, useCallback } from 'react';

function useModal(modalConfirmationAction) {
  const [showModal, setShowModal] = useState(false);
  const [modalTargetId, setModalTargetId] = useState(null);

  const onShowModal = useCallback((target_id) => {
    setShowModal(true);
    setModalTargetId(target_id);
  }, []);

  const onCancelModal = useCallback(() => {
    setShowModal(false);
    setModalTargetId(null);
  }, []);

  const onConfirmModal = useCallback(() => {
    setShowModal(false);
    modalConfirmationAction(modalTargetId);
    setModalTargetId(null);
  }, [modalConfirmationAction, modalTargetId]);

  return {
    showModal,
    onShowModal,
    onCancelModal,
    onConfirmModal,
  };
}

export default useModal;
