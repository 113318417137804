import React from 'react';
import ParagraphLayout from 'paragraph/ParagraphLayout';

const NonCodeableSurveyWrapper = ({ 
  header,
  disabled, // disabled should still be used
  children,
  logo,
  onClick,
  color
}) => {
  return (
    <div
      onClick={onClick}
      style={disabled ? {
        userSelect: "none",
      } : {}}
    >
      <ParagraphLayout
        speaker={header}
        logo={logo || 'triangle-bottom'}
        color={color}
      >
        <div
        style={{
          marginTop: '10px'
        }}>
          {children}
        </div>  
      </ParagraphLayout>
    </div>
    
  );
};

export default NonCodeableSurveyWrapper;