import React from 'react';
import SentenceComponent from 'projects/components/SentenceComponent';

var paragraphStyle = {
    lineHeight: "31px"
};

const SentencesComponent = ({
    sentences,
    paragraphHover,
    isPrint,
    hasEditPermission,
    onHoverExcerpt,
    hoverExcerptId }) => {
    const sentencesComponents = sentences.map((sentence, index) =>
        <SentenceComponent key={index}
                            codedSentence={sentence}
                            paragraphHover={paragraphHover}
                            index={index}
                            isPrint={isPrint}
                            hasEditPermission={hasEditPermission}
                            onHover={onHoverExcerpt}
                            hoverExcerptId={hoverExcerptId}
                            />
    );

    return (
        <p style={paragraphStyle}>
            {sentencesComponents}
        </p>
    )
}

export default SentencesComponent;