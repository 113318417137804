import React from "react";

const SurveyTableCellContainer = ({children}) => {
    return (<td
        style={{
            border: "1px solid #dddddd",
            textAlign: "left",
            padding: "8px",
            maxWidth: "300px", // Set a maximum width
            whiteSpace: "nowrap", // Prevent wrapping
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis", // Apply ellipsis
        }}
    >
        {children}
    </td>)
}

export default SurveyTableCellContainer;