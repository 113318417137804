import React, { useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import SurveyAPI from 'apis/SurveyAPI';
import PageLoadingComponent from 'projects/components/PageLoadingComponent';
import { useSurvey } from 'surveys/SurveyContextProvider';
import DragDropSurveyLayout from './DragDropSurveyLayout';
import FileDropzoneSquare from './FileDropzoneSquare';
import FileDropZone from '../projects/components/FileDropZone';

function SurveyUploadingContainer({ projectID, height, history, match, toastManager }) {
  const [csvFile, setCSVFile] = useState(null);
  const { newSurvey } = useSurvey();

  const csvFileID = match.params.csvFileID;

  useEffect(() => {  
    if (csvFileID && projectID) {
      let attempts = 0;
      const maxAttempts = 6; // 10 seconds * 6 = 1 minute
  
      const pollCSVFile = () => {
        attempts += 1;
  
        SurveyAPI.getCSVFile(csvFileID)
          .then((csv_file) => {
            setCSVFile(csv_file);
  
            // Stop polling if the survey ID is set or the status is 'failed'
            if (csv_file.status === 'completed' || csv_file.status === 'failed') {
              clearInterval(intervalId);
            }
          })
          .catch((error) => {
            toastManager.add('Error uploading csv.', { appearance: 'error', autoDismiss: false });
            clearInterval(intervalId);
            history.push(`/projects/${projectID}/surveys/new`);
          });
  
        // Stop polling after max attempts
        if (attempts >= maxAttempts) {
          clearInterval(intervalId);
        }
      };
  
      // Call the poll function immediately
      pollCSVFile();
  
      // Set up the interval for subsequent polling
      const intervalId = setInterval(pollCSVFile, 10000); // Poll every 10 seconds
  
      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [csvFileID, history, projectID]);
  
  useEffect(() => {
    if (csvFile?.status === 'completed') {
      history.push(`/surveys/${csvFile.survey_id}`);
      newSurvey(csvFile.survey_id);
    } else if (csvFile?.status === 'failed') {
      toastManager.add(`Error uploading CSV: ${csvFile.error_log}`, { appearance: 'error', autoDismiss: false });
      history.push(`/projects/${projectID}/surveys/new`);
    }
  }, [csvFile, history, projectID]);

  return (
    <DragDropSurveyLayout height={height}>
      <FileDropzoneSquare
          isHover={false}
          nonHoverText={'Uploading CSV...'}
          loading={true}
      />
    </DragDropSurveyLayout>
  );
}

export default withToastManager(SurveyUploadingContainer);
