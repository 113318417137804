var Dispatcher = require('flux').Dispatcher;

// Create dispatcher instance
var AppDispatcher = new Dispatcher();
import AuthConstants from 'constants/AuthConstants'

// Convenience method to handle dispatch requests
AppDispatcher.handleAction = function(action) {
  if (action.actionType != AuthConstants.AUTH_UPDATE_TOKEN)
  {
    console.log("App Dispatcher: ")
    console.log(action);
  }

  this.dispatch({
    source: 'VIEW_ACTION',
    action: action
  });
}

export default AppDispatcher
