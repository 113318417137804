import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import SurveyAPI from 'apis/SurveyAPI';
import SurveyQuestion from './SurveyQuestion';
import ProjectContext from 'contexts/ProjectContext';
import CodeStore from 'stores/CodeStore';
import CodeableContext from 'contexts/CodeableContext';
import queryString from 'query-string';
import SurveyPageWrapper from './SurveyPageWrapper';
import useHighlightAnswer from './useHighlightAnswer';
import {getAnswerSelect} from './surveySelectUtils';
import useSurveyExcerpts from './useSurveyExcerpts';
import SurveyResponseNotFound from './SurveyResponseNotFound';

import {
  surveyUrlOptionFilter
} from 'utils/surveyUrlEncoder';


const SurveyResponsePage = ({
  history,
  match,
  projectID,
  hasEditPermission,
  location
}) => {
  const [surveyRow, setSurveyRow] = useState({});
  const { handleMouseDown, isAnswerDisabled } = useHighlightAnswer();
  const [notFound, setNotFound] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  const {
    selectedExcerptId,
    select,
    onExcerptSelected,
    onDeselect,
    onSelectText
  } = useContext(CodeableContext);

  const surveyResponseID = parseInt(match.params.surveyResponseID);

  const projectContext = useContext(ProjectContext);  
  const {setProjectID} = projectContext;

  const onGotoQuestion = useCallback((questionId) => {
    history.push(`/survey_questions/${questionId}`);
  }, [history]);

  const getExcerpts = useCallback(() => {
    if ( !surveyResponseID ) return [];

    const excerpts = CodeStore.getSurveyResponseExcerpts(surveyResponseID);
    return excerpts;
  }, [surveyResponseID]);

  const {getQuestionExcerpts} = useSurveyExcerpts(getExcerpts);

  // get the survey response and excerpts
  useEffect(() => {
    if (!surveyResponseID) {
      return;
    }

    if ( !snippet ) {
      onDeselect()
    }

    SurveyAPI.getSurveyRow(surveyResponseID)
      .then((response) => {
        const data = response?.data || {};
        setSurveyRow(data)
        setProjectID(data.project_id);
      })
      .catch((error) => {
        if (error?.status === 404 || error?.status === 403) {
          setNotFound(true);
        }
        console.log(error);
      });

  }, [surveyResponseID]);

  useEffect(() => {
    if (!surveyResponseID) 
      return;

    SurveyAPI.getSurveyRowExcerpts(surveyResponseID)
    .catch((error) => {
      console.log(error);
    })
    /*
      .then((response) => {
        const newExcerpts = response.data?.excerpts || [];
        setExcerpts(newExcerpts);
        const excerpt_ids = newExcerpts.map((e) => e.id);
        setExcerptsWithState(CodeStore.getExcerptsWithCodes(excerpt_ids))
      })
      ;
      */
  }, [surveyResponseID]);

  useEffect(() => {
    if (!surveyRow.survey_id) {
      return;
    }
  
    SurveyAPI.getSurveyQuestions(surveyRow.survey_id)
      .then((response) => {
        console.log(response)
        const questions = response.data.survey_questions.sort((a, b) => a.id - b.id);
        setSurveyQuestions(questions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [surveyRow.survey_id]);

  /********\
   * 
   * Scrolling
   * 
  \********/
  const parsed = queryString.parse(location.search);
  const { snippet } = parsed;

  const scrollbarRef = useRef();
  const [hasScrolled, setHasScrolled] = useState(false);
  const questionRefs = useRef({});


  useEffect(() => {
    if (snippet) {
      setHasScrolled(false);
      onExcerptSelected(snippet);
    }
  }, [snippet]);

  useEffect(() => {
    const selectedRef = questionRefs.current[select?.codeableId];
    
    // Check if both selectedRef and scrollbarRef are ready
    if (selectedRef && scrollbarRef.current && !hasScrolled && snippet) {
      // Using requestAnimationFrame to ensure scrolling happens post-render
      setHasScrolled(true);
      requestAnimationFrame(() => {
        scrollbarRef.current.scrollTop(selectedRef.offsetTop);
      });
    }
  }, [surveyRow, select?.codeableId, snippet]);  // Including only dependencies that matter

  /********\
   * 
   * End of Scrolling
   * 
  \********/
  
  const onOptionClicked = useCallback((question_id, option_id) => {
    history.push(surveyUrlOptionFilter(surveyRow.survey_id, question_id, option_id));
  }, [history, surveyRow?.survey_id]);



  // Getting the excerpts for the survey response when the survey response ID changes and when the code changes

  /*
    {
      "id":1,
      "response_text":"Alex",
      "created_at":"2024-09-09T13:00:48.536Z",
      "updated_at":"2024-09-09T13:01:03.338Z",
      "survey_questions":[
          {
            "id":1,
            "question_text":"Tell us about your Delve experience",
            "question_type":"text",
            "survey_answer":{
                "id":1,
                "created_at":"2024-09-09T13:03:12.197Z",
                "updated_at":"2024-09-09T13:03:12.197Z",
                "body":"I really enjoyed it"
            }
          },
          {
            "id":2,
            "question_text":"Location",
            "question_type":"single_choice",
            "survey_answer":{
                "id":2,
                "created_at":"2024-09-09T13:15:34.889Z",
                "updated_at":"2024-09-09T13:15:34.889Z",
                "survey_option":{
                  "id":1,
                  "option_text":"Ericeira"
                }
            }
          }
      ]
    }
  */

  if (notFound) {
    return <SurveyResponseNotFound />;
  }

  return (
    <SurveyPageWrapper
      projectID={projectID}
      hasEditPermission={hasEditPermission}
      scrollbarRef={scrollbarRef}
      title={surveyRow.response_text}
    >
      {
        surveyQuestions.map((surveyQuestion) => {
          const surveyAnswer = surveyRow.survey_answers.find(answer => answer.survey_question_id === surveyQuestion.id);
          return (
            <div
              key={surveyQuestion.id}
              ref={(el) => (questionRefs.current[surveyAnswer?.id] = el)}
            >
              <SurveyQuestion
                responseId={surveyResponseID}
                key={surveyQuestion.id}
                questionText={surveyQuestion?.question_text}
                questionType={surveyQuestion?.question_type}
                questionId={surveyQuestion?.id}
                surveyAnswer={surveyAnswer}
                excerpts={getQuestionExcerpts(surveyQuestion?.id)}
                selectedExcerptId={selectedExcerptId}
                select={getAnswerSelect(select, surveyAnswer?.id)}
                onMouseDown={handleMouseDown}
                disabled={isAnswerDisabled(surveyAnswer?.id)}
                onExcerptSelected={onExcerptSelected}
                onDeselect={onDeselect}
                onGotoQuestion={onGotoQuestion}
                onOptionClicked={onOptionClicked}
                onSelectText={onSelectText}
                hasEditPermission={hasEditPermission}
            />
            </div>
          )
        })
      }
    </SurveyPageWrapper>
  );
};

export default SurveyResponsePage;