module.exports =  {
                    PROJECT_PAGE: "PROJECT_PAGE",
                    TRANSCRIPT_PAGE: "TRANSCRIPT_PAGE",
                    CODED_BY_ME_PAGE: "CODED_BY_ME_PAGE",
                    COMPARISON_PAGE: "COMPARISON_PAGE",
                    NEW_TRANSCRIPT_PAGE: "NEW_TRANSCRIPT_PAGE",
                    EDIT_TRANSCRIPT_PAGE: "EDIT_TRANSCRIPT_PAGE",
                    DEMOGRAPHICS_PAGE: "DEMOGRAPHICS_PAGE",
                    ANALYSIS_PAGE: "ANALYSIS_PAGE",
                    SEARCH_PAGE: "SEARCH_PAGE",
                    USER_PAGE: "USER_PAGE",
                    ROOT_PAGE: "ROOT_PAGE",
                    CODE_BOOK_PAGE: "CODE_BOOK_PAGE",
                    CODE_BOOK_CODE_PAGE: "CODE_BOOK_CODE_PAGE",
                    SUBSCRIBE_PAGE: "SUBSCRIBE_PAGE",
                    CHAT_PAGE: "CHAT_PAGE",
                    NEW_SURVEY_PAGE: "NEW_SURVEY_PAGE",
                  }
