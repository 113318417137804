import React, {useCallback} from 'react';
import SurveyQuestionText from './SurveyQuestionText';
import SurveyQuestionOption from './SurveyQuestionOption';
import { useSurveySelectCallbacks } from './surveySelectCallbacks';

const SurveyQuestion = ({
  responseId,
  questionText,
  questionType,
  questionId,
  surveyAnswer,
  excerpts,
  selectedExcerptId,
  select,
  onMouseDown,
  disabled,
  onExcerptSelected,
  onDeselect,
  onOptionClicked,
  onGotoQuestion,
  onSelectText,
  hasEditPermission
}) => {
  const onGotoQuestionWithId = useCallback(() => {
    if ( questionId )
      onGotoQuestion(questionId);
  }, [onGotoQuestion, questionId]);

  // (onSelectText, surveyResponseID, surveyAnswerId, surveyQuestionId) => {
  const {
    onSurveyAnswerSelected,
    onParagraphClick
  } = useSurveySelectCallbacks(onSelectText, responseId, surveyAnswer?.id, questionId);

  /*
    {
      "id":1,
      "question_text":"Tell us about your Delve experience",
      "question_type":"text",
      "survey_answer":{
          "id":1,
          "created_at":"2024-09-09T13:03:12.197Z",
          "updated_at":"2024-09-09T13:03:12.197Z",
          "body":"I really enjoyed it"
      }
    }

    {
      "id":2,
      "question_text":"Location",
      "question_type":"single_choice",
      "survey_answer":{
          "id":2,
          "created_at":"2024-09-09T13:15:34.889Z",
          "updated_at":"2024-09-09T13:15:34.889Z",
          "survey_option":{
            "id":1,
            "option_text":"Ericeira"
          }
      }
    }
  */

  const textAnswer = questionType === 'text';
  
  if ( !textAnswer ) {
    return <SurveyQuestionOption
      surveyAnswer={surveyAnswer}
      questionId={questionId}
      questionText={questionText}
      disabled={disabled}
      onClick={onOptionClicked}
    />
  }

  return (
    <SurveyQuestionText
      questionText={questionText}
      questionType={questionType}
      surveyQuestionId={questionId}
      surveyAnswer={surveyAnswer}
      excerpts={excerpts}
      selectedExcerptId={selectedExcerptId}
      select={select}
      onParagraphClick={onParagraphClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
      onExcerptSelected={onExcerptSelected}
      onDeselect={onDeselect}
      onSurveyAnswerSelected={onSurveyAnswerSelected}
      onTitleClick={onGotoQuestionWithId}
      logo={'align-left'}
      color={"#3D6096"}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default SurveyQuestion;