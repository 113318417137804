import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import SurveyAPI from 'apis/SurveyAPI';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import EditQuestionModal from './EditQuestionModal';
import SurveyTable from './SurveyTable';
import SurveyFilterBar from './SurveyFilterBar';
import SurveyPagePageBar from './SurveyPagePageBar';
import ProjectContext from 'contexts/ProjectContext';
import DeleteSurveyQuestionModal from './DeleteSurveyQuestionModal';
import useModal from 'modals/useModal';
import PageLoadingComponent from 'projects/components/PageLoadingComponent';
import useVisibleColumns from './useVisibleColumns';
import GearDropdown from 'projects/components/GearDropdown';
import SurveyButtonToolbar from './SurveyButtonToolbar';
import SurveyNotFound from './SurveyNotFound';

import {
  goNext,
  goPrevious,
  editQuestionUrl,
  hideUrl,
  optionClicked,
  deleteFilter,
  clearCheckedUrl,
  optionRemovedUrl,
  getFilters,
  questionAddedUrl,
  optionAddedUrl
} from 'utils/surveyUrlEncoder';

import {
  getBase64Filter,
  getParams
} from 'utils/urlEncoder';

const SurveyPage = ({ match, location, history, height, hasEditPermission }) => {
  const filterRef = useRef(null);
  const [surveyRows, setSurveyRows] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [pageNext, setPageNext] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const projectContext = useContext(ProjectContext);

  const {setProjectID} = projectContext;

  const surveyId = match.params.surveyID;

  const {
    visibleColumns,
    toggleColumnVisibility,
    resetColumns,
  } = useVisibleColumns(surveyId, surveyQuestions);
  

  const deleteQuestion = useCallback((question_id) => {
    const updatedQuestions = surveyQuestions.filter((question) => question.id !== question_id);
    setSurveyQuestions(updatedQuestions);
    SurveyAPI.deleteSurveyQuestion(question_id);
  }, [surveyQuestions]);

  const {
    showModal,
    onShowModal,
    onCancelModal,
    onConfirmModal
  } = useModal(deleteQuestion);

  // this is hosted at /surveys/:survey_id
  // when the component mounts, or the survey_id changes, fetch the survey and rows
  // survey_id is in the react-router-dom match object

  const getSurveyRows = () => {
    if (!surveyId) {
      return;
    }

    setLoading(true);
    setNotFound(false);
    SurveyAPI.getSurveyRows(surveyId, getBase64Filter(location))
      .then((response) => {
        setSurveyRows(response.data.survey_rows || []);
        setPageNext(response.data.next);
        setLoading(false);
      })
      .catch((error) => {

        if (error?.status === 404 || error?.status === 403 ) {
          setNotFound(true);
        }
        console.log(error);
        setLoading(false);
      });
  }
  
  useEffect(() => {
    getSurveyRows();
  }, [surveyId, location.search]);

  useEffect(() => {
    if (!surveyId) {
      return;
    }

    setLoadingQuestions(true);

    SurveyAPI.getSurveyQuestions(surveyId)
      .then((response) => {
        const data = response?.data || {};
        setSurveyQuestions(data.survey_questions || []);
        setProjectID(data.project_id);
        setLoadingQuestions(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingQuestions(false);
      });
  }, [surveyId]);

  const surveyQuestionID = Number(match.params.surveyQuestionID); // Convert to number
  const editQuestion = surveyQuestions.find((question) => question.id === surveyQuestionID);  

  // onHide for the modal should just go to the base survey page
  const onHide = () => {
    history.push(hideUrl(location));
  }

  const onQuestionClicked = (question_id) => {
    history.push(`/survey_questions/${question_id}`);
  }

  const onEditQuestion = (question_id) => {
    history.push(
      editQuestionUrl(location, question_id)
    )
  }

  const onGoNext = () => {
    history.push(goNext(location, pageNext));
  }

  const onGoPrevious = () => {
    history.push(goPrevious(location, 20));
  }

  const onOptionClicked = (question_id, option_id) => {
    history.push(optionClicked(location, question_id, option_id))
  }

  const onQuestionAdded = (question_id) => {
    history.push(questionAddedUrl(location, question_id));
  }

  const onOptionAdded = (question_id, option_id) => {
    history.push(optionAddedUrl(location, question_id, option_id));
  }

  const onOptionRemoved = (question_id, option_id) => {
    history.push(optionRemovedUrl(location, question_id, option_id));
  }

  const onDeleteFilter = (question_id) => {
    history.push(deleteFilter(
      location,
      question_id
    ))
  }

  const onClearChecked = (question_id) => {
    history.push(clearCheckedUrl(location, question_id));
  }

  const onSaved = (newQuestion) => {
    const updatedQuestions = surveyQuestions.map((question) => {
      if (question.id === newQuestion.id) {
        return newQuestion;
      }
      return question;
    });

    setSurveyQuestions(updatedQuestions)

    // this is a full repull, that's probably find, but maybe could be more effecient
    getSurveyRows();
  }

  const filterHeight = filterRef.current ? filterRef.current.clientHeight : 0;
  const filters = getFilters(location);
  const padding = 25;

  if (notFound) {
    return <SurveyNotFound />;
  }

  return (
    <div>
      <EditQuestionModal
        question_type={editQuestion?.question_type}
        question_id={editQuestion?.id}
        onHide={onHide}
        onSaved={onSaved}
        hasEditPermission={hasEditPermission}
      />

      <DeleteSurveyQuestionModal
        cancelDelete={onCancelModal}
        confirmDelete={onConfirmModal}
        showModal={showModal}
      />

      <div ref={filterRef} style={{ display: 'flex', alignItems: 'stretch', paddingLeft: `${padding - 10}px` }}>
        {!loadingQuestions && 
        <>
          <div style={{ flex: 1 }}>
            <SurveyFilterBar
              surveyQuestions={surveyQuestions}
              filters={filters}
              questionAdded={onQuestionAdded}
              optionAdded={onOptionAdded}
              onOptionRemoved={onOptionRemoved}
              onDeleteFilter={onDeleteFilter}
              onClearChecked={onClearChecked}
            />
          </div>
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-end' }}>
           <SurveyButtonToolbar> {/* Unfortunately this is needed to get the modal to line up correctly*/}
              <GearDropdown
                codes={surveyQuestions.map(question => ({
                  name: question.question_text,
                  id: question.id,
                  checked: visibleColumns.some(column => column.id === question.id)
                }))}
                onChecked={(codeID, checked) => toggleColumnVisibility(codeID)}
                subHeader={"Select columns to show"}
              />
            </SurveyButtonToolbar>
          </div>
        </>
        }
      </div>

      <Scrollbars autoHide style={{height:height - filterHeight}}>
        {
          loadingQuestions && <PageLoadingComponent />
        }
        <div style={{paddingLeft: padding}}>
          {!loadingQuestions && <SurveyTable
            surveyQuestions={visibleColumns}
            surveyRows={surveyRows}
            onOptionClicked={onOptionClicked}
            onEditQuestion={onEditQuestion}
            onQuestionClicked={onQuestionClicked}
            onDeleteQuestion={onShowModal}
            loading={loading}
            toggleColumnVisibility={toggleColumnVisibility}
            hasEditPermission={hasEditPermission}
          />}
          {!loading && !loadingQuestions && <SurveyPagePageBar
            onGoPrevious={onGoPrevious}
            onGoNext={onGoNext}
            pageNext={pageNext}
            offset={getParams(location).offset}
            disabled={loading}/>}
        </div>
      </Scrollbars>
    </div>
  );
};

export default withRouter(SurveyPage);