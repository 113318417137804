import React from 'react';
import { MenuItem, DropdownButton } from 'react-bootstrap';

const SurveyTableHeader = ({
  surveyQuestions,
  onEditQuestion,
  onDeleteQuestion,
  toggleColumnVisibility,
  hasEditPermission,
}) => {
  return (
    <thead>
      <tr>
        <th
          style={{
            border: "1px solid #dddddd",
            textAlign: "left",
            padding: "8px",
            backgroundColor: "#f2f2f2",
          }}
        >
          Name
        </th>
        {/* Map through questions to create headers */}
        {surveyQuestions.map((surveyQuestion) => (
          <th
            key={surveyQuestion.id}
            style={{
              border: "1px solid #dddddd",
              textAlign: "left",
              padding: "8px",
              backgroundColor: "#f2f2f2",
              maxWidth: "300px", // Set a maximum width
              whiteSpace: "nowrap", // Prevent wrapping
              overflow: "visible", // Allow the dropdown to be visible
              textOverflow: "ellipsis", // Apply ellipsis
            }}
          >
            {/* Text and button side by side */}
            <div
              style={{
                display: "inline-block",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                verticalAlign: "middle", // Align vertically with the button
              }}
            >
              {surveyQuestion.question_text}
            </div>

            {/* Dropdown button next to the text */}
            <DropdownButton
              id={`survey_question-dropdown-${surveyQuestion.id}`}
              bsStyle="link"
              title=""
              style={{
                display: "inline-block",
                marginLeft: "10px", // Add some space between the text and the button
                verticalAlign: "middle", // Align vertically with the text
              }}
            >
              {
                hasEditPermission && 
                  <MenuItem onClick={() => onEditQuestion(surveyQuestion.id)}>
                    Edit Question
                  </MenuItem>
              }
              <MenuItem onClick={() => toggleColumnVisibility(surveyQuestion.id)}>
                Hide Question
              </MenuItem>
              {
                hasEditPermission &&
                  <MenuItem
                    onClick={() => onDeleteQuestion(surveyQuestion.id)}
                  >Delete Question</MenuItem>
              }
            </DropdownButton>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default SurveyTableHeader;
