import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import TranscriptCodePageContainer from 'projects/components/TranscriptCodePageContainer';
import { MemoModalProvider } from 'projects/components/modal/MemoModalProvider';


const SurveyPageWrapper = ({
  projectID,
  hasEditPermission,
  scrollbarRef,
  title,
  children
}) => {
 
  return (
    <Scrollbars style={{ height: "100%" }} ref={scrollbarRef}>
      <MemoModalProvider>
        <TranscriptCodePageContainer
          projectID={projectID}
          hasEditPermission={hasEditPermission}      
        />
      </MemoModalProvider>

        <div>
          <h2 style={{
            margin: "20px 20px",
          }}>{title}</h2>

          <div style={{marginRight: "20px"}}>
            {children}
          </div>
        </div>
    </Scrollbars>
  );
};

export default SurveyPageWrapper;