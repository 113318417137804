import React from 'react';
import FileDropzoneText from 'projects/components/FileDropzoneText';

function FileDropzoneSquare({ isHover, hoverText, nonHoverText, loading}) {
  return (
    <div
      style={{
        fontSize: '1.2em',
        color: isHover ? '#007BFF' : '#000',
        padding: '30px',
        border: isHover ? '2px dashed #007BFF' : '2px dashed #ccc',
        borderRadius: '5px',
        backgroundColor: isHover ? '#f7faff' : '#fff',
        cursor: 'pointer',
        position: 'relative',
        minHeight: '200px', // Reasonable minimum size
        height: "100%",
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: '800px',
        maxHeight: '800px',
      }}
    >
      <FileDropzoneText
        isHover={isHover}
        header={!isHover ? nonHoverText : hoverText}
        loading={loading}
      />
    </div>
  );
}

export default FileDropzoneSquare;
