// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import HeaderDropdown from './HeaderDropdown'

function HeaderFrame({
  children,
  dropdownOptions,
  projectID,
  hasEditPermission,
  subscriptionState,
  onShowCollabModal,
  showCollabModal
}) {
  return (<div style={{
    width:"100%",
    display:"flex",
    flexWrap:"nowrap",
  }}>
    <div style={{
      flexGrow:1,
      flexBasis: 0,
      overflow: "hidden",
    }}>
      {children}
    </div>

    <div
      style={{
        flexBasis: 'auto',
        flexGrow: 0,
        flexShrink: 0}}
    >
      {dropdownOptions && <HeaderDropdown
        projectID={projectID}
        hasEditPermission={hasEditPermission}
        subscriptionState={subscriptionState}
        onShowCollabModal={onShowCollabModal}
        showCollabModal={showCollabModal}
      >
        {dropdownOptions}      
      </HeaderDropdown>}
    </div>
  </div>)
}

export default HeaderFrame;