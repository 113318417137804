// useParagraphIndex.js
import { useState, useCallback } from 'react';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';

const useParagraphIndex = (page, search, shouldGotoOtherPage) => {
  const [paragraphIndex, setParagraphIndex] = useState(null);

  const scrollToParagraphIndex = useCallback((transcriptID, pos) => {
    if ( !pos || !transcriptID ) return;

    // we'll need to get the paragraph index from the position to scroll to
    const paragraphHash = CodedTranscriptStore.getParagraphIndexWithLocation(transcriptID, pos);

    if ( !paragraphHash ) return

    // position is absolute, but we'll need to go tot he page of the paragraph if we are not currently on it
    // however if search has not resolved yet, we should wait
    if ( paragraphHash.page != (page || 1) && !search ) { 
      shouldGotoOtherPage(paragraphHash.page);
      // if you are not on the right page, you should wait to scroll, there would be no point
      // and you would be scrolling based on the wrong paragraph heights
      return;
    }

    const paragraphIndex = paragraphHash.paragraphIndex;
    setParagraphIndex(paragraphIndex);
  }, [page, search, shouldGotoOtherPage]);

  return { paragraphIndex, scrollToParagraphIndex };
};

export default useParagraphIndex;
