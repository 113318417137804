import React, {useCallback} from 'react';
import NonCodeableSurveyWrapper from './NonCodeableSurveyWrapper';
import {
  LIGHT_GREY
} from 'constants/ColorConstants';

const SurveyQuestionNoAnswer = ({ 
  questionType,
  questionText,
  onClick
}) => {
  return (
    <NonCodeableSurveyWrapper
      header={questionText}
      disabled={true}
      logo={questionType === 'text' ? 'align-left' : 'triangle-bottom'}
      onClick={onClick}
      color={questionType === 'text' ? "#3D6096" : null}
    >
      <span style={{color: LIGHT_GREY}}>
          &mdash;
      </span>
    </NonCodeableSurveyWrapper>
  );
};

export default SurveyQuestionNoAnswer;