// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert} from 'react-bootstrap'
import LoadingButton from './LoadingButton'
import Disclaimer from './Disclaimer'

const WIDTH = "400px"

class AccountSingleButton extends React.Component {
  constructor () {
    super();
    this.state = {
    };
  }

  render () {
    return (
      <div style={{textAlign:"center", width:WIDTH, margin:"0 auto"}}>
        { this.props.error && this.props.error.error &&
          <Alert bsStyle="warning">{this.props.error.message}</Alert>
        }
        <h4>{this.props.header}</h4>

        <div style={{width:WIDTH}}>
          {this.props.body}
        </div>

        <div style={{marginTop:"30px", marginBottom:"20px"}}>
          {(this.props.to || this.props.onClick) &&
            <LoadingButton bsStyle="blue"
                            width={WIDTH}
                            center
                            onClick={this.props.onClick}
                            to={this.props.to}
                            loading={this.props.loading}
                            disabled={!!this.props.disabled}>
              {this.props.button}
            </LoadingButton>
          }

          {this.props.onSubButton &&
            <Button bsStyle='link' onClick={this.props.onSubButton}>
              {this.props.subButton}
            </Button>
          }
        </div>

        <Disclaimer hideTerms={this.props.hideTerms}>
          {this.props.disclaimer}
        </Disclaimer>
      </div>
    );
  }
}

export default AccountSingleButton;
