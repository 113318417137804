import React from 'react';
import {ButtonToolbar} from 'react-bootstrap';

const SurveyButtonToolbar = ({ 
    children
}) => {
    return (
        <div style={{
            paddingTop: '10px',
            paddingRight: '10px',
        }}>
            <ButtonToolbar>
                {children}
            </ButtonToolbar>
        </div>
    );
};

export default SurveyButtonToolbar;