// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useState, useCallback} from 'react';

import FileDropZone from './FileDropZone'
import TranscriptActions from 'actions/TranscriptActions'
import NewTranscriptContainer from './NewTranscriptContainer'

function DragDropTranscriptContainer({projectID, height, onboardingState, history}) {
  const [isHover, setIsHover] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setIsHover(false);
    TranscriptActions.uploadManyTranscripts(projectID, acceptedFiles);
  }, [projectID]);

  const isHovering = useCallback((isHover) => {
    setIsHover(isHover);
  }, []);

  return (
    <div style={{height:height}}>
        <FileDropZone
          onDrop={onDrop}
          isHovering={isHovering}
          >
          {projectID &&
            <NewTranscriptContainer
              projectID={projectID}
              height={height}
              isHover={isHover}
              onboardingState={onboardingState}
              history={history}
            />
          }
        </FileDropZone>
    </div>
  );
}

export default DragDropTranscriptContainer;