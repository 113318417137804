import getParent from 'mappers/nest/ParentMapper'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'
const ROOT_KEY = 'ROOT'

const CANT_DROP_MARGIN = {
  canDropOnMargin: false
}

function getFilterAndMatch(isSearching, code, searchText) {
  let filtered = false;
  let match = false;

  if ( isSearching && code )
  {
    const codeLower = (code?.name || '').toLowerCase();
    const matchLower = (searchText || '').toLowerCase();
    filtered = !codeLower.includes(matchLower);
    if ( codeLower == matchLower ) match = true;
  }

  return {filtered, match}
}

const mergedNames = (name1, name2) => {
  return [name1, name2].filter((name)=>!!name).join(' | ')
}

function getMergeAttributes(state, name, id, mergeParent, mergeCode, mergeCodeInto, childCountZero, dragCodeID) {
  if ( mergeCode.id && mergeCodeInto.id && mergeCodeInto.id == id ) {
    const mergeName = mergedNames(name, mergeCode.name);
    return {
      name:mergeName,
      showConfirmMerge: true,
      showMerge: true,
      mergeName
    }
  }

  if ( !childCountZero || !dragCodeID || dragCodeID === id )
    return {
      showMerge: false
    }

  const dragCode = OptimisticMapperHelper.getCode(state, dragCodeID) || {};

  const mergeName = mergedNames(name, dragCode.name);

  return {
    showMerge: true,
    mergeName
  }
}

const _canDropOnParent = (level, dragCodeDepth, parentGhost, dragCodeID) => {
  return level + dragCodeDepth <= 3 && !parentGhost && !!dragCodeID;
}

const _convertParentID = (codeID) => codeID === ROOT_KEY ? null : codeID;

const _hasSameParent = (code, dragCode) => {
  return (code.parent_id || null) === (dragCode.parent_id || null);
}

const _getPositionUnder = (state, codeID, dragCodeID) => {
  const code = OptimisticMapperHelper.getCode(state, codeID);
  const dragCode = OptimisticMapperHelper.getCode(state, dragCodeID);
  if ( !_hasSameParent(code, dragCode) )
    return code.position + 1;
  else if (  code.position < dragCode.position )
    return code.position + 1;
  else
    return code.position;
}

const _getSingleDepthMarginPosition = (state, codeId, level, dragCodeID, dragCodeDepth, hasChildren, maxPosition, codeCollapsed) => {
  const parentID = getParent(state, codeId);
  const code = OptimisticMapperHelper.getCode(state, codeId);

  if ( dragCodeID !== codeId ) {
    if ( !hasChildren || !!codeCollapsed ) {
      return {
        marginDepth: level,
        marginDropCodeId: _convertParentID(parentID),
        marginDropPositon: _getPositionUnder(state, codeId, dragCodeID),
        canDropOnMargin: true
      }
    } else {
      return {
        marginDepth: level + 1,
        marginDropCodeId: codeId,
        marginDropPositon: 1,
        canDropOnMargin: true
      }
    }
  } else {
    if ( hasChildren ) {
      throw new Error('A nest of depth one cannot have a child...')
    } else {
      if ( code.position !== maxPosition ) {
        return {
          ...CANT_DROP_MARGIN,
          level: level + 1
        };
      }
      else if ( parentID === ROOT_KEY ) {
        return {
          ...CANT_DROP_MARGIN,
          level: level + 1
        };
      }
      else
      {
        const grandParentID = getParent(state, parentID);
        const parent = OptimisticMapperHelper.getCode(state, parentID);

        return {
          marginDepth: level - 1,
          marginDropCodeId: _convertParentID(grandParentID),
          marginDropPositon: _getPositionUnder(state, parentID, dragCodeID),
          canDropOnMargin: true
        }
      }
    }
  }
}

const _getDoubleDepthMarginPosition = (
  state,
  codeId,
  level,
  dragCodeID,
  dragCodeDepth,
  hasChildren,
  maxPosition,
  parentMaxPosition,
  codeCollapsed
) => {
  let parentID = getParent(state, codeId);

  const code = OptimisticMapperHelper.getCode(state, codeId);
  const isLastCode = code.position >= maxPosition;

  if (  codeId === dragCodeID ) {
    return CANT_DROP_MARGIN
  } else if ( parentID === dragCodeID ) {
    const parentCode = OptimisticMapperHelper.getCode(state, parentID);
    const isParentLastCode = parentCode.position >= parentMaxPosition
    if ( !isLastCode || !isParentLastCode )
      return {
        ...CANT_DROP_MARGIN,
        level: level + 1
      }
    else {
      const grandParentID = getParent(state, parentID);

      if ( grandParentID === ROOT_KEY ){
        return {
          ...CANT_DROP_MARGIN,
          level: level + 1
        };
      }
      else {
        const grandParentCode = OptimisticMapperHelper.getCode(state, grandParentID);
        if ( !grandParentCode ) // NOT SURE THIS IS REACHABLE...
          return {
            ...CANT_DROP_MARGIN,
            level: level + 1
          };

        return {
          marginDepth: 1,
          marginDropCodeId: null,
          marginDropPositon: _getPositionUnder(state, _convertParentID(grandParentID), dragCodeID),
          canDropOnMargin: true
        }
      }
    }
  }
  else  {
    if ( level === 1 ) {
      if ( !hasChildren || !!codeCollapsed ) {
        return {
          marginDepth: level,
          marginDropCodeId: _convertParentID(parentID),
          marginDropPositon: _getPositionUnder(state, codeId, dragCodeID),
          canDropOnMargin: true
        }
      } else {
        return {
          marginDepth: level + 1,
          marginDropCodeId: codeId,
          marginDropPositon: 1,
          canDropOnMargin: true
        }
      }
    }
    else if ( level === 2 ) {
     if ( !hasChildren || !!codeCollapsed ) {
       return {
         marginDepth: level,
         marginDropCodeId: _convertParentID(parentID),
         marginDropPositon: _getPositionUnder(state, codeId, dragCodeID),
         canDropOnMargin: true
       }
     } else {
       return {
         ...CANT_DROP_MARGIN,
         level: level + 1
       };
     }
   } else {
     if ( hasChildren ) {
       throw new Error('level 3 should not be allowed to have children')
     } else {
       if ( isLastCode ) {
         const grandParentID = getParent(state, parentID);
         const parentCode = OptimisticMapperHelper.getCode(state, parentID);

         return {
           marginDepth: level - 1,
           marginDropCodeId: _convertParentID(grandParentID),
           marginDropPositon: _getPositionUnder(state, parentID, dragCodeID),
           canDropOnMargin: true
         }
       } else {
         return {
           ...CANT_DROP_MARGIN,
           marginDepth: level + 1
         }
       }
     }
   }
  }
}

const _getTripleDepthMarginPosition = (
  state,
  codeId,
  level,
  dragCodeID,
  dragCodeDepth,
  hasChildren,
  maxPosition,
  parentMaxPosition,
  codeCollapsed
) => {
  const parentID = getParent(state, codeId);
  const grandParentID = getParent(state, parentID);
  const code = OptimisticMapperHelper.getCode(state, codeId);
  const isLastCode = code.position >= maxPosition;

  // No nesting on yourself if you are the third layer
  if ( parentID === dragCodeID || grandParentID === dragCodeID || codeId === dragCodeID)
    return CANT_DROP_MARGIN;
  // You are dragging a code with grandchildren
  // If it has children and the code is not collapsed you cannot drop in the margin
  // If the code IS collapsed you can potentially drop things on the margin
  else if ( hasChildren && !codeCollapsed )
    return CANT_DROP_MARGIN;
  else if ( level === 1) {
    return {
      marginDepth: 1,
      marginDropCodeId: null,
      marginDropPositon: _getPositionUnder(state, codeId, dragCodeID),
      canDropOnMargin: true
    }
  }
  else if ( level === 2 ) {
    if ( !isLastCode ) {
      return CANT_DROP_MARGIN
    }

    const parentCode = OptimisticMapperHelper.getCode(state, parentID);

    return {
      marginDepth: 1,
      marginDropCodeId: null,
      marginDropPositon: _getPositionUnder(state, parentID, dragCodeID),
      canDropOnMargin: true
    }
  }
  else if ( level === 3 ) {
    const parentCode = OptimisticMapperHelper.getCode(state, parentID);
    const isParentLastCode = parentCode.position >= parentMaxPosition

    if ( !isLastCode || !isParentLastCode)
      return {
        ...CANT_DROP_MARGIN,
        marginDepth: level
      }

    const grandParentCode = OptimisticMapperHelper.getCode(state, grandParentID);
    if ( !grandParentCode ) // I think this is unreachable
      return {
              ...CANT_DROP_MARGIN,
              marginDepth: level
            };

    return {
      marginDepth: 1,
      marginDropCodeId: null,
      marginDropPositon: _getPositionUnder(state, grandParentID, dragCodeID),
      canDropOnMargin: true
    }
  }
}

const _isNoOp = (state, dragCodeID, returnCode) => {
  if ( !returnCode.canDropOnMargin || !dragCodeID || !returnCode ) return {};
  const dragCode = OptimisticMapperHelper.getCode(state, dragCodeID);
  if ( !dragCode ) return {};
  if ( (dragCode.parent_id || null ) !== (returnCode.marginDropCodeId || null) ) return {};
  if ( dragCode.position !== returnCode.marginDropPositon ) return {};

  return {
    marginNoOp: true
  }
}

const _getMarginPosition = (state, codeId, level, dragCodeID, dragCodeDepth, hasChildren, maxPosition, parentMaxPosition, codeCollapsed) => {
  if ( !codeId || !dragCodeID) return CANT_DROP_MARGIN;

  let returnCode;
  if ( dragCodeDepth === 1) {
    returnCode = _getSingleDepthMarginPosition(state, codeId, level, dragCodeID, dragCodeDepth, hasChildren, maxPosition, codeCollapsed)
  } else if ( dragCodeDepth === 2 ) {
    returnCode = _getDoubleDepthMarginPosition(state, codeId, level, dragCodeID, dragCodeDepth, hasChildren, maxPosition, parentMaxPosition, codeCollapsed)
  } else
    returnCode = _getTripleDepthMarginPosition(state, codeId, level, dragCodeID, dragCodeDepth, hasChildren, maxPosition, parentMaxPosition, codeCollapsed)

  return {
    ...returnCode,
    ..._isNoOp(state, dragCodeID, returnCode)
  };
}

function recursiveBuild(state,
                        level,
                        findCodes,
                        codeEntities,
                        parentMapper,
                        collapseMapper,
                        collapseBarMapper,
                        key,
                        dragCodeID,
                        dragCodeDepth,
                        parentGhost,
                        searchText,
                        mergeCode,
                        mergeCodeInto,
                        parentId,
                        maxPosition,
                        parentMaxPosition
                      )
{
  mergeCode = mergeCode || {};
  mergeCodeInto = mergeCodeInto || {};
  const isSearching = !!searchText && searchText.length > 0;
  parentGhost = parentGhost || dragCodeID == key;
  const code = codeEntities[key] || {};

  const childrenPositions = (parentMapper[key] || [0]).map((childID)=>{
    const code = codeEntities[childID] || {};
    return code.position || 0;
  })

  const childMaxPosition = Math.max(...childrenPositions);

  const children = (parentMapper[key] || []).map((childID)=>{
    // if your child is the drag code, you are the drop parent
    // if the a child is being dragged onto you and onto the merge zone, do not need to render further
    // but, if there the child is the current merge code, it also does not need to be rendered
    if ( mergeCode.id && mergeCode.id == childID ) return null;

    return recursiveBuild(state,
      level + 1,
      findCodes,
      codeEntities,
      parentMapper,
      collapseMapper,
      collapseBarMapper,
      childID,
      dragCodeID,
      dragCodeDepth,
      parentGhost,
      searchText,
      mergeCode,
      mergeCodeInto,
      parentGhost ? parentId : (code.id || null),
      childMaxPosition,
      maxPosition
    );
  }).filter((child)=>!!child)
  .sort(function (a, b) {return a.position-b.position});

  const childCount = children.map((child)=>(child.childCount || 0) + 1).reduce((a,b)=>a+b, 0);

  const {filtered, match} = getFilterAndMatch(isSearching, codeEntities[key], searchText);
  findCodes['match'] = match || findCodes['match'];

  // TODO: not sure what this is for will likely need to fix it
  const mergeParent = false;

  const hasChildren = children.length > 0;

  const canDropOnParent = _canDropOnParent(level, dragCodeDepth, parentGhost, dragCodeID)

  level + dragCodeDepth <= 3 && !parentGhost;

  const {marginDropCodeId, marginDropPositon, marginDepth, canDropOnMargin, marginNoOp} =
  _getMarginPosition(state,
    code.id,
    level,
    dragCodeID,
    dragCodeDepth,
    hasChildren,
    maxPosition,
    parentMaxPosition,
    collapseBarMapper[code.id]
  );

  let returnValue = {
    ...code,
    ...getMergeAttributes(state,
      code.name,
      code.id,
      mergeParent,
      mergeCode,
      mergeCodeInto,
      dragCodeDepth === 1,
      dragCodeID
    ),
    childCount: childCount,
    collapsed: !!collapseMapper[key],
    barCollapsed: !!collapseBarMapper[key] && !(isSearching),
    ghostCode: parentGhost, // are you ghosted
    children: children,
    filtered: filtered,
    isSearching: isSearching,
    canDropOnMargin,
    ...(canDropOnMargin && {
      marginDropCodeId,
      marginDropPositon,
    }),
    ...(marginDepth && {
      marginDepth
    }),
    ...(marginNoOp && {
      marginNoOp
    }),
    canDropOnParent,
    //dropCodeId: code ? (parentGhost ? parentId : code.id ) : null,
    level: level,
    position: code.position
  };

  return returnValue
}

export default recursiveBuild
