// Desc: Modal for deleting a transcript

import React from 'react';
import DeleteModal from 'projects/components/DeleteModal';

function DeleteSurveyQuestionModal({
  cancelDelete,
  confirmDelete,
  showModal
}) {
  return <DeleteModal
    title="Are you sure you want to delete this survey question?"
    onCancel={cancelDelete}
    onDelete={confirmDelete}
    show={showModal}
  >
    All answers and coded snippets from this survey question will be deleted.
  </DeleteModal>
}

export default DeleteSurveyQuestionModal;