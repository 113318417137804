import React from 'react';

function DragDropSurveyLayout({ children, height}) {
  return (
    <div
      style={{
        height: height,
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column', // Ensures vertical stacking
        boxSizing: 'border-box',
      }}
    >
      <h2 style={{ marginBottom: '15px' }}>Add Survey by Importing a CSV File</h2>
      <ol style={{ textAlign: 'left', fontSize: '1.2em', marginBottom: '20px' }}>
        <li>Ensure each row is a survey response.</li>
        <li>Use column 1 for participant’s name or ID.</li>
        <li>Delete unwanted columns before importing.</li>
      </ol>
  
      <div
        style={{
          flex: 1, // Ensures this div takes all available space
          display: 'flex',
        }}
      >
        {children}
      </div>
    </div>
  );
  
  
  
}

export default DragDropSurveyLayout;
