// ExcerptMapper.js
import memoMapper from './MemoMapper';
import collaboratorsMapper from './CollaboratorsMapper';
const _ = require('underscore');

const excerptMemoMapper = (state, excerpt, projectID) => {
  if ( !excerpt ) return null;
  
  const collaborators = collaboratorsMapper(state, projectID);
  const userColors = collaborators.userColors;
  
  const memos = _.chain((excerpt.memos || [])).map((memo_id)=>
    memoMapper(state, memo_id, userColors)
  ).filter((memo)=>!!memo)
  .sortBy((memo)=>memo.created_at).value()

  return {
    ...excerpt,
    memos
  }
}

export default excerptMemoMapper
