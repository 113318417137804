import { v1 as uuidv1 } from 'uuid';

import FilterStore from 'stores/FilterStore';
import ExcerptAPI from 'apis/ExcerptAPI';
import AppDispatcher from 'dispatcher/AppDispatcher';
import FilterAPI from 'apis/FilterAPI';
import FilterConstants from 'constants/FilterConstants';
import pagingUtil from 'utils/PagingUtil';

import {
  OP_OR,
  OP_AND
} from 'constants/OpConstants'

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var FilterActions = {
  /****************************\
  *
  * Filtering
  *
  \****************************/
  filterCleared: function(descriptorID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_CLEAR,
      data: {
        descriptorID: descriptorID,
        projectID: projectID,
        source
      }
    })
  },

  transcriptChecked: function(transcriptID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.TRANSCRIPT_CHECKED,
      data: {
        transcriptID: transcriptID,
        projectID: projectID,
        source
      }
    });
  },

  transcriptUnchecked: function(transcriptID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.TRANSCRIPT_UNCHECKED,
      data: {
        transcriptID: transcriptID,
        projectID: projectID,
        source
      }
    });
  },

  transcriptCleared: function(projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.TRANSCRIPT_CLEAR,
      data: {
        projectID: projectID,
        source
      }
    });
  },

  filterChecked: function(descriptorID, choiceID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_CHECKED,
      data: {
        descriptorID: descriptorID,
        choiceID: choiceID,
        projectID: projectID,
        source
      }
    })
  },

  filterUnchecked: function(descriptorID, choiceID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_UNCHECKED,
      data: {
        descriptorID: descriptorID,
        choiceID: choiceID,
        projectID: projectID,
        source
      }
    })
  },

  filterSort: function(projectID, sort) {
    AppDispatcher.handleAction({
      actionType: FilterConstants.SORT_CHANGED,
      data: {
        projectID: projectID,
        sort: sort
      }
    })
  },

  filterCheckCodeId: function(codeID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_CODE_CHECKED,
      data: {
        codeID: codeID,
        projectID: projectID,
        source
      }
    })
  },

  filterUncheckCodeId: function(codeID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_CODE_UNCHECKED,
      data: {
        codeID: codeID,
        projectID: projectID,
        source
      }
    })
  },

  filterClearCheckedCodes: function(projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_CODE_CLEAR,
      data: {
        projectID: projectID,
        source
      }
    })
  },

  surveyChecked: function(surveyID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.SURVEY_CHECKED,
      data: {
        surveyID: surveyID,
        projectID: projectID,
        source
      }
    });
  },

  surveyUnchecked: function(surveyID, projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.SURVEY_UNCHECKED,
      data: {
        surveyID: surveyID,
        projectID: projectID,
        source
      }
    });
  },

  surveyCleared: function(projectID, source)
  {
    AppDispatcher.handleAction({
      actionType: FilterConstants.SURVEY_CLEAR,
      data: {
        projectID: projectID,
        source
      }
    });
  },

  _filterAndDispatch(tmpID, projectID, codeIDs, choiceIDs, transcriptIDs, surveyIDs, limit, op, offset, sort, source) {
    return FilterAPI.filterByCodes(
      projectID,
      codeIDs,
      choiceIDs,
      transcriptIDs,
      surveyIDs,
      limit,
      offset,
      op,
      sort,
      source
    ).then((response) => {
      AppDispatcher.handleAction({
        actionType: FilterConstants.FILTER_EXCERPTS_RESULT,
        data: {
          ...response.data,
          filterUUID:tmpID,
          projectID: projectID,
        }
      })
      return response.data;
    })
    .catch(error => {
        console.error(error);
        AppDispatcher.handleAction({
          actionType: FilterConstants.FILTER_EXCERPTS_ERROR,
          data: {
            projectID: projectID,
            filterUUID: tmpID,
          },
          error: error
        })
    });
  },

  _pageThroughExcerpts(projectID, codeIDs, choiceIDs, transcriptIDs, surveyIDs, op) {
    const tmpID = uuidv1();

    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_EXCERPTS,
      data:{
        projectID: projectID,
        filterUUID:tmpID,
      }
    })

    return pagingUtil(this._filterAndDispatch, tmpID, projectID, codeIDs, choiceIDs, transcriptIDs, surveyIDs, 100, op);
  },

  pageThroughExcerptsWithFilters(projectID) {
    const {codes, multi_choices, transcripts, surveys, op} = FilterStore.getFilters(projectID);
    return this._pageThroughExcerpts(projectID, codes, multi_choices, transcripts, surveys, op);
  },

  getFilterReport: (tmpID, projectID, codeIDs, choiceIDs, transcriptIDs, surveyIDs, op) => {
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_REPORT,
      data:{
        projectID: projectID,
        filterUUID: tmpID,
      }
    });

    return FilterAPI.getFilterReport(projectID, codeIDs, choiceIDs, transcriptIDs, surveyIDs, op)
    .then((response)=>{
      AppDispatcher.handleAction({
        actionType: FilterConstants.FILTER_REPORT_RESPONSE,
        data: {
          ...response.data,
          filterUUID: tmpID,
        }
      })
      return response.data;
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: FilterConstants.FILTER_REPORT_ERROR,
        data: {
          project_id: projectID,
          filterUUID: tmpID,
        },
        error: error
      })
    })
  },

  bulkGetExcerpts: function(projectID, excerptIDs) {
    return ExcerptAPI.bulkGetExcerpts(
      projectID,
      excerptIDs
      ).then((response) => {
      AppDispatcher.handleAction({
        actionType: FilterConstants.FILTER_EXCERPTS_RESULT,
        data: {
          ...response.data,
          projectID: projectID,
        }
      })
      return response.data;
    })
  },


  filterByFilters: function(projectID, codes, multi_choices, transcripts, surveys, op, sort, source) {
    const tmpID = uuidv1();
    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_EXCERPTS,
      data:{
        projectID: projectID,
        filterUUID:tmpID,
      }
    })

    return this._filterAndDispatch(
      tmpID,
      projectID,
      codes,
      multi_choices,
      transcripts,
      surveys,
      null,
      op,
      null,
      sort,
      source
    ).then((result)=>{
      return this.getFilterReport(
        tmpID,
        projectID,
        codes,
        multi_choices,
        transcripts,
        surveys,
        op
      )
    })
  },

  filterByCodes: function(projectID, source)
  {
    const {codes, multi_choices, transcripts, surveys, op, sort} = FilterStore.getFilters(projectID);
    return this.filterByFilters(projectID, codes, multi_choices, transcripts, surveys, op, sort, source);
  },

  nextFilterPage: function(projectID, source)
  {
    const {codes, multi_choices, transcripts, surveys, uuid, next, op} = FilterStore.getFilters(projectID);

    AppDispatcher.handleAction({
      actionType: FilterConstants.FILTER_NEXT,
      data:{
        projectID: projectID,
      }
    })

    return this._filterAndDispatch(
      uuid,
      projectID,
      codes,
      multi_choices,
      transcripts,
      surveys,
      null,
      op,
      next,
      null,
      source
    )
  },

  codeOpAnyAction: function(projectID, source) {
    AppDispatcher.handleAction({
      actionType: FilterConstants.CODE_OP_CHANGED,
      data: {
        projectID,
        op: OP_OR,
        source
      }
    })
  },

  codeOpAllAction: function(projectID, source) {
    AppDispatcher.handleAction({
      actionType: FilterConstants.CODE_OP_CHANGED,
      data: {
        projectID,
        op: OP_AND,
        source
      }
    })
  }
}

export default FilterActions
