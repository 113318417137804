// NIL means way in the future

module.exports = {
  TRANSCRIPT: 'Transcript/Survey',
  SNIPPET: 'Snippet',
  URL: 'URL',
  MEMOS: 'Memos',
  CODED_BY: 'Snippet Coded By',
  CODES: 'Codes',
  CODE: 'Code',
  START: 'Start Position on Transcript',
  END: 'End Position on Transcript',
  SURVEY_QUESTION: 'Survey Question',
  SURVEY_RESPONDANT_NAME: 'Survey Respondent Name',
}
