import React from "react";
import SurveyOption from "./SurveyOption";
import SurveyTableCellContainer from "./SurveyTableCellContainer";
import SurveyTableTextCell from "./SurveyTableTextCell";

const SurveyTableCell = ({ answer, question, optionClicked, onQuestionClicked }) => {
    const body = answer?.body

    if ( !body )
        return <SurveyTableCellContainer/>


    if ( question?.question_type === "single_choice" ) {
        return (<SurveyTableCellContainer>
            <SurveyOption
                body={body}
                optionClicked={() => optionClicked(question.id, answer.option_id)}
            />
        </SurveyTableCellContainer>)
    } else {
        return (<SurveyTableTextCell
            body={body}
            question_id={question.id}
            onQuestionClicked={onQuestionClicked}
        />)
    }
}

export default SurveyTableCell;