import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal } from 'react-bootstrap'
import ExcerptListComponent from './ExcerptListComponent'
import CloseModalButton from './CloseModalButton';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import CodeStore from 'stores/CodeStore';
import JobStore from 'jobs/JobStore';
import { MemoModalProvider } from './modal/MemoModalProvider';
import ChatActions from 'chat/ChatActions';
import UserContext from 'contexts/UserContext';
import AICodingButton from './AICodingButton';
import QualCodeActions from 'actions/QualCodeActions';
import { Alert } from 'react-bootstrap';
import HelpLink from './collab/HelpLink';
import {Button} from 'react-bootstrap';
import CodeableContextProvider from 'codeable/CodeableContextProvider';
import {withRouter} from 'react-router'

import {
  JOB_STATUS_NOT_STARTED,
  JOB_STATUS_UNDOING,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_ERROR,
  JOB_STOPPING
} from 'jobs/JobConstants';


const LEFT_PADDING = 45;

const BotModal = (props) => {
  const [botExcerpts, setBotExcerpts] = useState([]);
  const [hasCodes, setHasCodes] = useState(true);
  const [codingJob, setCodingJob] = useState(null); 
  const [loading, setLoading] = useState(false);
  const {hasEditPermission} = useContext(UserContext);
  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);

  const gotoUpgrade = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    props.history.push('/users/upgrade');
  }, [props.history]);
  
  const getJobStatus = () => {
    return codingJob && codingJob.status || 'not_started';
  }

  const getError = () => {
    if ( !hasCodes )
      return 'You need to create codes before you can apply them to this transcript using AI.';

    const status = getJobStatus();
    if ( status === JOB_STATUS_ERROR )
      return 'There was an error while coding this transcript. Please try again.'

    if ( errorStatus === 402 ) {
      return <span>
        You have reached the limit of the AI Assistant for your trial. <a onClick={gotoUpgrade}>Upgrade</a> to a paid plan to continue using the AI Assistant.
      </span>
    }

    return error;
  }


  const getStatusText = () => {
    const status = getJobStatus();
    switch(status) {
      case JOB_STATUS_COMPLETED:
        return '✅ Coding complete!';
      case JOB_STATUS_UNDOING:
        return 'Removing codes applied with AI';
      case JOB_STATUS_ERROR:
      case JOB_STATUS_IN_PROGRESS:
      default:
        return 'Apply your existing codes to this transcript using AI.';
    }
  }

  const showStopButton = () => {
    const status = getJobStatus();
    switch (status) {
      case JOB_STATUS_IN_PROGRESS:
      case JOB_STOPPING:
        return true;
      default:
        return false;
    }
  }

  const stopButtonDisabled = () => {
    const status = getJobStatus();
    switch (status) {
      case JOB_STOPPING:
        return true;
      default:
        return false;
    }
  }

  const startAICoding = () => {
    ChatActions.createAICoding(props.transcriptID).catch((error)=>{
      const response = error.response || {};
      const status = response.status;
      const message = (response.data || {}).error || 'There was an error while starting coding. Please restart and try again.';

      setErrorStatus(status);

      if ( status === 429 || status === 402) {
        setError(message);
      } else {
        setError('There was an error while starting coding. Please restart and try again.')
      };
    })
  }

  const onStop = () => {
    ChatActions.stopAICoding(codingJob.id)
  }

  const onUndo = () => {
    ChatActions.destroyAICoding(codingJob['id']).then((data)=>{
      QualCodeActions.loadTranscript(codingJob['transcript_id'])
      setBotExcerpts([]);
    })
  }

  const onHide = () => {
    props.hideModal();
  }

  const _loadState = () => {
    const {excerpts} = CodedTranscriptStore.getBotExcerpts(props.transcriptID);
    setBotExcerpts(excerpts || []);
    const job = JobStore.getJob(props.transcriptID);
    setCodingJob(job);

    const codes = CodeStore.getCodes(props.projectID);
    setHasCodes(!!codes && codes.length > 0);
  }

  const _onChange = () => {
    _loadState();
  }

  useEffect(() => {
    CodedTranscriptStore.addChangeListener(_onChange);
    CodeStore.addChangeListener(_onChange);
    JobStore.addChangeListener(_onChange);
    _loadState();

    // Specify how to clean up after this effect:
    return function cleanup() {
      CodedTranscriptStore.removeChangeListener(_onChange);
      CodeStore.removeChangeListener(_onChange);
      JobStore.removeChangeListener(_onChange);
    };
  }, [props.transcriptID, props.projectID]); // Only run once on mount and clean up on unmount

  useEffect(() => {
    if (props.show) {
      _loadState(props)
      setLoading(true);
      setError(null);
      setErrorStatus(null);
      ChatActions.getAICoding(props.transcriptID).then((data)=>{
        setLoading(false);
      })
    }
  }, [props.show]); // This will run whenever `props.show` changes



  let syncOrAsyncError = getError();
  return (
    <Modal style={props.isModalOpen ? { filter: 'brightness(0.7)' } : {}} bsSize="large" show={props.show} onHide={onHide} animation={false}>
        <Modal.Body style={{padding: 0, margin: 0}}>
          <div style={{padding:`30px ${LEFT_PADDING}px`, borderBottom: "1px solid lightGray"}}>
            <CloseModalButton onHide={onHide}/>
            {
              syncOrAsyncError && 
              <Alert bsStyle="warning">{syncOrAsyncError}</Alert>
            }
            <h3 style={{paddingTop: '0px', marginTop: '0px'}}>Apply codes using AI</h3>
            <p>
              {getStatusText()}
            </p>

            
            <div style={{ marginBottom: '20px', marginTop: '20px', display: 'flex'}}>
              <AICodingButton
                status={getJobStatus()}
                onClick={startAICoding}
                onUndo={onUndo}
                onAutoCode={startAICoding}
                loading={loading}
                disabled={!hasCodes}
              />

              {showStopButton() &&
                <Button style={{marginLeft: '40px', marginTop:5, paddingTop: 0}}
                        bsStyle='link'
                        disabled={stopButtonDisabled()}
                        onClick={onStop}>
                  Stop Applying Codes
                </Button>
              }
            </div>
        
              <HelpLink 
                intercomArticle={process.env['INTERCOM_ARTICLE_AI_CODING']}
              >
                Learn How AI Codes Your Transcripts
              </HelpLink>
          </div>

          <MemoModalProvider>
            <CodeableContextProvider>
              <ExcerptListComponent
                excerpts={botExcerpts || []}
                codePage={false} // is not on the codebook
                projectID={props.projectID} // set this, even though it is not being set
                onLinkClick={onHide}
                onCodeClick={onHide}
                hasEditPermission={hasEditPermission}
                canPage={false}
                showMore={false}
                loading={false}
                headerText="Snippets coded with AI will appear below."
              />
            </CodeableContextProvider>
          </MemoModalProvider>
        </Modal.Body>
    </Modal>
  );
}

export default withRouter(BotModal);