import React from 'react';
import AddFilterDropdownButton from './AddFilterDropdownButton';
import SurveyFilterButton from './SurveyFilterButton';
import SurveyButtonToolbar from './SurveyButtonToolbar';
 
const SurveyFilterBar = ({ filters,
    surveyQuestions,
    questionAdded,
    optionAdded,
    onOptionRemoved,
    onDeleteFilter,
    onClearChecked
}) => {
    return (
        <SurveyButtonToolbar>
            {filters.map((filter, index) => (
                <SurveyFilterButton
                    key={index}
                    question={surveyQuestions.find((question) => question.id === filter.question_id)}
                    option_ids={filter.option_ids}
                    optionAdded={optionAdded}
                    onOptionRemoved={onOptionRemoved}
                    onDeleteFilter={onDeleteFilter}
                    onClearChecked={onClearChecked}
                />
            ))}
            <AddFilterDropdownButton
                questions={surveyQuestions}
                questionAdded={questionAdded}
                disabledQuestionIds={filters.map((filter) => filter.question_id)}
            />
        </SurveyButtonToolbar>
    );
};

export default SurveyFilterBar;