// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import UserSquare from './UserSquare'
import GlypiconParagraph from 'paragraph/GlyphiconParagraph';

function SpeakerComponent({
  initials,
  color,
  onHover,
  onClick,
  logo
}) {
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => onHover && onHover(true)}
      onMouseLeave={() => onHover && onHover(false)}
      className='speaker-component'
    >
      {!logo ?
        <UserSquare
          initials={initials}
          color={color}
        />
        : 
        <GlypiconParagraph
          glyph={logo}
          color={color}
        />
      }
      
    </div>
  );
}

export default SpeakerComponent;
