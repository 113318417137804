import React, { useContext } from 'react';
import CodeableContext from 'contexts/CodeableContext';
import CodeContainer from 'projects/components/CodeContainer';

function ChatCodeContainer({ projectID, height }) {
    const {
        selectedExcerptId,
        selectedExcerptVisible
    } = useContext(CodeableContext);

    return <CodeContainer
        projectID={projectID}
        height={height}
        disabled={!selectedExcerptId || !selectedExcerptVisible}
    />
}

export default ChatCodeContainer;