import React from "react";
import {Link} from 'react-router-dom'
import SurveyTableCell from './SurveyTableCell'


const SurveyTableRow = ({ surveyQuestions, response, index, onOptionClicked, onQuestionClicked }) => {
    return (<tr
            key={response.id}
            style={{
              backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
            }}
          >
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              <Link to={`/survey_responses/${response.id}`}>
                {response.response_text}
              </Link>
            </td>
            {/* Map through answers and match them to the question */}
            {surveyQuestions.map((surveyQuestion) => {
              const questionId = surveyQuestion.id;
              const answer = response.answers.find(
                (a) => a.question_id === parseInt(questionId)
              );

              return <SurveyTableCell
                        key={questionId} 
                        answer={answer}
                        question={surveyQuestion}
                        optionClicked={onOptionClicked}
                        onQuestionClicked={onQuestionClicked}
                        />;
            })}
          </tr>)
}

export default SurveyTableRow;