import React from "react";
import { OverlayTrigger } from "react-bootstrap";

const ConditionalOverlayTrigger = ({ body, minChars, tooltip, children }) => {
    return body.length >= minChars ? (
        <OverlayTrigger delayShow={500} delayHide={0} placement="bottom" overlay={tooltip}>
            {children}
        </OverlayTrigger>
    ) : (
        <>{children}</>
    );
};

export default ConditionalOverlayTrigger;
