// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {MenuItem} from 'react-bootstrap'
import EditableName from '../EditableName'
import BotModal from '../BotModal'
import DeleteTranscriptModal from '../modal/DeleteTranscriptModal';
import SubscriptionHeaderFrame from './SubscriptionHeaderFrame';


class TranscriptHeader extends React.Component {
  render () {
    const aiMenuItems = !!process.env['AI_FEATURE_FLAG'] ? [
      {
        title: 'Apply Codes Using AI',
        onClick: this.props.aiCodings
      }
    ] : []

    const menuItemObjects = [
      ...aiMenuItems,
      {
        title: 'Export Transcript (.txt)',
        onClick: this.props.exportTranscript
      },
      {
        title: 'Export Transcript with Codes (.pdf)',
        onClick: this.props.printTranscript
      },
      {
        title: 'Delete Transcript',
        onClick: this.props.deleteModalClicked
      }
    ]

    const menuItems = menuItemObjects.map((item, i) => {
      return (
        <MenuItem
          key={i}
          eventKey={i}
          onClick={item.onClick}
        >
          {item.title}
        </MenuItem>
      )
    })

    return (
      <div>
        <SubscriptionHeaderFrame
          dropdownOptions={menuItems}
          projectId={this.props.projectID}
          hasEditPermission={this.props.hasEditPermission}
          subscriptionState={this.props.subscriptionState}
          onShowCollabModal={this.props.onShowCollabModal}
          showCollabModal={this.props.showCollabModal}
          name={this.props.title || 'Untitled'}          
          isActive={this.props.isActive}
          onRenew={this.props.onRenew}
          roleIsEdit={this.props.roleIsEdit}
        >
          <EditableName
              title={this.props.title}
              onSave={(title) => this.props.onTitleChange(title)}
              placeholder="Untitled"
          />
        </SubscriptionHeaderFrame>
        <DeleteTranscriptModal
          showModal={this.props.showModal}
          cancelDelete={this.props.cancelDelete}
          confirmDelete={this.props.confirmDelete}
        />
        <BotModal 
          transcriptID={this.props.transcriptID}
          show={this.props.showDeductiveModal}
          hideModal={this.props.hideAiCodings}
          projectID={this.props.projectID}
        />
      </div>
    );
  }
}

export default TranscriptHeader;
