// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import AnalysisExportModal from '../analysis/AnalysisExportModal'
import {MenuItem} from 'react-bootstrap'
import DropdownHeader from './DropdownHeader'
import matchToProjectID from 'utils/MatchToProjectID'

class AnalysisHeader extends React.Component {
  constructor () {
    super();
    this.showExportModal = this.showExportModal.bind(this);
    this.hideExportModal = this.hideExportModal.bind(this);
    this.state = {
      showExportModal: false
    }
  }

  hideExportModal () {
    this.setState({
      showExportModal: false
    })
  }

  showExportModal () {
    this.setState({
      showExportModal: true
    })
  }

  render () {
    const dropdownOptions = [
      <MenuItem eventKey="1" onClick={this.showExportModal}>
              Export Options
      </MenuItem>
    ];
  
    const projectId = matchToProjectID(this.props.match);
    return (
      <div>
        <AnalysisExportModal
          show={this.state.showExportModal}
          projectId={projectId}
          onHide={this.hideExportModal}
        />
        <DropdownHeader
          dropdownOptions={dropdownOptions}
          projectId={projectId}
          hasEditPermission={this.props.hasEditPermission}
          subscriptionState={this.props.subscriptionState}
          onShowCollabModal={this.props.onShowCollabModal}
          showCollabModal={this.props.showCollabModal}
        >
          Snippets
        </DropdownHeader>
      </div>
    )
  }
}

export default AnalysisHeader;
