// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal} from 'react-bootstrap'
import TranscriptPillLink from './TranscriptPillLink'
import Textarea from 'react-textarea-autosize';
import ColorConstants from 'constants/ColorConstants'
import EditableName from './EditableName'
import CloseModalButton from './CloseModalButton'
import ExcerptListComponent from './ExcerptListComponent'
import CodeableContextProvider from 'codeable/CodeableContextProvider'

const LEFT_PADDING = 45;
const CHANGE_COUNT_AUTO_SAVE = 100;

class CodePageModal extends React.Component {
  constructor () {
    super();
    this.state = {
      textAreaFocused: false,
      synthesisText: null,
    };
    this.onHide = this.onHide.bind(this);
    this.onTextAreaFocus = this.onTextAreaFocus.bind(this);
    this.onTextAreaBlur = this.onTextAreaBlur.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onTranscriptClick = this.onTranscriptClick.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onSynthesisChange = this.onSynthesisChange.bind(this);
    this.textarea = null;
    this.mouseDownState = false;
    this.count = 1;
    this._getCodeDescription = this._getCodeDescription.bind(this);
  }

  onSynthesisChange(event){
    this.setState({synthesisText: event.target.value})

    this.count += 1;
    if ( this.count % CHANGE_COUNT_AUTO_SAVE == 0)
      this.props.saveText(event.target.value);
  }

  onMouseDown (e)
  {
    this.mouseDownState = this.state.textAreaFocused;
  }

  onMouseUp (e)
  {
    if ( !this.mouseDownState )
    {
      if ( this.textarea )
        this.textarea.focus();
    }
  }

  onTextAreaFocus (e)
  {
    this.setState({
        textAreaFocused: true,
        synthesisText: this.props.code.synthesis,
    });
  }

  onTextAreaBlur (e)
  {
    this.setState({
        textAreaFocused: false,
    });

    this.props.saveText(this.state.synthesisText);
  }

  onHide (e)
  {
    this.props.hideModal();
  }

  onLinkClick(excerptID)
  {
    this.props.hideModal();
  }

  onTranscriptClick(e)
  {
    this.props.hideModal();
  }

  _getCodeDescription() {
    const descriptionStyle = {  width: "100%",
              resize:'none',
              padding:'20px 30px',
              borderColor:"#d7d7d7",
              borderRadius: "5px",
              outline: "none",
              fontSize:'1.25em',
          }

    const text = this.state.textAreaFocused ? this.state.synthesisText : this.props.code.synthesis;

    if ( this.props.hasEditPermission ) {
      return (
        <div style={{marginTop: 40, width: "100%"}}>
          <Textarea placeholder="Write a description or thoughts about this code"
                    onChange={this.onSynthesisChange}
                    onFocus={this.onTextAreaFocus}
                    onBlur={this.onTextAreaBlur}
                    inputRef={tag => (this.textarea = tag)}
                    value={text ? text : ""}
                    style={descriptionStyle}
          />
          <div  onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                style={{textDecoration:'none', float:'right', cursor:'pointer', fontSize: '.8em'}}>
                {this.state.textAreaFocused ? "Done" : "Edit"}
          </div>
          <div style={{clear: 'both'}}/>
        </div>
      )
    } else if ( !!text ) {
      return (
        <div style={{marginTop: 40, width: "100%"}}>
          <div style={{
                    ...descriptionStyle,
                    borderStyle: 'solid',
                    borderWidth: '1px'
                }}>
                {text}
          </div>
          <div style={{clear: 'both'}}/>
      </div>)
    } else {
      return null;
    }
  }

  render () {
    const transcripts = this.props.transcripts.map((transcript,index)=>{
      return(
        <TranscriptPillLink
          key={index}
          onClick={this.onTranscriptClick}
          id={transcript.id}
          name={transcript.name}
          count={transcript.count}
        />);
    });

    const userName = (this.props.user || {}).name;

    return (
      <Modal style={this.props.isModalOpen ? { filter: 'brightness(0.7)' } : {}} bsSize="large" show={this.props.show} onHide={this.onHide} animation={false}>
          <Modal.Body style={{padding: 0, margin: 0}}>
            <div style={{padding:`30px ${LEFT_PADDING}px`, borderBottom: "1px solid lightGray",}}>
              <CloseModalButton onHide={this.onHide}/>
              <h4>
                {this.props.hasEditPermission ?
                  <EditableName
                    title={this.props.code.name}
                    onSave={this.props.saveCodeName}
                    blurredPostfix={this.props.code && this.props.code.count ? ` (${this.props.code.count})` : ''}
                  /> : <span style={{fontSize:"1.5em"}}>
                      {`${this.props.code.name} (${this.props.code.count})`}
                    </span>
                }
              </h4>

              <div style={{clear:'both'}}/>

              <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}>
                <div style={{
                  cursor:'default',
                  width: "50%"
                }}>
                  Appears in {this.props.transcripts.length}/{this.props.transcriptCount} transcripts
                </div>

                <div style={{
                  cursor:'default',
                  width: "50%",
                  textAlign: "right",
                  color: ColorConstants.LIGHT_GREY
                }}>
                  {userName &&
                    <em>Code added by {userName}</em>
                  }
                </div>
              </div>

              <div style={{width:"100%", wordWrap: 'break-word'}}>
                {transcripts}
              </div>

              {this._getCodeDescription()}
            </div>
            <CodeableContextProvider
              projectId={this.props.projectID}
              includeAllUsers={true}
            >
              <ExcerptListComponent
                excerpts={this.props.excerpts}
                codePage={this.props.codePage}
                projectID={this.props.projectID}
                onLinkClick={this.onLinkClick}
                hasEditPermission={this.props.hasEditPermission}
                canPage={this.props.canPage}
                showMore={this.props.showMore}
                loading={this.props.loading}
              />
            </CodeableContextProvider>
          </Modal.Body>
      </Modal>
    );
  }
}

export default CodePageModal;
