// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import UserAvatarBase from './UserAvatarBase'

function UserSquare(props) {
  const CIRCLE_SIZE = 30;

  return <UserAvatarBase
      size={CIRCLE_SIZE}
      borderRadius="20%"
      fontSize="15px"
      marginTop={0}
      initials={props.initials}
      color={props.color}
    />
}

export default UserSquare;
