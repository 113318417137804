// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback} from 'react';
import urlUtil from 'utils/UrlUtil';
import LeftSideLink from 'left_side_bar/LeftSideLink';

function TranscriptLink({
  page,
  transcriptID,
  location,
  selected,
  children,
  onLinkClick,
}) {

  const onClick = useCallback(() => {
    const link = urlUtil.getTranscriptUrl(
      page,
      {
        transcriptID: transcriptID,
        location: location,
      }
    );

    onLinkClick(link);
  }, [page, transcriptID, location, onLinkClick]);

  return (<LeftSideLink
    selected={selected}
    className="transcriptLink"
    onClick={onClick}
  >
    {children}
  </LeftSideLink>)
}

export default TranscriptLink;
