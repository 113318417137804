import React, { useState, useCallback } from 'react';

import FileDropZone from 'projects/components/FileDropZone';
import { withToastManager } from 'react-toast-notifications';
import SurveyAPI from 'apis/SurveyAPI';
import DragDropSurveyLayout from './DragDropSurveyLayout';
import FileDropzoneSquare from './FileDropzoneSquare';

function DragDropSurveyContainer({ projectID, height, onboardingState, history, toastManager }) {
  const [isHover, setIsHover] = useState(false);
  
  const MAX_FILE_SIZE_MB = parseInt(process.env.MAX_CSV_FILE_SIZE, 10) || 5;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const onDrop = useCallback((acceptedFiles) => {
    setIsHover(false);

    if (acceptedFiles.length > 1) {
      toastManager.add("Please upload one csv at a time.", { appearance: 'error', autoDismiss: true });
      return;  
    }

    const file = acceptedFiles[0];

    // check if file is a csv
    if (file.type !== 'text/csv') {
      toastManager.add("Please upload a csv file.", { appearance: 'error', autoDismiss: true });
      return;
    }

    // check if file size is within the limit
    if (file.size > MAX_FILE_SIZE_BYTES) {
      toastManager.add(`File size should be less than ${MAX_FILE_SIZE_MB}MB.`, { appearance: 'error', autoDismiss: true });
      return;
    }

    return SurveyAPI.uploadCSVFile(projectID, file).then((data) => {
      history.push(`/projects/${projectID}/surveys/new/${data.id}`);
    }).catch((error) => {
      const errorMessage = error.response?.data?.errors?.[0] || `Upload failed with error: ${error.message}`;
      toastManager.add(errorMessage, { appearance: 'error', autoDismiss: true });
    });

    //TranscriptActions.uploadManyTranscripts(projectID, acceptedFiles);
  }, [projectID, history, toastManager]);

  const isHovering = useCallback((hoverState) => {
    setIsHover(hoverState);
  }, []);

  return (
    <DragDropSurveyLayout
      height={height}
    >
      <FileDropZone onDrop={onDrop} isHovering={isHovering}>
        <FileDropzoneSquare
          isHover={isHover}
          hoverText={'Drop .csv file here'}
          nonHoverText={'Drag and Drop a .csv file'}  
        />
      </FileDropZone>
    </DragDropSurveyLayout>
  );
}

export default withToastManager(DragDropSurveyContainer);
