// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback} from 'react';
import LeftSideLink from 'left_side_bar/LeftSideLink';

function SurveyLink({
  surveyId,
  selected,
  children,
  onLinkClick
}) {
  
  const onClick = useCallback(() => {
    const link = `/surveys/${surveyId}`;
    onLinkClick(link);
  }, [surveyId, onLinkClick]);



  return (<LeftSideLink
    onClick={onClick}
    selected={selected}
    className="surveyLink"
  >
    {children}
  </LeftSideLink>)
}

export default SurveyLink;
