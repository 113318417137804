// app/javascript/projects/components/AnalysisDisplayContainer.jsx

import React, {useContext, useEffect} from 'react';

// Stores
import FilterStore from 'stores/FilterStore'
import AnalysisStore from 'stores/AnalysisStore'
import ProjectsStore from 'stores/ProjectsStore'
import CodeStore from 'stores/CodeStore'
import DescriptorStore from 'stores/DescriptorStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'

import AnalysisDisplay from './AnalysisDisplay'
import {MemoModalProvider} from './modal/MemoModalProvider';

import TranscriptCodePageContainer from './TranscriptCodePageContainer'

import CodeableContext from 'contexts/CodeableContext';

function AnalysisDisplayWrapper(props) {
  const {
    onExcerptSelected,
    onDeselect,
    selectedExcerptId,
    setSelectedExcerptVisible,
  } = useContext(CodeableContext);

  useEffect(() => {
    onDeselect();
  }, [props.projectID]);
 
  return (
    <AnalysisDisplayContainer
      {...props}
      onExcerptSelected={onExcerptSelected}
      onDeselect={onDeselect}
      selectedExcerptId={selectedExcerptId}
      setSelectedExcerptVisible={setSelectedExcerptVisible}
    />
  );
}


class AnalysisDisplayContainer extends React.Component {
  constructor () {
    super();

    const {excerpts, loadingState} = AnalysisStore.getFilteredExcerpts(null);

    this.state = {
      excerpts,
      loadingState
    }

    this._onChange = this._onChange.bind(this);
    this.onExcerptClicked = this.onExcerptClicked.bind(this);
    this._getNewState = this._getNewState.bind(this);
  }

  onExcerptClicked(excerptId) {
    this.props.onExcerptSelected(excerptId)
  }

  // static
  _getNewState (projectID) {
    if ( !projectID ) return {};

    const {excerpts, loadingState} = AnalysisStore.getFilteredExcerpts(projectID);

    return {
      excerpts,
      loadingState
    }
  }

  _onChange () {
    const newState = this._getNewState(this.props.projectID);
    this.setState(newState);

    if ( !!this.props.selectedExcerptId ) {
      // check if the selected excerpt is still in the list
      const selectedExcerpt = (newState?.excerpts || []).find(excerpt => String(excerpt?.id) === String(this.props.selectedExcerptId));
      this.props.setSelectedExcerptVisible(!!selectedExcerpt);
    }
  }

  componentDidMount () {
    AnalysisStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    DescriptorStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
    CodedTranscriptStore.addChangeListener(this._onChange);
    FilterStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AnalysisStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
    DescriptorStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
    CodedTranscriptStore.removeChangeListener(this._onChange);
    FilterStore.removeChangeListener(this._onChange);
  }

  render () {
    return (
      <div>
        <MemoModalProvider>
          <TranscriptCodePageContainer
            projectID={this.props.projectID}
            hasEditPermission={this.props.hasEditPermission}
          />
          <AnalysisDisplay
            checkedCodeNames={this.state.checkedCodeNames}
            loadingState={this.state.loadingState}
            excerpts={this.state.excerpts}
            selectedExcerptID={this.props.selectedExcerptId}
            projectID={this.props.projectID}
            height={this.props.height}
            onboardingState={this.props.onboardingState}
            hasEditPermission={this.props.hasEditPermission}
            onExcerptClicked={this.onExcerptClicked}
            onDeselect={this.props.onDeselect}
            />
        </MemoModalProvider>
      </div>

    );
  }
}

export default AnalysisDisplayWrapper;
