import React, {useState, useEffect, useContext, useCallback} from 'react';
import SurveyAPI from 'apis/SurveyAPI';
import ProjectContext from 'contexts/ProjectContext';
import SurveyPageWrapper from './SurveyPageWrapper';
import SurveyResponseQuestion from './SurveyResponseQuestion';
import CodeableContext from 'contexts/CodeableContext';
import useHighlightAnswer from './useHighlightAnswer';
import {getAnswerSelect} from './surveySelectUtils';
import useSurveyExcerpts from './useSurveyExcerpts';
import CodeStore from 'stores/CodeStore';
import SurveyPagePageBar from './SurveyPagePageBar';
import queryString from 'query-string';
import SurveyQuestionNotFound from './SurveyQuestionNotFound';

import {
  BLUE,
  ACTIVE_BUTTON_BLUE,
} from 'constants/ColorConstants'

const SurveyQuestionPage = ({
  history,
  match,
  location,
  projectID,
  hasEditPermission,
}) => {
  const [surveyQuestion, setSurveyQuestion] = useState({});
  const [surveyRows, setSurveyRows] = useState([]);
  const [pageNext, setPageNext] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const { handleMouseDown, isAnswerDisabled } = useHighlightAnswer();

  const {
    selectedExcerptId,
    select,
    onExcerptSelected,
    onDeselect,
    onSelectText
  } = useContext(CodeableContext);

  const surveyQuestionID = match.params.surveyQuestionID;
  const parsed = queryString.parse(location.search);
  const offset = parseInt(parsed.offset) || 0;
  const projectContext = useContext(ProjectContext);
  const {setProjectID} = projectContext;

  const gotoResponse = useCallback((responseId) => {
    history.push(`/survey_responses/${responseId}`);
  }, [history]);

  const getExcerpts = useCallback(() => {
    if ( !surveyQuestionID ) return [];

    const excerpts = CodeStore.getSurveyQuestionExcerpts(surveyQuestionID);
    return excerpts;
  }, [surveyQuestionID]);

  const {getResponseExcerpts} = useSurveyExcerpts(getExcerpts);

  useEffect(() => {
    if (!surveyQuestionID)
      return;

    SurveyAPI.getSurveyQuestionExcerpts(surveyQuestionID)
    .catch((error) => {
      console.log(error);
    })
  }, [surveyQuestionID]);
  
  useEffect(() => {
    if (!surveyQuestionID) {
      return;
    }

    setLoading(true);

    onDeselect();

    SurveyAPI.getSurveyQuestionResponses(surveyQuestionID, offset)
      .then((response) => {
        const data = response?.data || {};
        setSurveyQuestion(data);
        setSurveyRows(data.survey_rows || []);
        setPageNext(data.next);
        setProjectID(data.project_id);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.status === 404 || error?.status === 403) {
          setNotFound(true);
        }
        console.log(error);
        setLoading(false);
      });
  }, [surveyQuestionID, offset]);

  if (notFound) {
    return <SurveyQuestionNotFound />;
  }

  const onGoNext = () => {
    history.push(`/survey_questions/${surveyQuestionID}?offset=${pageNext}`);
  }

  const onGoPrevious = () => {
    const newOffset = Math.max(0, offset - 100);
    history.push(`/survey_questions/${surveyQuestionID}?offset=${newOffset}`);
  }

  return (
    <SurveyPageWrapper
      projectID={projectID}
      hasEditPermission={hasEditPermission}
      title={surveyQuestion?.question_text}
    >
      {
        surveyRows.map((surveyRow, index) => {
          const surveyAnswer = surveyRow.answer;
          
          if (!surveyAnswer || !surveyAnswer.body)
            return null;

          return (
            <div
              key={surveyRow.id}
            >
              <SurveyResponseQuestion
                responseId={surveyRow.id}
                surveyAnswer={surveyAnswer}
                questionType={"text"}
                questionId={surveyQuestion?.id}
                questionText={surveyRow.response_text}
                onGotoResponse={gotoResponse}
                color={index % 2 === 0 ? BLUE : ACTIVE_BUTTON_BLUE}
                onSelectText={onSelectText}
                onMouseDown={handleMouseDown}
                disabled={isAnswerDisabled(surveyRow.answer?.id)}
                select={getAnswerSelect(select, surveyRow.answer?.id)}
                excerpts={getResponseExcerpts(surveyRow.id)}
                selectedExcerptId={selectedExcerptId}
                onExcerptSelected={onExcerptSelected}
                onDeselect={onDeselect}
                hasEditPermission={hasEditPermission}
              />

              <div
                style={{
                  marginBottom: "20px",
                  borderBottom: "1px solid #e0e0e0",
                  marginLeft: "20px",
                  marginRight: "20px"
              }}
              />
            </div>
          );
        }).filter(Boolean)
      }

      <div
        style={{
          marginLeft: "15px",
          marginBottom: "15px"
        }}>
          <SurveyPagePageBar
            onGoPrevious={onGoPrevious}
            onGoNext={onGoNext}
            pageNext={pageNext}
            offset={offset}
            disabled={loading}
          />
      </div>
    </SurveyPageWrapper>
  );
};

export default SurveyQuestionPage;