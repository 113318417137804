// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import DropdownHeader from './DropdownHeader'
import {MenuItem} from 'react-bootstrap'
import ExportActions from 'actions/ExportActions'
import matchToProjectID from 'utils/MatchToProjectID'

class CodesHeader extends React.Component {
  constructor () {
    super();
    this.exportCodeBook = this.exportCodeBook.bind(this);
    this.exportCodeBookCSV = this.exportCodeBookCSV.bind(this);
  }

  exportCodeBook () {
    const projectId = matchToProjectID(this.props.match);
    if ( !projectId) return null;
    ExportActions.exportToWord(projectId);
  }

  exportCodeBookCSV () {
    const projectId = matchToProjectID(this.props.match);
    if ( !projectId) return null;
    ExportActions.exportCodesToCSV(projectId);
  }

  render () {
    const projectId = matchToProjectID(this.props.match);

    const dropdownOptions = [
      <MenuItem eventKey="1" onClick={this.exportCodeBook}>
        Export Codes as DOC
      </MenuItem>,
      <MenuItem eventKey="2" onClick={this.exportCodeBookCSV}>
        Export Codes as CSV
      </MenuItem>
    ]

    return (
      <div>
        <DropdownHeader
          projectId={projectId}
          dropdownOptions={dropdownOptions}
          hasEditPermission={this.props.hasEditPermission}
          subscriptionState={this.props.subscriptionState}
          isActive={this.props.isActive}
          roleIsEdit={this.props.roleIsEdit}
          onRenew={this.props.onRenew}
          onShowCollabModal={this.props.onShowCollabModal}
          showCollabModal={this.props.showCollabModal}  
        >
          Codes
        </DropdownHeader>
      </div>
    )
  }
}

export default CodesHeader;
