// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Link} from 'react-router-dom'

import ColorConstants from 'constants/ColorConstants'
import { getRelativeExcerptUrl } from '../mappers/ExcerptUrlMapper';

const ExcerptTitle = ({excerpt, onLinkClick}) => {
  return (<div style={{fontSize:"0.8em"}}>
    <div>
      <Link onClick={onLinkClick} to={`/surveys/${excerpt.survey_id}`}>
        {excerpt.survey_title} >
      </Link> 

      <Link onClick={onLinkClick} to={getRelativeExcerptUrl(excerpt)}>
        {excerpt.survey_question_text}
      </Link>
    </div>

    <Link onClick={onLinkClick} to={getRelativeExcerptUrl(excerpt)}>
      <span style={{
        color:ColorConstants.ACTIVE_BUTTON_BLUE,
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '90%'
      }}>
        <strong>
          {excerpt.survey_row_text}  
        </strong>
      </span>
    </Link>

  </div>)
}

export default ExcerptTitle;

