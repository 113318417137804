import React from "react";
import ColorConstants from "constants/ColorConstants";


const SurveyOption = ({ body, optionClicked }) => {
    return <span
        className="survey-option"
        style={{
            maxWidth: "100%", // Set the width to 100%
            border: "1px solid",
            borderRadius: "5px", // Add rounded corners
            borderColor: ColorConstants.LIGHT_GREY,
            padding: "2px 5px", // Add some padding inside the border
            display: "inline-block", // Ensure the span is inline-block
            whiteSpace: "nowrap", // Prevent wrapping
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis", // Apply ellipsis
            cursor: "pointer" // Add a pointer on hover       
        }}
        onClick={optionClicked}
    >
        {body}
    </span>
}

export default SurveyOption;