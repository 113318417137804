import fileParserBuilder from './FileParser/FileParserBuilder'
import ErrorUtil from 'utils/ErrorUtil'

import {
  FILES__TYPE_UNSUPPORTED,
  FILES__COUNT_LIMIT
} from 'constants/ErrorConstants'

const FILE_COUNT_LIMIT = 200;

const _checkFile = (fileName) => {
  const SUPPORTED_FILE_TYPES = [
    '.txt',
    '.docx',
    !!process.env.PDF_IMPORT_FLAG ? '.pdf' : null,
  ].filter((item)=>!!item)

  if ( !fileName ) return false;
  const fileNameLower = fileName.toLowerCase();
  return SUPPORTED_FILE_TYPES.some((fileType)=>fileNameLower.endsWith(fileType));
}

function fileTypesCopy(conjunction) {
  return process.env.PDF_IMPORT_FLAG ?
            `.txt, .pdf, ${conjunction} .docx` : `.txt ${conjunction} .docx`;
}

const FileUtil = {
  fileTypesAndCopy: () => {
    return fileTypesCopy('and')
  },
  fileTypesOrCopy: () => {
    return fileTypesCopy('or')
  },
  checkFiles: (files) =>{
    if ( files.length > FILE_COUNT_LIMIT )
      throw ErrorUtil.createError(FILES__COUNT_LIMIT);

    const allText = files.map((file)=>(file.name || 'file.other')
      .toLowerCase()).every(_checkFile);

    if ( !allText )
      throw ErrorUtil.createError(FILES__TYPE_UNSUPPORTED);

    return true;
  },
  upload: async (file) =>{
    const fileParser = fileParserBuilder(file);
    return fileParser.upload();
  }
}

export default FileUtil;
