// app/javascript/projects/components/ProjectsDisplay.jsx

import { Scrollbars } from 'react-custom-scrollbars';
import React, {useRef, useCallback, useEffect, useState} from 'react';

import PageLoadingComponent from './PageLoadingComponent';
import TranscriptBody from './TranscriptBody';
import LocalAPI from 'utils/LocalAPI'
import PagerWrapper from './PagerWrapper';

function TranscriptScrollbar ({
  codedTranscript,
  pageCount,
  transcriptID,
  hasEditPermission,
  page,
  scrollToParagraphIndex,
  shouldGotoOtherPage,
  waitToScroll, // basically means that the scrollToParagraphIndex is loading and we should wait for it to finish,
  onDeselect
}) {
  // setup ref for the scrollbar
  const scrollbar = useRef(null);
  const paragraphRefs = useRef({});
  //const scrollbarHeight = scrollbar.current ? scrollbar.current.getScrollHeight() : 0;
  const [contentLoaded, setContentLoaded] = useState(true);
  const hasScrolled = useRef(false);

  useEffect (() => {
    if ( codedTranscript && codedTranscript.paragraphs.length > 0 ) {
      setContentLoaded(true);
    } else { // if the paragraph length goes to 0 then reset the content loaded
      setContentLoaded(false);
      hasScrolled.current = false;
    }
  }, [codedTranscript, transcriptID]);

  useEffect(() => {
    hasScrolled.current = false;
  }, [transcriptID, page, scrollToParagraphIndex]);

  useEffect(() => {
    // Scroll only if content is loaded and a scroll target is set
    if (!contentLoaded || hasScrolled.current) {
      return;
    }

    if ( scrollToParagraphIndex != undefined ) {
      const paragraphDiv = paragraphRefs.current[scrollToParagraphIndex];
      if ( !paragraphDiv ) return;

      const y = paragraphDiv.offsetTop;
      if ( y ) {
        scrollbar.current.scrollTop(y);
        hasScrolled.current = true;
      }
    } else if ( !waitToScroll ) {
      const {loc, page: saved_page} = LocalAPI.getTranscriptLocation(transcriptID) || {};

      if ( !loc ) return;

      // if the saved_page is different from the current page
      // note a null page is considered as 1
      if ( (saved_page || '1') != (page || '1') ) {
        shouldGotoOtherPage(saved_page);
        return
      }

      scrollbar.current.scrollTop(loc);
      hasScrolled.current = true;
    }
  }, [contentLoaded, scrollToParagraphIndex, transcriptID, page]);
    
  const setParagraphRef = useCallback((index, pc) => {
    paragraphRefs.current[index] = pc;
  }, []);

  const onPage = useCallback((page) => {
    if ( scrollbar.current ) {
      scrollbar.current.scrollTop(0);
      LocalAPI.setTranscriptLocation(transcriptID, 1, page);
      onDeselect();
    }
  }, [transcriptID]);

  const handleScrollStop = useCallback(() => {
    if ( transcriptID ) {
      const scrollTop = scrollbar.current.getScrollTop();
      if ( !scrollTop ) return;

      LocalAPI.setTranscriptLocation(transcriptID, scrollTop, page || 1);
    }
  }, [transcriptID, page]);

  return (
    <div style={{height:"100%"}} className='transcript-scrollbar'>
      <Scrollbars
        autoHide
        style={{height:"100%"}}
        ref={scrollbar}
        onScrollStop={handleScrollStop}
      >
      {
        !codedTranscript || codedTranscript.paragraphs.length === 0 ?
          <PageLoadingComponent/>
        :
        <PagerWrapper pageCount={pageCount} onPage={onPage}>
          <TranscriptBody
            setParagraphRef={setParagraphRef}
            transcriptID={transcriptID}
            codedTranscript={codedTranscript}
            hasEditPermission={hasEditPermission}
          />
        </PagerWrapper>
      }
      </Scrollbars>
    </div>
  );
}

export default TranscriptScrollbar;