// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

class Header extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
        <div style={{fontSize:"1.5em", maxWidth: "100%"}}>
          {this.props.children}
        </div>
    );
  }
}

export default Header;
