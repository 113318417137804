import { useState, useContext, useEffect } from 'react';
import UserContext from 'contexts/UserContext';

// include all users means that we are on the codepage
// so we should ignore that we are on the /user/me page
const useResolvedUserId = (location, includeAllUsers) => {
  const [codingUserId, setCodingUserId] = useState(null);
  const { user } = useContext(UserContext);

  // Callback to set the placeholder user ID when new IDs are passed
  useEffect(() => {
    // check if we are on the /user/me page
    if (location?.pathname.includes('user/me') && !includeAllUsers) {
      if ( user?.id )
        setCodingUserId(user.id);
    } else {
      setCodingUserId(null);
    }
      
  }, [location?.pathname, user?.id, includeAllUsers]);

  return { codingUserId };
};

export default useResolvedUserId;
