// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CodingConstants from 'constants/CodingConstants'
import ColorConstants from 'constants/ColorConstants'

const PADDING_PX = "8";

const createStyle = (color) => {
  return {
    boxShadow: `0 ${PADDING_PX}px 0 0 ${color}, 0 -${PADDING_PX}px 0 0 ${color}`,
    backgroundColor: color
  }
}

const HIGHLIGHT_STYLE = createStyle(ColorConstants.HIGHLIGHT_COLOR);
const HOVER_STYLE = createStyle(ColorConstants.HOVER_COLOR);

const CODED_STYLE = {borderBottom: "2px solid " + ColorConstants.UNDERLINE_COLOR};
const SUPER_CODED_STYLE = {borderBottom: "2px solid " + ColorConstants.SUPER_UNDERLINE_COLOR};

class SentenceComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      mouseInside: false
    }

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }


  onMouseEnter(event)
  {
    if ( this.props.isPrint || !this.props.hasEditPermission ) return;
    this.props.onHover(this._getExcerptId())
    this.setState({mouseInside: true});
  }

  onMouseLeave(event)
  {
    if ( this.props.isPrint || !this.props.hasEditPermission ) return;
    this.props.onHover(null)
    this.setState({mouseInside: false});
  }

  _getExcerptId() {
    return (this.props.codedSentence || {}).excerptId || null;
  }

  _containsExcerptId(excerptId) {
    if ( !excerptId ) return false;
    return ((this.props.codedSentence || {}).excerptIds || []).includes(excerptId);
  }

  render () {
    var sentenceElements = []

    // When you add highlighting you might need this
    if ( this.props.codedSentence )
    {
      sentenceElements = this.props.codedSentence.selectElements.map((selectElement, index) => {
          const subElements = selectElement.elements.map((subElement, subIndex) => {
            var style = {};
            if ( subElement.style == CodingConstants.CODED)
              style = CODED_STYLE;
            else if ( subElement.style == CodingConstants.SUPERCODED)
              style = SUPER_CODED_STYLE;

            return <span
                        code-index={subIndex}
                        className="codeSpan"
                        start={subElement.start}
                        end={subElement.end}
                        key={subIndex}
                        style={style}>
                          {subElement.text}
                        </span>
          });


          if ( selectElement.style == CodingConstants.SELECTED )
          {
            return <span highlight-index={index} className="highlightSpan" key={index}
                                  style={HIGHLIGHT_STYLE}
                                  onMouseLeave={this.onMouseLeave}>{subElements}</span>
          }
          else {
            const isCurrentExcerpt = this._containsExcerptId(this.props.hoverExcerptId);
            const style = (this.state.mouseInside || this.props.paragraphHover || isCurrentExcerpt) ?
                            HOVER_STYLE : {};

            return <span highlight-index={index} className="highlightSpan" key={index}
                                 style={style}
                                  onMouseEnter={this.onMouseEnter}
                                  onMouseLeave={this.onMouseLeave}>{subElements}</span>
          }
        }
      );
    }

    return (
      <span sentence-index={this.props.index}
            excerpt-id={this._getExcerptId()}
            className="sentenceSpan"
            style={ this.props.hasEditPermission ? {cursor:"pointer"} : {cursor:"default"}}
          >
        {sentenceElements}
      </span>
    );
  }
}

export default SentenceComponent;
