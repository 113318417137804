// app/javascript/projects/components/NewTranscript.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import ClipLoader from 'react-spinners/ClipLoader';

function HeaderAndSubheader({children, subheader, style, loading}) {
  return (
    <div style={style}>
      <p>
        {children}
      </p>
      <p>
        {subheader}
      </p>
      {loading && <ClipLoader
              color={ColorConstants.ACTIVE_BUTTON_BLUE}
              sizeUnit={"px"}
              size={19}
              loading={loading}
      />}
    </div>
  );
}


function FileDropzoneText({isHover, header, subheader, loading}) {
  return (
    <div style={{
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "2.0em",
      width: "100%",
      color: ColorConstants.LIGHT_GREY,
      fontFamily: "Libre Baskerville, serif"
    }}>
      <HeaderAndSubheader
        subheader={subheader}
        loading={loading}
        style={isHover ? {color:ColorConstants.ACTIVE_BUTTON_BLUE} : {pointerEvents:'none'}}
      >
        {header}
      </HeaderAndSubheader>
    </div>
);
} 

export default FileDropzoneText;