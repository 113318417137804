module.exports =  {
  BLACK:"#1A1615",
  DARK_GREY:"#2E2826",
  MEDIUM_GREY:"#2E2826",
  LIGHT_GREY:"#979797",
  ULTRA_LIGHT_GREY:"#F0F0F0",
  HIGHLIGHT_COLOR:"#FFE28B",
  HOVER_COLOR:"#FFF8E3",
  UNDERLINE_COLOR:"#4A90E2",
  SUPER_UNDERLINE_COLOR:"#FF8C00",
  ACTIVE_BUTTON_BLUE:"#4A90E2",
  LIGHT_BLUE:"#D2DFF0",
  BLUE: "#B2D0FB",
  GHOST_COLOR:"#C9DFF8",
  ERROR_TEXT: "#D0021B"
}
