import React from "react";
import ColorConstants from "constants/ColorConstants";
import { ButtonToolbar, Button, Checkbox, Glyphicon } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";

const CONTENT_BUFFER = 150;
const CHECKBOX_BUFFER = 100;

const dropDownContentStyle = {
  position: "absolute",
  borderColor: ColorConstants.LIGHT_GREY,
  backgroundColor: "white",
  minWidth: "160px",
  width: "300px",
  zIndex: 3,
  boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
  borderRadius: "3px",
  marginTop: "40px"
};

const ChecklistDropdown = ({
  codes = [],
  shown,
  dropdownAbove,
  dropdownAlignRight,
  wrapperRef,
  subHeader,
  onDeleteFilter,
  onClearChecked,
  onDoneButton,
  filterButtonText,
  onChecked,
}) => {
  const getCodeName = (code) => (code.count ? `${code.name} (${code.count})` : code.name);

  const checkLists = (codes || []).map((code) => (
    <Checkbox
      key={code.id}
      checked={code.checked}
      onChange={() => onChecked(code.id, code.checked)}
    >
      {getCodeName(code)}
    </Checkbox>
  ));

  const contentStyleDynamic = {
    ...dropDownContentStyle,
    maxHeight: window.innerHeight - CONTENT_BUFFER,
    display: shown ? "block" : "none",
    padding: "5px",
    ...(dropdownAbove ? { bottom: "100%" } : { top: "100%" }),
    ...(dropdownAlignRight ? { right: 0 } : { left: 0 }), // Dynamic alignment based on dropdownAlignRight
  };

  return (
    <div style={contentStyleDynamic} ref={wrapperRef}>
      <div className="descriptor-dropdown" style={{ padding: "10px", width: "100%" }}>
        <Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - CONTENT_BUFFER - CHECKBOX_BUFFER}>
          <div style={{ color: ColorConstants.LIGHT_GREY }}>
            <em>{subHeader || "Filter snippets by any of the selected"}</em>
          </div>
          {checkLists}
        </Scrollbars>
        <div style={{ float: "right" }}>
          <ButtonToolbar>
            {onDeleteFilter && (
              <Button bsStyle="link" onClick={onDeleteFilter}>
                <Glyphicon glyph="trash" />
              </Button>
            )}
            {onClearChecked && 
              <Button bsStyle="white" onClick={onClearChecked}>
                Clear
              </Button>
            }
            {onDoneButton && 
              <Button bsStyle="blue" onClick={onDoneButton}>
                {filterButtonText || "Apply Filters"}
              </Button>
            }
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};

export default ChecklistDropdown;
