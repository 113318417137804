import React from 'react';

import { PARAGRAPH_PADDING } from 'constants/DimensionsConstants';
import ParagraphLeftComponent from 'codeable/ParagraphLeftComponent';
import ParagraphSpeakerComponent from 'codeable/ParagraphSpeakerComponent';

const MAIN_STYLE = {
  marginLeft: PARAGRAPH_PADDING + "px",
  marginRight: "0px",
  marginTop: "0px",
  marginBottom: "0px",
  padding: "0px",
};

const allStyle = {
  margin: 0,
  border: 0,
  position: "relative",
  paddingBottom: "20px",
  minHeight: "65px",
  // height:"500px", // this is set set by react
};

const ParagraphLayout = ({
  marginLeft,
  marginRight,
  speaker,
  time,
  color = null,
  onMouseEnter,
  onMouseLeave,
  paragraphIndex,
  start,
  end,
  logo,
  onParagraphHover,
  onParagraphClick,
  onEditClick,
  canEdit,
  isPrint,
  showTools,
  hideTitle,
  onTitleClick,
  children,
}) => {
  const mainStyle = {
    ...MAIN_STYLE,
    marginLeft: marginLeft || MAIN_STYLE.marginLeft,
    marginRight: marginRight || MAIN_STYLE.marginRight,
  };

  return (
    <div
      style={allStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="paragraphSpan"
      paragraph-index={paragraphIndex}
      start={start}
      end={end}
    >
      <ParagraphLeftComponent
        speaker={speaker}
        logo={logo}
        onParagraphHover={onParagraphHover}
        onParagraphClick={onParagraphClick}
        color={color}
        onEditClick={onEditClick}
        canEdit={canEdit}
        isPrint={isPrint}
        showTools={showTools}
        hideTitle={hideTitle}
      />

      {(speaker || time) && (
        <div style={mainStyle} className="speaker">
          <ParagraphSpeakerComponent
            speaker={speaker}
            time={time}
            onTitleClick={onTitleClick}
          />
        </div>
      )}
      <div style={mainStyle}>
        {children}
      </div>
    </div>
  );
};

export default ParagraphLayout;
