const surveyMapper = (surveys, filterState, projectID) => {
    try {
        const nullState = { surveys: [] };
        if (!projectID) return nullState;

        const projects = filterState.projects || {};
        const project = projects[projectID] || {};

        const surveyToCount = project?.counts?.survey_ids || {};

        const checkedSurveys = project?.filters?.surveys || [];

        const mappedSurveys = surveys.map((survey) => {
            return {
                id: survey.id,
                name: survey.title || survey.name || "Untitled",
                count: surveyToCount[survey.id] || 0,
                checked: checkedSurveys.includes(survey.id)
            };
        }).filter((survey) => !!survey)
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        return { surveys: mappedSurveys };
    } catch (e) {
        console.error(e);
        return { surveys: [] };
    }
};

export default surveyMapper;
