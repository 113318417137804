// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Link} from 'react-router-dom'

import ColorConstants from 'constants/ColorConstants'
import { getRelativeExcerptUrl } from '../mappers/ExcerptUrlMapper';

const ExcerptTitle = ({excerpt, onLinkClick}) => {
  return (<p style={{fontSize:"0.8em"}}>
  <Link onClick={onLinkClick} to={getRelativeExcerptUrl(excerpt)}>
    <span style={{
      color:ColorConstants.ACTIVE_BUTTON_BLUE,
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '90%'
    }}>
      <strong>
        {excerpt.transcript.name}
      </strong>
    </span>
  </Link>
  </p>)
}

export default ExcerptTitle;

