import React from 'react';
import SurveyTableHeader from './SurveyTableHeader';
import SurveyTableRow from './SurveyTableRow';
import PageLoadingComponent from 'projects/components/PageLoadingComponent';

const SurveyTable = ({
  surveyQuestions,
  surveyRows,
  onOptionClicked,
  onEditQuestion,
  onQuestionClicked,
  onDeleteQuestion,
  loading,
  toggleColumnVisibility,
  hasEditPermission,
}) => {
    return (
      <div>
        {loading && <PageLoadingComponent />}
        <table
          style={{
            borderCollapse: "collapse",
            marginTop: "20px",
            fontFamily: "Arial, sans-serif",
            tableLayout: "auto"
          }}
        >
          <SurveyTableHeader
            surveyQuestions={surveyQuestions}
            onEditQuestion={onEditQuestion}
            onDeleteQuestion={onDeleteQuestion}
            toggleColumnVisibility={toggleColumnVisibility} // Pass the function down
            hasEditPermission={hasEditPermission}
          />
          <tbody>
            {!loading && surveyRows.map((response, index) => (
              <SurveyTableRow
                key={response.id}
                surveyQuestions={surveyQuestions}
                response={response}
                index={index}
                onOptionClicked={onOptionClicked}
                onQuestionClicked={onQuestionClicked}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
}

export default SurveyTable;