import { useState, useEffect } from 'react';
import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import CodeStore from 'stores/CodeStore';

const useSurveyExcerpts = (getExcerpts) => {
  const [excerpts, setExcerpts] = useState([]);

  const filterExcerpts = (excerpts, compareFunc) => {
    return excerpts.filter((e) => {
      return compareFunc(e);
    })
  }
    
  const getQuestionExcerpts = (question_id) => {
    return filterExcerpts(excerpts, (e) => e.survey_question_id === question_id);
  };

  const getResponseExcerpts = (response_id) => {
    return filterExcerpts(excerpts, (e) => e.survey_row_id === response_id);
  };

  // Fetch excerpts from the provided `getExcerpts` function
  useEffect(() => {
    const onNewCodes = () => {
      const newExcerpts = getExcerpts();
      setExcerpts(newExcerpts);
    };

    // Listen for changes
    CodedTranscriptStore.addChangeListener(onNewCodes, 'codeChange');
    CodeStore.addChangeListener(onNewCodes, 'codeChange');

    // Cleanup listeners
    return () => {
      CodedTranscriptStore.removeChangeListener(onNewCodes, 'codeChange');
      CodeStore.removeChangeListener(onNewCodes, 'codeChange');
    };
  }, [getExcerpts]);

  // Return the current excerpts and setter for custom use cases
  return {getQuestionExcerpts, getResponseExcerpts};
};

export default useSurveyExcerpts;
