import { useState, useCallback } from 'react';

const useHighlightAnswer = () => {
  const [highlightedAnswerId, setHighlightedAnswerId] = useState(null);

  const handleMouseDown = useCallback((answerId) => {
    setHighlightedAnswerId(answerId);
  }, []);

  const isAnswerDisabled = useCallback(
    (answerId) => !!highlightedAnswerId && highlightedAnswerId !== answerId,
    [highlightedAnswerId]
  );

  return {
    handleMouseDown,
    isAnswerDisabled,
  };
};

export default useHighlightAnswer;
