import React from "react";
import { Button } from 'react-bootstrap';
import SurveyButtonToolbar from "./SurveyButtonToolbar";

const SurveyPagePageBar = ({
    onGoPrevious,
    onGoNext,
    offset,
    pageNext,
    disabled
}) => {
    // if the offset is 0, we are at the beginning, do not show the previous button
    // if the pageNext is null, we are at the end, do not show the next button
    const showNext = pageNext !== null;
    const showPrevious = offset > 0;

    if (!showNext && !showPrevious) {
        return null;
    }

    return (
        <SurveyButtonToolbar>
            {showPrevious && (
                <Button 
                    bsStyle="white"
                    onClick={onGoPrevious}
                    disabled={disabled}
                >
                    Previous Responses
                </Button>
            )}
            {
                showNext && (
                    <Button
                        bsStyle="white"
                        onClick={onGoNext}
                        disabled={disabled}
                    >
                        Next Responses
                    </Button>
                )
            }
      </SurveyButtonToolbar>
    )
}

export default SurveyPagePageBar;