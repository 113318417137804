// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ProjectsStore from 'stores/ProjectsStore'
import Header from './Header'
import matchToProjectID from 'utils/MatchToProjectID'

class ProjectHeader extends React.Component {
  constructor () {
    super();

    this.state = {
      projectName: ""
    };

    this._onChange = this._onChange.bind(this);
  }

  _loadState(props) {
    var project = ProjectsStore.getProject(matchToProjectID(props.match));

    if ( project )
    {
      this.setState({
        projectName:project.name
      })
    }
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillReceiveProps (nextProps) {
    var nextProject = matchToProjectID(nextProps.match);
    var thisProject = matchToProjectID(this.props.match);

    if ( nextProject != thisProject )
    {
      this._loadState(nextProps);
    }
  }


  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    return (
      <Header>{this.state.project}</Header>
    );
  }
}

export default ProjectHeader;
