import React, { useCallback, useContext } from 'react';
import QualCodeActions from 'actions/QualCodeActions';
import SpeakerParagraphComponent from './SpeakerParagraphComponent';
import CodeableContext from 'contexts/CodeableContext';

const _ = require('underscore');

const ParagraphContainer = ({
  transcriptID,
  absoluteIndex,
  codedParagraph,
  paragraphIndex,
  canEdit,
  hasEditPermission,
  isPrint,
  marginLeft,
  marginRight,
  onParagraphClick
}) => {
  
  const onStartEditing = useCallback((e) => {
    QualCodeActions.editParagraph(transcriptID, absoluteIndex);
    if (e.stopPropagation) e.stopPropagation();
  }, [transcriptID, absoluteIndex]);

  const {onRemoveCode} = useContext(CodeableContext) || {};

  return (
    <SpeakerParagraphComponent
      codedParagraph={codedParagraph}
      paragraphIndex={paragraphIndex}
      absoluteIndex={absoluteIndex}
      onEditClick={onStartEditing}
      canEdit={canEdit}
      hasEditPermission={hasEditPermission}
      isPrint={isPrint}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onParagraphClick={onParagraphClick}
      onRemoveCode={onRemoveCode}
    />
  );
};

export default ParagraphContainer;
