// ExcerptMapper.js
import excerptCodesMapper from './ExcerptCodesMapper';
const _ = require('underscore');
import OptimisticMapperHelper from './OptimisticMapperHelper';

// TODO: this is not tested in any way, please test before launching
const excerptsWithCodesMapper = (state, excerpt_ids) => {
  return excerpt_ids.map((excerpt_id)=>{
    return OptimisticMapperHelper.getExcerpt(state, excerpt_id);
  }).map((excerpt)=>{
    if ( !excerpt ) return null;
    return excerptCodesMapper(state, excerpt);
  }).filter((excerpt)=>!!excerpt);
}

export default excerptsWithCodesMapper;
