// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import SurveyHeader from './SurveyHeader';
import ProjectHeader from './ProjectHeader'
import AnalysisHeader from './AnalysisHeader'
import CodesHeader from './CodesHeader'
import ShareHeader from './ShareHeader'
import ErrorAlert from '../ErrorAlert'
import Header from './Header'
import ChatHeader from './ChatHeader'
import TranscriptHeaderContainer from './TranscriptHeaderContainer';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

class QualHeader extends React.Component {
  render () {
    if ( !!this.props.notFoundError ) {
      return <div></div>
    }

    return (
      <div className='header'>
        <ErrorAlert/>
        <Switch>
          <Route
            path='/projects/:projectID/transcripts/new'
            render={(match)=>(<ShareHeader
                {...match}
                hasEditPermission={this.props.hasEditPermission}
                subscriptionState={this.props.subscriptionState}
                isActive={this.props.isActive}
                roleIsEdit={this.props.roleIsEdit}
                onShowCollabModal={this.props.onShowCollabModal}
                showCollabModal={this.props.showCollabModal}
              >
                Add Transcript
              </ShareHeader>)}
          />
          <Route
            path='/projects/:projectID/surveys/new/:csvID'
            render={()=>(<Header>Add Survey</Header>)}
          />
          <Route
            path='/projects/:projectID/surveys/new'
            render={(match)=>(<ShareHeader
                {...match}
                hasEditPermission={this.props.hasEditPermission}
                subscriptionState={this.props.subscriptionState}
                isActive={this.props.isActive}
                roleIsEdit={this.props.roleIsEdit}
                onShowCollabModal={this.props.onShowCollabModal}
                showCollabModal={this.props.showCollabModal}
              >
                Add Survey
              </ShareHeader>)}
          />
          <Route
            path='/projects/:projectID/search'
            render={(match)=>(<ShareHeader
                {...match}
                hasEditPermission={this.props.hasEditPermission}
                subscriptionState={this.props.subscriptionState}
                name="Search"
                isActive={this.props.isActive}
                roleIsEdit={this.props.roleIsEdit}
                onShowCollabModal={this.props.onShowCollabModal}
                showCollabModal={this.props.showCollabModal}
              >
                Search
              </ShareHeader>)}
          />
          <Route
            path='/projects/:projectID/chat'
            render={(match)=>(<ChatHeader
                {...match}
                hasEditPermission={this.props.hasEditPermission}
                subscriptionState={this.props.subscriptionState}
                isActive={this.props.isActive}
                roleIsEdit={this.props.roleIsEdit}
                onShowCollabModal={this.props.onShowCollabModal}
                showCollabModal={this.props.showCollabModal}
              />)}
          />
          <Route
            path='/projects/:projectID/analysis'
            render={(match)=>(<AnalysisHeader
                {...match}
                hasEditPermission={this.props.hasEditPermission}
                subscriptionState={this.props.subscriptionState}
                isActive={this.props.isActive}
                roleIsEdit={this.props.roleIsEdit}
                onShowCollabModal={this.props.onShowCollabModal}
                showCollabModal={this.props.showCollabModal}
              />)}
            exact
          />
          <Route
            path='/projects/:projectID/codes'
            render={(match)=>(<CodesHeader
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              roleIsEdit={this.props.roleIsEdit}
              onRenew={this.props.onRenew}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/projects/:projectID'
            render={(match)=>(<ProjectHeader
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/transcripts/:transcriptID'
            render={(match)=>(<TranscriptHeaderContainer
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              roleIsEdit={this.props.roleIsEdit}
              onRenew={this.props.onRenew}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/surveys/:surveyID'
            render={(match)=>(<SurveyHeader
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              roleIsEdit={this.props.roleIsEdit}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/survey_responses/:surveyResponseID'
            render={(match)=>(<SurveyHeader
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              roleIsEdit={this.props.roleIsEdit}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/survey_questions/:surveyQuestionID'
            render={(match)=>(<SurveyHeader
              {...match}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              isActive={this.props.isActive}
              roleIsEdit={this.props.roleIsEdit}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />)}
          />
          <Route
            path='/users'
            render={()=>(<Header>Account</Header>)}
          />
        </Switch>
      </div>
    );
  }
}

export default QualHeader;
