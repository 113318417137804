import { useEffect, useRef } from 'react';


function useSelectSnippetEffect(onExcerptSelected, snippet) {
    useEffect(() => {
        if ( snippet ) {
            onExcerptSelected(snippet);
        }
    }, [snippet]);    
}

function useTextSelectionEffect(onSelectText, pos, end, transcriptID) {
    useEffect(() => {
        if ( pos && end ) {
            onSelectText(pos, end, transcriptID, 'Transcript');
        }
    }, [pos, end, transcriptID]);
}

function useSearchRedirectEffect(searchRedirect, transcriptID, search) {
    useEffect(() => {
        // search changed
        if ( search )
          searchRedirect(transcriptID, search);
    }, [search, transcriptID]);
}

function deselectOnNewTranscript(onDeselect, transcriptID, snippet, search, pos) {
    const hasSelectionRef = useRef(false);

    useEffect(() => {
        hasSelectionRef.current = snippet || search || pos;
    }, [snippet, search, pos]);

    useEffect(() => {
        // If there's a selection, do not deselect
        if (hasSelectionRef.current) return;
        onDeselect();
    }, [transcriptID]);
}
  
export {
    useSelectSnippetEffect,
    useTextSelectionEffect,
    useSearchRedirectEffect,
    deselectOnNewTranscript
};
