import API from './API'
import AppDispatcher from 'dispatcher/AppDispatcher';
import QualConstants from 'constants/QualConstants';

export default {
  // search transcript
  getSurveys: async function(project_id)
  {
    if ( !project_id )
      throw new Error('Project Id required for Search Transcripts')

    return API.get(`/api/projects/${project_id}/surveys`);
  },

  getSurvey: async function(survey_id)
  {
    if ( !survey_id )
      throw new Error('Survey Id required for Survey')

    return API.get(`/api/surveys/${survey_id}`);
  },

  destroySurvey: async function(survey_id)
  {
    if ( !survey_id )
      throw new Error('Survey Id required for Survey')

    return API.delete(`/api/surveys/${survey_id}`);
  },

  getSurveyQuestionOptions: async function(survey_question_id)
  {
    if ( !survey_question_id )
      throw new Error('Survey Question Id required for Survey Question Options')

    return API.get(`/api/survey_questions/${survey_question_id}/survey_options`);
  },

  getSurveyRows: async function(survey_id, filter) {
    // if option_id is string, convert it to array
    if (!survey_id) {
      throw new Error('Survey Id required for Survey Rows');
    }

    let url = `/api/surveys/${survey_id}/survey_rows`;

    if (filter) {
      url += `?filter=${filter}`;
    }

    return API.get(url);
  },

  getSurveyRow: async function(survey_row_id)
  {
    if ( !survey_row_id )
      throw new Error('Survey Row Id required for Survey Row')

    return API.get(`/api/survey_rows/${survey_row_id}`);
  },

  getSurveyQuestionResponses: async function(survey_question_id, offset = 0) {
    if (!survey_question_id) {
      throw new Error('Survey Question Id required for Survey Question');
    }

    let url = `/api/survey_questions/${survey_question_id}/survey_rows?offset=${offset}`;

    return API.get(url);
  },

  getSurveyQuestion: async function(survey_question_id)
  {
    if ( !survey_question_id )
      throw new Error('Survey Question Id required for Survey Question')

    return API.get(`/api/survey_questions/${survey_question_id}`);
  },

  deleteSurveyQuestion: async function(survey_question_id)
  {
    if ( !survey_question_id )
      throw new Error('Survey Question Id required for Survey Question')

    return API.delete(`/api/survey_questions/${survey_question_id}`);
  },

  getSurveyQuestionExcerpts: async function(survey_question_id)
  {
    if ( !survey_question_id )
      throw new Error('Survey Question Id required for Survey Question Excerpts')

    return API.get(`/api/survey_questions/${survey_question_id}/excerpts`).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_EXCERPTS_RESULT,
        data: response.data
      });  

      return response;
    })
  },

  getSurveyRowExcerpts: async function(survey_row_id)
  {
    if ( !survey_row_id )
      throw new Error('Survey Row Id required for Survey Row Excerpts')

    return API.get(`/api/survey_rows/${survey_row_id}/excerpts`).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.FETCH_EXCERPTS_RESULT,
        data: response.data
      });  

      return response;
    });
  },

  getSurveyQuestions: async function(survey_id)
  {
    if ( !survey_id )
      throw new Error('Survey Id required for Survey Questions')

    return API.get(`/api/surveys/${survey_id}/survey_questions`);
  },

  updateSurveyQuestion: async function(survey_question_id, question_type)
  {
    if ( !survey_question_id )
      throw new Error('Survey Question Id required for Update Survey Question')

    return API.put(`/api/survey_questions/${survey_question_id}`, {
      survey_question: {
        question_type
      }
    });
  },

  uploadCSVFile: async function(project_id, file) {
    if ( !project_id )
      throw new Error('Project Id required for Create Survey')

    const formData = new FormData();
    formData.append('csv_upload[file]', file);  // Append the file object
  
    // Use the API.post function to send the form data
    return API.post(`/api/projects/${project_id}/csv_uploads`, formData)
      .then(response => {
        return response.data;
      })
  },

  getCSVFile: async function(csv_upload_id) {
    if ( !csv_upload_id )
      throw new Error('CSV Upload Id required for Get CSV File')

    return API.get(`/api/csv_uploads/${csv_upload_id}`).then((response)=>{
      return response.data;
    });
  }
};
