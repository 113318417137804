import React, {useCallback} from 'react';
import SurveyOption from './SurveyOption';
import NonCodeableSurveyWrapper from './NonCodeableSurveyWrapper';
import SurveyQuestionNoAnswer from './SurveyQuestionNoAnswer';

const SurveyQuestionOption = ({ 
  surveyAnswer,
  questionId,
  questionText,
  onClick
}) => {
  if (!surveyAnswer)
    return (
      <SurveyQuestionNoAnswer
        questionText={questionText}
        questionType='single_choice'
      />
    )

  const optionClicked = useCallback(() => {
    onClick(questionId, surveyAnswer?.survey_option?.id);
  }, [questionId, surveyAnswer?.survey_option?.id, onClick]);

  const option_text = surveyAnswer?.survey_option?.option_text;

  return (
    <NonCodeableSurveyWrapper
      header={questionText}
      disabled={true}
      logo='triangle-bottom'
    >
      <SurveyOption
        body={surveyAnswer?.survey_option?.option_text}
        optionClicked={optionClicked}
      />
    </NonCodeableSurveyWrapper>
  );
};

export default SurveyQuestionOption;