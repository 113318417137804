import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import CodePageConstants from 'constants/CodePageConstants'
import State from 'stores/State'
import CodePageState from 'stores/CodePageState'
import descriptorReducer  from 'reducers/DescriptorReducer'
import nestReducer from 'reducers/NestReducer'
import codePageReducer from 'reducers/CodePageReducer'

import codesMapper from 'mappers/CodesMapper'
import codesSideMapper from 'mappers/CodesSideMapper'
import codePageMapper from 'mappers/CodePageMapper'
import codePageNextMapper from 'mappers/CodePageNextMapper'
import exportCodesMapper from 'mappers/exports/ExportCodesMapper'
import exportCodesCSVMapper from 'mappers/exports/ExportCodesCSVMapper'
import codeAncestryMapper from 'mappers/CodeAncestryMapper'
import overlappingCodesMapper from 'mappers/OverlappingCodesMapper'
import codeChildrenMapper from 'mappers/CodeChildrenMapper'
import codeIdToProjectIdMapper from 'mappers/CodeIdToProjectIdMapper'
import codesIdsMapper from 'mappers/CodesIdsMapper'
import excerptWithStartAndEndMapper from 'mappers/ExcerptWithStartAndEndMapper'

import {
  CREATE_MEMO,
  CREATE_MEMO_RESULT,
  CREATE_MEMO_ERROR,
  DELETE_MEMO,
  DELETE_MEMO_CABLE
} from 'memos/MemoConstants'
import memoReducer from 'memos/MemoReducer'


import asyncUtil from 'utils/AsyncUtil'
import excerptsWithCodesMapper from 'mappers/ExcerptsWithCodesMapper'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var CodeStore = _.extend({}, EventEmitter.prototype, {
  // TODO: deeply consider the necessity of this hack. Ideally do this in a better way
  // doing this so codedTranscriptUtil can get state for SurveyAnswers
  getState: function() {
    return State.get();
  },

  codeIdToProjectId: (codeId) => {
    return codeIdToProjectIdMapper(State.get(), codeId);
  },

  getCodeWithChildren: (codeId) => {
      return codeChildrenMapper(State.get(), codeIdToProjectIdMapper(State.get(), codeId), codeId)
  },

  getCodeNext: (codeId) => {
    return codePageNextMapper(CodePageState.get(), codeId);
  },

  getMergeCodes: () => {
    return State.get().mergeCodes;
  },

  getOverlappingCodes: (codeId) => {
    if ( !!OVERLAPPING_CODE_FLAG )
      return overlappingCodesMapper(State.get(), codeId);
    else
      return {overlappingCodes: []};
  },

  getCodePageModalInfo: function(codeID)
  {
    return codePageMapper(State.get(), CodePageState.get(), codeID);
  },

  getCodes: function(projectID) {
    const codes = codesMapper(State.get(), projectID);
    // TODO: in the future include codes key
    return codes.codes;
  },

  getCodeIds: function(projectID) {
    return codesIdsMapper(State.get(), projectID);
  },

  getCodesSidebar: function(projectID, ghostCodeID, searchText) {
    const result = codesSideMapper(State.get(), projectID, ghostCodeID, searchText);
    // TODO: in the future include codes key
    return result;
  },

  getCodeParents: function(codeID)
  {
    return codeAncestryMapper(State.get(), codeID);
  },

  getCodesForExport: function(projectId) {
    const exportObject = exportCodesMapper(State.get(), projectId);
    return exportObject;
  },

  getCodesForCSVExport: function(projectId) {
   const exportObject = exportCodesCSVMapper(State.get(), projectId);
   return exportObject;
  },

  getLoadState: function(projectID) {
    const projectLoadState = State.get().loadingState.codes[projectID];
    if ( projectLoadState )
      return projectLoadState;
    else
      return {loading: false, loaded: false};
  },

  getExcerptsWithCodes: function(excerpt_ids) {
    return excerptsWithCodesMapper(State.get(), excerpt_ids);
  },

  getExcerpt: function(excerptId) {
    return excerptsWithCodesMapper(State.get(), [excerptId])[0];
  },

  getSurveyResponseExcerpts: function(surveyResponseID) {
    return excerptsWithCodesMapper(State.get(), Object.values(State.get().entities.excerpts).filter(excerpt =>{
          return excerpt.survey_row_id === parseInt(surveyResponseID)
        }
      ).map(excerpt => excerpt.id)
    )
  },

  getSurveyQuestionExcerpts: function(surveyQuestionID) {
    return excerptsWithCodesMapper(State.get(), Object.values(State.get().entities.excerpts).filter(excerpt =>{
        return excerpt.survey_question_id === parseInt(surveyQuestionID)
        }
      ).map(excerpt => excerpt.id)
    )
  },

  // TODO: put this in a mapper
  getExcerptWithStartAndEnd: function(start, end, codeableId, codeableType) {
    return excerptWithStartAndEndMapper(State.get(), start, end, codeableId, codeableType);
  },

  // Emit Change event
  emitChange: function(event_name) {
    this.emit(event_name || 'change');
  },

  // Add change listener
  addChangeListener: function(callback, event_name) {
    this.on(event_name || 'change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback, event_name) {
    this.removeListener(event_name || 'change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.actionType) {
    case QualConstants.DELETE_CODE:
    case QualConstants.CREATE_EXCERPT_RESULT:
    case QualConstants.DELETE_CODE_FROM_EXCERPT_RESULT:
    case QualConstants.ADD_CODE_TO_EXCERPT_RESULT:
    case QualConstants.FETCH_CODES_RESULT:
    case QualConstants.NEST_CODE_CABLE:
    case QualConstants.CREATE_EXCERPT_CABLE:
    break;
    // TODO: Okay for future reference this is doing two things
    // 1 it is nesting the codes immediately using the reduer (which is good)
    // but it also using the reducer to figure out who the new parent is
    // this is to make sure that you don't violate any of the nesting rules
    // however, it would be cleaner to just call async util from Actions
    // but it might require a way to quickly get who the parent should be
    case QualConstants.NEST_CODE:
    {
      State.set(nestReducer(action, State.get()));
      const childCodeID = action.data.child_code_id;
      const state = State.get();
      const child = state.entities.codes[state.mappers.codes[childCodeID]];
      const parentCodeID = child.parent_id;
      const position = action.data.position || 1;
      asyncUtil.nestCode(child.id, parentCodeID, position);
    }
    break
    case QualConstants.FETCH_CODES:
    case QualConstants.CREATE_CODE_RESULT:
    case QualConstants.CREATE_CODE_ERROR:
    case QualConstants.CREATE_CODE_CABLE:
    case QualConstants.RENAME_CODE:
    case QualConstants.UPDATE_CODE_CABLE:
    case QualConstants.DELETE_CODE_CABLE:
    case QualConstants.GET_CODE_RESULT:
    case QualConstants.UPDATE_CODE_SYNTHESIS:
    case QualConstants.FETCH_EXCERPTS_RESULT:
      State.set(descriptorReducer(action, State.get()));
      CodeStore.emitChange('codeChange');
    break;
    case QualConstants.DELETE_CODE_FROM_EXCERPT:
    case QualConstants.ADD_CODE_TO_EXCERPT:
    case QualConstants.CREATE_EXCERPT:
      CodeStore.emitChange('codeChange');
    break;
    case QualConstants.SHOW_CODE_PAGE_MODAL:
    case QualConstants.HIDE_CODE_PAGE_MODAL:
    case QualConstants.COLLAPSE_CODE:
    case QualConstants.EXPAND_CODE:
    case QualConstants.COLLAPSE_CODE_BAR:
    case QualConstants.EXPAND_CODE_BAR:
    case QualConstants.CODE_BOOK:
    case QualConstants.CODE_BAR:
        State.set(descriptorReducer(action, State.get()));
    break;
    case CodePageConstants.FILTER_CODE_EXCERPTS_RESULT:
    case CodePageConstants.FILTER_CODE_EXCERPTS:
      CodePageState.set(codePageReducer(action, CodePageState.get()));
      State.set(descriptorReducer(action, State.get())); // this saves the excerpts
    break;
    case CodePageConstants.CODE_REPORT_RESULT:
      CodePageState.set(codePageReducer(action, CodePageState.get()));
    break;
    case CREATE_MEMO_RESULT:
    case CREATE_MEMO:
    case CREATE_MEMO_ERROR:
    case DELETE_MEMO:
    case DELETE_MEMO_CABLE:
      State.set(memoReducer(action, State.get()));
    break;
    default:
      return true;
  }

  CodeStore.emitChange();
  return true;
});

export default CodeStore
