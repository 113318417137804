import React from "react";
import CodeTag from "projects/components/CodeTag";

const ParagraphCodeList = ({
    codes,
    hasEditPermission,
    onCodeRemove,
    tagEnter,
    tagLeave
}) => {
    const labels = [...codes.map((code, index) =>
        <CodeTag
          key={index}
          id={(code||{}).id}
          name={(code||{}).name}
          selected={(code||{}).selected}
          tagEnter={tagEnter}
          tagLeave={tagLeave}
          onCodeRemove={onCodeRemove}
          hasEditPermission={hasEditPermission}
          coders={code.coders}
          />
      )].filter((label) => label !== null);
  
    return (
        <div className="codelist" style={{width:"100%", wordWrap: 'break-word'}}>
            {labels}
        </div>
    )
};

export default ParagraphCodeList;

