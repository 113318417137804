import ConversationsAPI from './ConversationsAPI';
import AppDispatcher from 'dispatcher/AppDispatcher'
import FilterStore from 'stores/FilterStore'

const dispatchJob = (actionType, data) => {
  AppDispatcher.handleAction({
    actionType,
    data,
  })
}

var ConversationActions = {
  createConversation: async function(project_id, content) {
    return ConversationsAPI.createConversation(project_id, content)
  },
  createMessage: async function(conversation_id, content) {
    return ConversationsAPI.createMessage(conversation_id, content)
  },
  getConversation: async function(conversation_id) {
    return ConversationsAPI.getConversation(conversation_id)
  },
  createAIConversation: async function(projectID, conversation_id) {
    const {codes, multi_choices, transcripts, op} = FilterStore.getFilters(projectID);

    return ConversationsAPI.createAIConversation(conversation_id, codes, op, transcripts)
  },
  getConversations: async function(project_id) {
    return ConversationsAPI.getConversations(project_id)
  }
}

export default ConversationActions
