import React from 'react';
import {useDropzone} from 'react-dropzone';


function FileDropZone({isHovering, onDrop, children}) {
  /*
    Open can be used to open the native file picker
  */
  const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
    onDrop: onDrop,
    noClick: true,
    noKeyboard: true,
    onDragEnter: event => isHovering(true),
    onDragLeave: event => isHovering(false)
  });

  return (
    <div style={{height:"100%", width: "100%"}}>
      <div {...getRootProps({className: 'dropzone'})} style={{height:"100%", width: "100%"}}>
        <input {...getInputProps()}/>
        {children}
      </div>
    </div>
  );
}


export default FileDropZone
