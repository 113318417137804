// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useContext } from 'react';

import CodeableContext from 'contexts/CodeableContext';
import CodeContainer from 'projects/components/CodeContainer';

var _ = require('underscore');

function TranscriptCodeContainer({ projectID, height, hideCodeCounts }) {
  const {
      select,
      selectedExcerptId,
  } = useContext(CodeableContext);

  return <CodeContainer
      projectID={projectID}
      height={height}
      disabled={!select && !selectedExcerptId}
      hideCodeCounts={hideCodeCounts}
  />
}

export default TranscriptCodeContainer;
