// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback, useContext} from 'react';
import {Button} from 'react-bootstrap'
import Excerpt from './Excerpt'
import CodeabeleContext from 'contexts/CodeableContext';

const LEFT_PADDING = 45;

function ExcerptListComponent({
  codePage,
  projectID,
  excerpts,
  headerText,
  hasEditPermission,
  onLinkClick,
  onCodeClick,
  showMore,
  loading,
  canPage
}) {
  const {
    selectedExcerptId,
    onExcerptSelected,
    onDeselect
  } = useContext(CodeabeleContext);

  const onClick = useCallback((e) => {
    onDeselect();
    // make sure does not bubble up
    e.stopPropagation();
  })

  const selectedExcerptIdString = selectedExcerptId ? selectedExcerptId.toString() : null;

  const excerptComponents = excerpts.map((excerpt,index)=>{
    return(
          <Excerpt
            codePage={codePage}
            projectID={projectID}
            excerpt={excerpt}
            key={index}
            onLinkClick={onLinkClick}
            onTextClicked={onExcerptSelected}
            selected={(excerpt || {}).id?.toString() === selectedExcerptIdString}
            hasEditPermission={hasEditPermission}
            onCodeClick={onCodeClick}
            />
        );
  });

  return (
    <div style={{padding:`20px ${LEFT_PADDING}px`}}
      onClick={onClick}
    >
      <span style={{fontSize:'.8em', cursor:'default'}}><strong>
        {headerText || "Sorted By Most Recent"}
      </strong></span>
      {excerptComponents}

      {canPage &&
        <div style={{textAlign:'center', marginBottom:"20px"}}>
          <Button bsStyle="white"
            onClick={showMore}
            disabled={loading}
            >Show More</Button>
        </div>
      }
    </div>
  );
}

export default ExcerptListComponent;
